import { Component } from "@angular/core";

@Component({
  selector: 'pg-cadastrar',
  templateUrl: './cadastrar.page.html',
  styleUrls: ['./cadastrar.page.scss']
})
export class CadastrarPage {

  constructor(){
  }
}