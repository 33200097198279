import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { MeuVideoPitchPage } from './meu-video-pitch.page';
import { MeuVideoComponent } from 'src/app/arquitetura/rodada-negocios/meu-video/meu-video.component';

@NgModule({
  declarations: [
    MeuVideoPitchPage,
    MeuVideoComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class MeuVideoPitchModule { }
