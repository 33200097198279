import { Component, OnInit, ViewChild } from "@angular/core";
import { CabecalhoComponent } from "src/app/arquitetura/cabecalho/cabecalho.component";
import { HomeComponent } from "src/app/arquitetura/core/home/home.component";

@Component({
  selector: 'pg-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit{

  @ViewChild('cabecalho') cabecalho: CabecalhoComponent;
  @ViewChild('home') home: HomeComponent;

  constructor(){
  }
  
  ngOnInit(): void {
    if(this.cabecalho){
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }

  ionViewWillEnter(){
    if(this.cabecalho){
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }

  ionViewDidEnter(){
    if(this.cabecalho){
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }

  onAtualizarLocalizacao(){
    this.home.onRecarregar();
  }
}