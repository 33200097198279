import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cmp-condicao-produto',
  templateUrl: './condicao-produto.component.html',
  styleUrls: ['./condicao-produto.component.scss']
})
export class CondicaoProdutoComponent {

  condicoes: any[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor() { 
  }

  ngOnInit(): void {
      this.listar();
  }

  listar(): void {
    this.condicoes = [
        {name: 'Produto novo', value: '1', description: 'Produto novo'},
        {name: 'Produto usado', value: '2', description: 'Produto usado'}
    ];
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }
  
  setCondicao(condicao: string){
    this.condicoes.forEach((item) =>{
      if(condicao === item.value)
        this.valor = item.value;
    });
  }
}
