import { Component } from "@angular/core";

@Component({
  selector: 'pg-editar-endereco',
  templateUrl: './editar-endereco.page.html',
  styleUrls: ['./editar-endereco.page.scss']
})
export class EditarEnderecoPage {

  constructor(){
  }
}