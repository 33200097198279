import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from 'src/app/arquitetura/base.component';
import { getMobile } from 'src/app/arquitetura/service/base.service';
// import { EventoService } from 'src/app/arquitetura/service/evento.service';
import { LandingpageService } from 'src/app/shared/service/landingpage/landingpage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pg-landing-page',
  templateUrl: `../../../landingpage/page/landingpage.page.html`,
  styleUrls: [
    `../../../landingpage/page/landingpage.page.css`,
  ]
})
export class LandingPage extends BaseComponent {

  step = 0;

  name!: string;
  email!: string;
  telefone!: string;

  constructor(
    // eventoService: EventoService,
    public router: Router,
    private service: LandingpageService,
    public toastController: ToastController,
    public loadingController: LoadingController,
  ) {
    super(router, toastController, loadingController);
    // if (getMobile()) {
    //   this.router.navigate(['/core/home']);
    // }
    // super(eventoService);
  }

  isMobile() {
    return getMobile();
  }

  onRedirect(url: string) {
    this.router.navigate([url]);
  }

  onOpen(url: string) {
    window.open(url, '_blank');
  }

  setStep(step: number) {
    if (step == this.step) {
      this.step = 0;
      return;
    }

    this.step = step;
  }

  onEnviar() {
    if (!this.name) {
      // this.adicionarMensagemAlerta('Nome é obrigatório!')
      return;
    }

    if (!this.email) {
      // this.adicionarMensagemAlerta('E-mail é obrigatório!')
      return;
    }

    if (!this.telefone) {
      // this.adicionarMensagemAlerta('Celular é obrigatório!')
      return;
    }

    // this.service.register(this.name, this.email, this.telefone).toPromise().then((resultado) =>{
    //   // this.adicionarMensagemSucesso('Informações registradas com sucesso!');
    // }).catch((error) =>{
    //   // this.adicionarMensagemSucesso('Informações registradas com sucesso!');
    // });
  }
}
