import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { getAccountId, getToken, isValidCNPJ, isValidCPF } from "../../service/base.service";
import { TransferenciaService } from "src/app/shared/service/transferencias/transferencia.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-fazer-transferencia',
  templateUrl: './fazer-transferencia.component.html',
  styleUrls: [
    './fazer-transferencia.component.scss',
    `../../../../themes/xpory/css/component/transferencias/fazer-transferencia.component.scss`,
    `../../../../themes/xpory-futebol/css/component/transferencias/fazer-transferencia.component.scss`,
    `../../../../themes/fcj-barter/css/component/transferencias/fazer-transferencia.component.scss`,
  ]
})
export class FazerTransferenciaComponent extends BaseComponent {
  theme = environment.theme_name;
  nome!: string;
  step: number = 1;
  entidades: Usuario[] = [];
  id!: number;
  description!: string;
  value!: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private servico: TransferenciaService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    
  }
  
  buscar(){
    var token = getToken();
    var accountId = getAccountId();

    var count = this.nome.replace(/[^\d]/g, '').length;

    if(count === 11){
      var cpfValido = isValidCPF(this.nome);

      if(cpfValido){
        this.servico.buscarPessoa(this.nome, false, token, accountId).then((resultado) =>{
          if(resultado.ok === false)
            this.message(resultado.msg);
          else{
            this.entidades.push(resultado.entity as Usuario);
            this.step = 2;
          }
        });    
      }else{
        this.message("CPF informado é inválido!")
      }
    }
    else if(count === 14){
      var cnpjValido = isValidCNPJ(this.nome);

      if(cnpjValido){
        this.servico.buscarPessoa(this.nome, true, token, accountId).then((resultado) =>{
          if(resultado.ok === false)
            this.message(resultado.msg);
          else{
            this.entidades.push(resultado.entity as Usuario);
            this.step = 2;
          }
        });    
      }else{
        this.message("CNPJ informado é inválido!")
      }
    }
    else{
      this.servico.buscarEntidades(this.nome, token, accountId).then((resultado) =>{
        if(resultado.ok === false)
          this.message(resultado.msg);
        else{
          this.entidades = resultado.entities as Usuario[];
          this.step = 2;
        }
      });
    }
  }

  continuar(){
    this.entidades.forEach((entidade) =>{
      if(entidade.select == 'on'){
        this.id = entidade.id;
        this.step = 3;
        return;
      }
    });

    if(this.step < 3){
      this.message("Selecione um fornecedor!");
    }
  }

  concluir(){
    if(!this.description)
      this.message("Descrição é obrigatória!");
    else if (!this.value)
      this.message("Valor é obrigatório!");
    else
      this.router.navigate(['/transferencias/fazer-transferencia-detalhe', { id: this.id, description: this.description, value: this.value }]);
  }

  async lerQrCode() {
    const data = await BarcodeScanner.scan();

    try {
      const requestDetails = JSON.parse(data.text);

      this.router.navigate(
        [
          '/transferencias/fazer-transferencia-detalhe',
          { id: requestDetails.id, description: requestDetails.description, value: requestDetails.value }
        ]
      );

    } catch (e) {
      console.log(e);
      this.message('QR Code inválido!');
    }
  }
}