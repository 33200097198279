import { Component } from "@angular/core";

@Component({
  selector: 'pg-meus-emprestimos-em-rs',
  templateUrl: './meus-emprestimos-em-rs.page.html',
  styleUrls: ['./meus-emprestimos-em-rs.page.scss']
})
export class MeusEmprestimosEmRSPage {

  constructor(){
  }
}