export class Question{
    answer: Response;
    answerA: string;
    answerB: string;
    answerC: string;
    answerD: string;
    answerE: string;
    difficulty: number;
    id: number;
    question: string;
}

export class Response{
    correctAnswer: string;
    explanation: string;
    optionAnswered: string;
}