import { NgModule } from '@angular/core';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { DDIComponent } from './selects/ddi/ddi.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { EstadoComponent } from './selects/estado/estado.component';
import { CidadeComponent } from './selects/cidade/cidade.component';
import { RodapeComponent } from './rodape/rodape.component';
import { OfertaComponent } from './cards/oferta/oferta.component';
import { SaldoComponent } from './cards/saldo/saldo.component';
import { PerfilComponent } from './cards/perfil/perfil.component';
import { TipoPerfilComponent } from './selects/tipo-perfil/tipo-perfil.component';
import { CategoriaComponent } from './selects/categoria/categoria.component';
import { CategoriasComponent } from './selects/categorias/categorias.component';
import { TipoOfertaComponent } from './selects/tipo-oferta/tipo-oferta.component';
import { EstornoComponent } from './cards/estorno/estorno.component';
import { CondicaoProdutoComponent } from './selects/condicao-produto/condicao-produto.component';
import { TipoFreteComponent } from './selects/tipo-frete/tipo-frete.component';
import { ImagemComponent } from './cards/imagem/imagem.component';
import { OrdenarPorComponent } from './selects/ordernar-por/ordenar-por.component';
import { SafePipe } from './pipe/SafePipe';
import { ParcelasComponent } from './selects/parcelas/parcelas.component';
import { CardMenuPerfilComponent } from '../page/dashboard/card-menu-perfil/card-menu-perfil.component';
import { MenuPerfilComponent } from '../page/dashboard/menu-perfil/menu-perfil.component';
import { DashboardComponent } from '../page/dashboard/dashboard/dashboard.component';

@NgModule({
  declarations: [
    CabecalhoComponent,
    RodapeComponent,
    DDIComponent,
    EstadoComponent,
    CidadeComponent,
    OfertaComponent,
    SaldoComponent,
    PerfilComponent,
    CardMenuPerfilComponent,
    MenuPerfilComponent,
    DashboardComponent,
    TipoPerfilComponent,
    CategoriaComponent,
    CategoriasComponent,
    TipoOfertaComponent,
    EstornoComponent,
    CondicaoProdutoComponent,
    TipoFreteComponent,
    ImagemComponent,
    OrdenarPorComponent,
    SafePipe,
    ParcelasComponent
  ],
  exports: [
    CabecalhoComponent,
    RodapeComponent,
    DDIComponent,
    EstadoComponent,
    CidadeComponent,
    OfertaComponent,
    SaldoComponent,
    PerfilComponent,
    CardMenuPerfilComponent,
    MenuPerfilComponent,
    DashboardComponent,
    TipoPerfilComponent,
    CategoriaComponent,
    CategoriasComponent,
    TipoOfertaComponent,
    EstornoComponent,
    CondicaoProdutoComponent,
    TipoFreteComponent,
    ImagemComponent,
    OrdenarPorComponent,
    SafePipe,
    ParcelasComponent,

    CommonModule,
    BrowserModule
  ],
  imports: [
    CommonModule,
    BrowserModule
  ]
})
export class ArquiteturaModule { }
