import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { CentralDeAjudaPage } from './central-de-ajuda.page';

@NgModule({
  declarations: [
    CentralDeAjudaPage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class CentralDeAjudaModule { }
