import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { TransferenciaService } from "src/app/shared/service/transferencias/transferencia.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { getAccountId, getToken } from "../../service/base.service";
import { environment } from "src/environments/environment";
import { ComissaoService } from "src/app/shared/service/comissao/comissao.service";
import { Comissao } from "src/app/shared/model/comissao/comissao.model";
import { CartaoService } from "src/app/shared/service/financeiro/cartao.service";
import { Cartao } from "src/app/shared/model/financeiro/cartao.model";

@Component({
  selector: 'cmp-fazer-transferencia-detalhe',
  templateUrl: './fazer-transferencia-detalhe.component.html',
  styleUrls: [
    './fazer-transferencia-detalhe.component.scss',
    `../../../../themes/xpory/css/component/transferencias/fazer-transferencia-detalhe.component.scss`,
    `../../../../themes/xpory-futebol/css/component/transferencias/fazer-transferencia-detalhe.component.scss`,
    `../../../../themes/fcj-barter/css/component/transferencias/fazer-transferencia-detalhe.component.scss`,
  ]
})
export class FazerTransferenciaDetalheComponent extends BaseComponent {
  theme = environment.theme_name;
  comissao: Comissao = new Comissao;
  entidade: Usuario;
  cartoes: Cartao[] = [];
  value: any;
  description: any;
  hoje: Date = new Date();
  voucher: any;
  showCartao: boolean;

  numeroCartao: string;
  nomeCartao: string;
  validadeCartao: string;
  cvcCartão: string;
  salvarCartao: boolean;

  cartaoSelecionado: Cartao;
  cvcCartaoSelecionado: string;
  parcelas: number;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private servicoUsuario: UsuarioService,
    private servicoTransferencia: TransferenciaService,
    private comissaoService: ComissaoService,
    private cartaoService: CartaoService){
    super(router, toastController, loadingController);
    const entidadeId = this.route.snapshot.paramMap.get('id');
    this.description = this.route.snapshot.paramMap.get('description');
    this.value = this.route.snapshot.paramMap.get('value');

    this.carregarUsuario(entidadeId);
    this.carregarComissao();
    this.listarCartoes();
  }

  carregarUsuario(id: any){
    this.servicoUsuario.simplificado(id).then((entidade) =>{
      this.entidade = entidade.entity as Usuario;
      console.log(this.entidade);
    })
  }

  carregarComissao(){
    var token = getToken();
    var accountId = getAccountId();
    this.comissaoService.obter(token, accountId).then((resultado) =>{
      this.comissao = resultado as Comissao;
    })
  }

  concluir(){
    var token = getToken();
    var accountId = getAccountId();

    if(this.getValorPendente() > 0){
      if(!this.cartaoSelecionado){
        this.message("Selecione um cartão!");
        return;
      }

      if(!this.cvcCartaoSelecionado){
        this.message("Informe o código de segurança do cartão!");
        return;
      }

      if(this.getValorPendente() < 200){
        this.parcelas = 1;
      }else{
        if(!this.parcelas){
          this.message("Informe a quantidade de parcelas!");
          return;
        }
      }
    }
    
    if(this.cartaoSelecionado){
      this.servicoTransferencia.transferirCartao(this.entidade.id, this.description, this.value, token, accountId, this.cartaoSelecionado.id, this.cvcCartaoSelecionado, this.parcelas).then((resultado) =>{
        if(resultado.ok === true){
          this.message("Transferência concluida com sucesso!");
          this.router.navigate(['/transferencias/minhas-transferencias']);
        }else{
          this.message(resultado.msg)
        }
      });
    }else{
      this.servicoTransferencia.transferir(this.entidade.id, this.description, this.value, token, accountId).then((resultado) =>{
        if(resultado.ok === true){
          this.message("Transferência concluida com sucesso!");
          this.router.navigate(['/transferencias/minhas-transferencias']);
        }else{
          this.message(resultado.msg)
        }
      });
    }
  }

  imprimir(){
    var token = getToken();
    var accountId = getAccountId();
    window.open(`${environment.api}v1/download-voucher?accountId=${accountId}&token=${token}&voucherCode=${this.voucher}`, '_system');
  }

  onAdicionar(){
    var token = getToken();
    var accountId = getAccountId();
    var cartao = new Cartao;
    cartao.cardNumber = this.numeroCartao;
    cartao.holder = this.nomeCartao;
    cartao.expirationDate = this.validadeCartao;
    cartao.saveCard = this.salvarCartao;
    cartao.brand = this.verificarBandeiraCartao(this.numeroCartao);

    this.cartaoService.adicionar(token, accountId, cartao).then((resultado) => {
      if(resultado.ok === true){
        this.listarCartoes();
        this.showCartao = false;
      }else{
        this.message(resultado.msg);
      }
    }).catch((ex) =>{
      console.log(ex.data);
    });
  }

  verificarBandeiraCartao(numeroCartao: string) {
    if (/^4/.test(numeroCartao)) {
      return "Visa";
    } else if (/^5[1-5]/.test(numeroCartao)) {
      return "Master";
    } else if (/^3[47]/.test(numeroCartao)) {
      return "American Express";
    } else if (/^6(011|5)/.test(numeroCartao)) {
      return "Discover";
    } else if (/^3(0[0-5]|6|8)/.test(numeroCartao)) {
      return "Diners Club";
    } else if (/^35/.test(numeroCartao)) {
      return "JCB";
    } else {
      return "Desconhecida";
    }
  }

  getValorPendente(){
    return (this.value * this.comissao.comissionPercentage) - this.comissao.rBalance;
  }

  listarCartoes(){
    var token = getToken();
    var accountId = getAccountId();
    this.cartaoService.listar(token, accountId).then((resultado) =>{
      if(resultado.ok === true){
        this.cartoes = resultado.savedCards as Cartao[];
      }else{
        this.message(resultado.msg);
      }
    });
  }

  removerCartao(id: number){
    var token = getToken();
    var accountId = getAccountId();
    this.cartaoService.remover(id, token, accountId).then((resultado) =>{
      if(resultado.ok === true){
        this.listarCartoes();
        this.cartaoSelecionado = undefined;
        this.parcelas = undefined;
      }else{
        this.message(resultado.msg);
      }
    });
  }

  selecionaCartao(cartao: Cartao){
    cartao.selected = !cartao.selected;
    if(cartao.selected === true){
      this.cartaoSelecionado = cartao;
    }
    else
      this.cartaoSelecionado = undefined;
  }

  onSelectParcelas(id: any){
    this.parcelas = id;
  }

  onDisableConcluir() : boolean{
    if(this.cartaoSelecionado && this.cvcCartaoSelecionado)
      return false;
    if(this.value * this.comissao.comissionPercentage > this.comissao.rBalance)
      return true;
    else
      return false;
  }
}