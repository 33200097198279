import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { getAccountId, getToken, getUsuarioLogado } from "../../service/base.service";
import { Router } from "@angular/router";
import { EmprestimoService } from "src/app/shared/service/financeiro/emprestimo.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-solicitar-antecipacao',
  templateUrl: './solicitar-antecipacao.component.html',
  styleUrls: [
    './solicitar-antecipacao.component.scss',
    `../../../../themes/xpory/css/component/financeiro/solicitar-antecipacao.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/solicitar-antecipacao.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/solicitar-antecipacao.component.scss`,
  ]
})
export class SolicitarAntecipacaoComponent extends BaseComponent {
  theme = environment.theme_name;
  termo: boolean = false;
  valor: number;
  valorSolicitado: number;
  taxaEmX: number;
  modalShow: boolean = false;
  vencimento: any;
  credito: number;
  messageSucesso: string;

  constructor(
    public router: Router,
    private service: EmprestimoService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    var usuario = JSON.parse(getUsuarioLogado()) as Usuario;
    console.log(usuario);
    this.credito = usuario.userLimit.advanceLimit;
  }

  solicitar() {
    if (!this.termo) {
      this.message('Termo deve ser selecionado!');
    } else {
      var token = getToken();
      var accountId = getAccountId();
      this.service.advance(token, accountId, this.valor).then((resultado) => {
        if (resultado.ok == false) {
          this.message(resultado.msg);
        } else if (resultado.msg) {
          this.messageSucesso = resultado.msg;
          this.valorSolicitado = resultado.newLimit;
          this.vencimento = resultado.dueDate;
          this.taxaEmX = this.valor * 4;
          this.modalShow = true;
        }
      }).catch((ex) => {
        if (ex && ex.error) {
          var json = JSON.parse(ex.error);
          if (json)
            this.message(json.msg)
        }
      });
    }
  }

  fecharConfirmacao() {
    this.modalShow = false;
  }
}