import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DDIModel } from 'src/app/shared/model/endereco/ddi.model';
import { EnderecoService } from 'src/app/shared/service/endereco/endereco.service';
import { BaseComponent } from '../../base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'cmp-ddi',
  templateUrl: './ddi.component.html',
  styleUrls: ['./ddi.component.scss']
})
export class DDIComponent extends BaseComponent {

  ddis: DDIModel[] = [];
  valor: number;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected service: EnderecoService) { 
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.service.ddis().then((resultado) =>{
      this.ddis = resultado as DDIModel[];
    });
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }

  setDDI(id: any){
    console.log(id);
    this.service.ddis().then((resultado) =>{
      console.log(resultado);
      this.ddis = resultado as DDIModel[];

      console.log(this.ddis);
      this.ddis.forEach((ddi) =>{
        if(ddi.id == id){
          console.log(ddi);
          this.valor = ddi.id;
        }
      })
    });
  }
}
