import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PainelComprasPage } from './painel-compras.page';
import { PainelComprasComponent } from 'src/app/arquitetura/compras/painel-compras/painel-compras.component';

@NgModule({
  declarations: [
    PainelComprasPage,
    PainelComprasComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PainelComprasModule { }
