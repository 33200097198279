import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AccordionComponent } from './ui/accordion/accordion.component';

@NgModule({
  declarations: [
    AccordionComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    AccordionComponent
  ],
})
export class UiModule { } 