import { Component, Input, ContentChild, AfterContentInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'cmp-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit {
  @Input() title: string = '';
  @Input() value: string = 'first';
  @Input() titleClass: string | null = null;
  @Input() accordionClass: string = '';
  @Input() noArrow: boolean = false;
  @Input() expanded: boolean = false;

  @Input() titleSlot: TemplateRef<any>;
  
  hasCustomTitle: boolean = false;

  ngAfterContentInit() {
    this.hasCustomTitle = !!this.titleSlot;
  }

  randomId() {
    const id = Math.random().toString(36).substring(2, 15)
    return id;
  }
} 