import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TransferenciaService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, transferType: string, offset: number, max: number){
    return this.http.post(`${environment.api}v2/list-transfers?max=${max}&offset=${offset}&transferType=${transferType}&token=${token}`, { accountId: accountId }, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, transferType: string, inicio: string, fim: string, offset: number, max: number){
    return this.http.post(`${environment.api}v2/list-transfers?initialDate=${inicio}&endDate=${fim}&max=${max}&offset=${offset}&transferType=${transferType}&token=${token}`, { accountId: accountId }, this.config()).then(data => JSON.parse(data.data));
  }

  buscarEntidades(name: string, token: string, accountId: number){
    return this.http.get(`${environment.api}v1/search-entity-name?name=${name}&token=${token}&accountId=${accountId}`, null, this.configTokenSemState()).then(data => JSON.parse(data.data));
  }

  buscarPessoa(name: string, isComany: boolean, token: string, accountId: number){
    return this.http.get(`${environment.api}v1/find-entity?providerIndentifier=${name}&isCompany=${isComany}&token=${token}&accountId=${accountId}`, null, this.configTokenSemState()).then(data => JSON.parse(data.data));
  }

  solicitar(desricao: string, valor: number){
    const headers = this.configToken();

    return this.http.sendRequest(
      `${environment.api}v2/request-transfer/`,
      { 
        method: 'post', 
        headers: headers, 
        data: { 
          transferDescription: desricao, 
          transferValue: valor 
        },
        serializer: 'json',
      }
    ).then(data => JSON.parse(data.data))
  }

  transferir(id: number, description: string, value: number, token: string, accountId: number){
    return this.http.post(`${environment.api}v1/perform-transfer-user?paymentParams=%7B%22paymentType%22:%22balanceAccount%22,%22installments%22:1%7D&entityReceiverId=${id}&description=${description}&value=${value}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  transferirCartao(id: number, description: string, value: number, token: string, accountId: number, cartao: number, securityCode: string, parcelas: number){
    return this.http.post(`${environment.api}v1/perform-transfer-user?paymentParams=%7B%22paymentType%22:%22creditCard%22,%22securityCode%22:${securityCode},%22cieloTokenCardId%22:${cartao},%22installments%22:${parcelas}%7D&entityReceiverId=${id}&description=${description}&value=${value}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}