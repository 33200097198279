import { Component } from "@angular/core";

@Component({
  selector: 'pg-meu-perfil',
  templateUrl: './meu-perfil.page.html',
  styleUrls: ['./meu-perfil.page.scss']
})
export class MeuPerfilPage {

  constructor(){
  }
}