import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExpirarService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, expired: number, offset: number, max: number) {
    return axios.get(`${environment.api}v1/get-x-transaction-maturities`, {
      params: {
        token,
        accountId,
        offset,
        max,
        expired
      },
      headers: this.config()
    }).then(response => response.data);
  }

  listarData(token: string, accountId: number, expired: number, inicio: string, fim: string, offset: number, max: number) {
    return axios.get(`${environment.api}v1/get-x-transaction-maturities`, {
      params: {
        token,
        accountId,
        offset,
        max,
        expired,
        initialDate: inicio,
        endDate: fim
      },
      headers: this.config()
    }).then(response => response.data);
  }
}