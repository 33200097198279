import { Component } from "@angular/core";
import { LoadingController, NavController, Platform, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Blog } from "src/app/shared/model/geral/blog.model";
import { BlogService } from "src/app/shared/service/blog/blog.service";
import { Oferta } from "src/app/shared/model/ofertas/oferta.model";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { OfertaResumida, OfertasResumida, OfertasResumidaCategoria } from "src/app/shared/model/ofertas/oferta-resumida.model";
import { CategoriaService } from "src/app/shared/service/categoria/categoria.service";
import { CategoriasModel } from "src/app/shared/model/categoria/categorias.model";
import { Categoria } from "src/app/shared/model/categoria/categoria.model";
import { getMobile, getRandomElement } from "../../service/base.service";
import { TipsService } from "src/app/shared/service/tips/tips.service";
import { Tips } from "src/app/shared/model/geral/tips.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.scss',
  ]
})
export class HomeComponent extends BaseComponent {
  theme = environment.theme_name;

  categoriaNome: string;
  categorias: CategoriasModel;
  destaques: Oferta[] = [];
  ofertas: OfertaResumida[] = [];
  ofertasCategoria: OfertaResumida[] = [];
  blogs: Blog[] = [];
  tips: Tips[] = [];
  destaque: OfertaResumida;

  destaquesOptions = {
    observer: true,
    observeParents: true,
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    autoplay: true,
    speed: 600
  };

  ofertasOptions = {
    observer: true,
    observeParents: true,
    slidesPerView: getMobile() ? 1.1 : 3,
    spaceBetween: 10,
    loop: true,
    autoplay: false,
    speed: 600
  };

  tipsOptions = {
    observer: true,
    observeParents: true,
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    autoplay: true,
    speed: 600,
    delay: 500
  };

  theme_name: string;

  carouselResponsiveOptions;

  constructor(
    public router: Router,
    private platform: Platform,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected service: OfertasService,
    protected serviceCategoria: CategoriaService,
    protected serviceBlog: BlogService,
    protected serviceTips: TipsService,
    protected navCtrl: NavController
  ) {
    super(router, toastController, loadingController);


    this.carouselResponsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      // this.openFullLoading('Carregando...');
      // this.listarBlog();

      Promise.all([
        this.listarTips(),
        this.listarDestaque(),
        this.listarOfertas(),

      ]).finally(() => {
        setTimeout(() => {
          this.closeFullLoading();
        }, 1000);

        Promise.all([
          this.listarCategorias(),
          this.listarBlog(),
        ])
      });
    });
  }

  onRecarregar() {
    this.listarTips();
    this.listarDestaque();
    this.listarOfertas();
    this.listarCategorias();
  }

  async listarTips() {
    const resultado = await this.serviceTips.listar();
    if (resultado) {
      this.tips = resultado.tips as Tips[];
    }
  }

  async listarDestaque() {
    this.destaques = [];
    const resultado = await this.service.listarDestaque(1);
    const destaques = resultado as OfertaResumida[];

    if (destaques) {
      const tmp = await this.service.obter(destaques[0].id);
      this.destaque = tmp.offer;
      console.log(`🥷🏼 fsk >> HomeComponent >> this.destaque:`, this.destaque);
      // resultados.forEach(temp => {
      //   this.destaques.push(temp.offer);
      // });
    }
  }

  async listarOfertas() {
    this.ofertas = [];
    const resultado = await this.service.listarRandom(10);
    const offers = resultado as OfertasResumida;

    if (offers && offers.offerList) {
      this.ofertas = offers.offerList;
      const promessas = this.ofertas.map(oferta => this.service.obter(oferta.id));
      const resultados = await Promise.all(promessas);

      resultados.forEach(temp => {
        this.ofertas.push(temp.offer);
      });
    }
  }

  async listarCategorias() {
    const resultado = await this.serviceCategoria.listar();
    this.categorias = resultado as CategoriasModel;

    const categoria = getRandomElement(this.categorias.categories) as Categoria;
    this.categoriaNome = categoria.name;

    await this.listarOfertasPorCategoria(categoria.id);
  }

  async listarOfertasPorCategoria(categoriaId: number) {
    this.ofertasCategoria = [];
    const resultado = await this.service.listarPorCategoria(categoriaId, 10);
    const offers = resultado as OfertasResumidaCategoria;

    if (offers && offers.offerList.length > 0) {
      this.ofertasCategoria = this.service.dePara(offers.offerList);
    }
  }

  async listarBlog() {
    this.blogs = [];
    const resultado = await this.serviceBlog.listar();
    this.blogs = resultado as Blog[];

    if (this.blogs) {
      const promessas = this.blogs.map(blog => this.obterBlogImagem(blog));
      await Promise.all(promessas);
    }
  }

  obterBlogImagem(blog: Blog) {
    this.serviceBlog.obter(blog.id).then((resultado) => {
      if (resultado && resultado.length > 0) {
        blog.source_url = resultado[0].guid.rendered;
      }
    });
  }

  abrirLink(link: string) {
    window.open(link, "_blank");
  }
}