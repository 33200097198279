import { Component } from "@angular/core";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Router } from "@angular/router";
import { Estatisticas } from "src/app/shared/model/perfil/estatisticas.model";
import { EstatisticasService } from "src/app/shared/service/perfil/estatisticas.service";
import { Share } from '@capacitor/share';
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-meu-perfil',
  templateUrl: './meu-perfil.component.html',
  styleUrls: [
    './meu-perfil.component.scss',
    `../../../../themes/xpory/css/component/perfil/meu-perfil.component.scss`,
    `../../../../themes/xpory-futebol/css/component/perfil/meu-perfil.component.scss`,
    `../../../../themes/fcj-barter/css/component/perfil/meu-perfil.component.scss`,
  ]
})
export class MeuPerfilComponent extends BaseComponent {
  theme = environment.theme_name;
  step: number = 0;
  usuario: Usuario;
  estatisticas: Estatisticas;
  isVerificada: boolean;
  email: string;

  options = {  
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 10,
    loop: false
  };

  constructor(
    private navCtrl: NavController,
    private clipboard: Clipboard,
    private socialSharing: SocialSharing,
    private service: UsuarioService,
    private servicoEstatisticas: EstatisticasService,
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaUsuario();
    this.carregaEstatisticas();
  }

  carregaUsuario(){
    var token = getToken();
    var accountId = getAccountId();
    this.service.get(token, accountId).then((resultado: any) =>{
      if(resultado.ok === false){
        clearStorage();
        this.navCtrl.navigateForward(['/core/home']).then(() =>{
          window.location.reload();
        });
      }else{
        this.usuario = resultado.entity as Usuario;
      }
    }).catch((ex) =>{
      console.log(ex);
    });
  }

  carregaEstatisticas(){
    var token = getToken();
    var accountId = getAccountId();
    this.servicoEstatisticas.get(token, accountId).then((resultado) =>{
      this.estatisticas = resultado as Estatisticas;
    }).catch((ex) =>{
      // this.navCtrl.navigateForward('/onboarding/login');
    });
  }

  copiar(){
    this.clipboard.copy(this.usuario.codeIndication);
    this.message(`Código copiado!`);
  }

  onFacebook(){
    this.socialSharing.shareViaFacebook(this.usuario.codeIndication).then(() => {
        this.message("Código compartilhado no Facebook!");
    }).catch((error) =>{
        this.message("Erro ao compartilhar no Facebook!");
    })
  }

  onShare(){
    Share.share({
      title: 'Código de indicação',
      text: `Olá, este é meu código de indicação {{label.title}}: ${this.usuario.codeIndication}.`,
      dialogTitle: 'Compartilhe o código de indicação',
    });
  }

  onWhatsapp(){
    this.router.navigate([]).then(result => {  window.open(`https://api.whatsapp.com/send?phone=${this.usuario.countryCode + this.usuario.cellNumber}&text=${this.usuario.codeIndication}`, '_blank'); });
  }

  onEditar(){
    this.navCtrl.navigateForward(['/perfil/editar-perfil']);
  }
}