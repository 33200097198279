import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TransacaoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, offset: number, max: number) {
    return this.http.post(`${environment.api}v2/get-x-transactions`, { accountId: accountId, token: token, max: max, offset: offset }, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, offset: number, max: number, inicio: string, fim: string){
    return this.http.post(`${environment.api}v2/get-x-transactions`, { accountId: accountId, token: token, max: max, offset: offset, initialDate: inicio, endDate: fim }, this.config()).then(data => JSON.parse(data.data));
  }

  listarReal(token: string, accountId: number, offset: number, max: number) {
    return this.http.post(`${environment.api}v2/get-currency-transactions`, { accountId: accountId, token: token, max: max, offset: offset }, this.config()).then(data => JSON.parse(data.data));
  }

  listarRealData(token: string, accountId: number, offset: number, max: number, inicio: string, fim: string){
    return this.http.post(`${environment.api}v2/get-currency-transactions`, { accountId: accountId, token: token, max: max, offset: offset, initialDate: inicio, endDate: fim }, this.config()).then(data => JSON.parse(data.data));
  }
}