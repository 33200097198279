import { Component } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { getAccountId, getToken } from '../../service/base.service';
import { VendasService } from 'src/app/shared/service/vendas/vendas.service';
import { BarcodeScanner  } from '@awesome-cordova-plugins/barcode-scanner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmp-validar-voucher',
  templateUrl: './validar-voucher.component.html',
  styleUrls: [
    './validar-voucher.component.scss',
    `../../../../themes/xpory/css/component/vendas/validar-voucher.component.scss`,
    `../../../../themes/xpory-futebol/css/component/vendas/validar-voucher.component.scss`,
    `../../../../themes/fcj-barter/css/component/vendas/validar-voucher.component.scss`,
  ]
})
export class ValidarVoucherComponent extends BaseComponent {
  theme = environment.theme_name;
  voucher: string;

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private service: VendasService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {

  }

  consultarVoucher(){
    const token = getToken();
    const accountId = getAccountId();
    this.service.consultaVoucher(token, accountId, this.voucher).then((resultado) =>{
      if(resultado){
        if(resultado.ok === false){
          this.message(resultado.msg);
        }


      }
      console.log(resultado);
    }).catch((ex) =>{

    });
  }

  async lerQrCode() {
    const data = await BarcodeScanner.scan();

    try {
      const url = new URL(data.text);
      const voucher = url.searchParams.get('voucherCode');

      if (voucher){
        this.voucher = voucher;
      } else {
        this.message('Voucher não encontrado');
      }
    } catch (e) {
      console.log(e);
      this.message('Voucher não encontrado');
    }
  }
}
