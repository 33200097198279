import { Component, EventEmitter, Output } from "@angular/core";
import { LoginModel } from "src/app/shared/component/login/login.model";
import { BaseComponent } from "../../base.component";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { LoginService } from "src/app/shared/service/login/login.service";
import { clearStorage, setAccountId, setEntityId, setToken, setUsuarioLogado } from "../../service/base.service";
import { Router } from "@angular/router";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    `../../../../themes/xpory/css/component/onboarding/login.component.scss`,
    `../../../../themes/xpory-futebol/css/component/onboarding/login.component.scss`,
    `../../../../themes/fcj-barter/css/component/onboarding/login.component.scss`,
  ]
})
export class LoginComponent extends BaseComponent{
  theme = environment.theme_name;
  loginModel!: LoginModel;
  email!: string;
  senha!: string;

  @Output() entrar: EventEmitter<any> = new EventEmitter();

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected navCtrl: NavController,
    protected service: LoginService,
    protected usuarioService: UsuarioService){
        super(router, toastController, loadingController);
  }

  login(){
    console.log(1);
    this.openLoading('Carregando...');
    this.service.login(this.email, this.senha).then((resultado) => {
      console.log(2);
      this.loginModel = resultado as LoginModel;
      
      if(this.loginModel && this.loginModel.ok){
        console.log(3);
        setAccountId(this.loginModel.accountId);
        setEntityId(this.loginModel.entityId);
        setToken(this.loginModel.token);
        this.usuarioService.get(this.loginModel.token, this.loginModel.accountId).then((resultado) => {
          console.log(4);
          if(resultado.ok == false){
            clearStorage();
          }else{
            setUsuarioLogado(resultado.entity)
            this.navCtrl.navigateForward('/core/home').then(() =>{
               window.location.reload();
            });
          }
        });
      }
    }).catch((ex) =>{
      console.log(5);
      console.log(ex);
      if(ex.error){
        if(ex.error.valid === false){
            this.navCtrl.navigateForward('/onboarding/confirmar-cadastro', { queryParams: { id: ex.error.id, celular: ex.error.cellNumber } });
        }else{
          this.message(JSON.parse(ex.error).msg);
        }
      }
    }).finally(() => {
        this.closeLoading();
    });
  }
  
  esqueci(){
    this.navCtrl.navigateForward('/onboarding/recuperar');
  }

  cadastrar(){
    this.navCtrl.navigateForward('/onboarding/cadastrar');
  }
}