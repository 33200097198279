import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PainelIndicacaoPage } from './painel-indicacao.page';
import { PainelIndicacaoComponent } from 'src/app/arquitetura/indicacoes/painel-indicacao/painel-indicacao.component';

@NgModule({
  declarations: [
    PainelIndicacaoPage,
    PainelIndicacaoComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PainelIndicacaoModule { }
