import { Component, ViewChild } from "@angular/core";
import { OfertasComponent } from "src/app/arquitetura/core/ofertas/ofertas.component";

@Component({
  selector: 'pg-ofertas',
  templateUrl: './ofertas.page.html',
  styleUrls: ['./ofertas.page.scss']
})
export class OfertasPage {

  @ViewChild('ofertas') ofertas: OfertasComponent;

  constructor(){
    
  }

  onBuscar(texto: any){
    this.ofertas.listar(texto);
  }
}