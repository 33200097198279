import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class IndicacaoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(accountId: number, offset: number, max: number){
    return this.http.post(`${environment.api}v1/list-indications?offset=${offset}&max=${max}`, null, this.configToken()).then(data => JSON.parse(data.data));
  }

  indicar(emailReceiver: string[]){
    return this.http.post(`${environment.api}v1/indication-user`, { emailReceiver: emailReceiver }, this.config()).then(data => JSON.parse(data.data));
  }
}