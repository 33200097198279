import { Component, ViewChild } from "@angular/core";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { Oferta } from "src/app/shared/model/ofertas/oferta.model";
import { OfertaResumida } from "src/app/shared/model/ofertas/oferta-resumida.model";
import { getAccountId, getMobile, getToken } from "../../service/base.service";
import { ComissaoService } from "src/app/shared/service/comissao/comissao.service";
import { Comissao } from "src/app/shared/model/comissao/comissao.model";
import { Cartao } from "src/app/shared/model/financeiro/cartao.model";
import { CartaoService } from "src/app/shared/service/financeiro/cartao.service";
import { ParcelasComponent } from "../../selects/parcelas/parcelas.component";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-oferta-detalhe',
  templateUrl: './oferta-detalhe.component.html',
  styleUrls: [
    './oferta-detalhe.component.scss',
  ]
})
export class OfertaDetalheComponent extends BaseComponent {
  theme = environment.theme_name;

  comissao: Comissao = new Comissao;
  oferta: Oferta;
  ofertas: OfertaResumida[] = [];
  cartoes: Cartao[] = [];
  orcamento: boolean;
  confirmacao: boolean;
  showCartao: boolean;
  voucherConfirmacao!: string;

  limiteCliente: number = 999;
  quantidade: number = 1;

  numeroCartao: string;
  nomeCartao: string;
  validadeCartao: string;
  cvcCartao: string;
  salvarCartao: boolean;

  cartaoSelecionado: Cartao;
  cvcCartaoSelecionado: string;
  parcelas: number;

  ofertasOptions = {  
    observer: true,
    observeParents: true,
    slidesPerView: getMobile() ? 1.1 : 4,
    spaceBetween: 10,
    loop: true,
    autoplay: false,
    speed: 600
  };

  thumbnails: string[] = [];
  currentThumbnail: string;

  constructor(
    public toastController: ToastController, 
    public loadingController: LoadingController,
    public router: Router,
    protected navCtrl: NavController,
    protected service: OfertasService,
    private comissaoService: ComissaoService,
    private route: ActivatedRoute,
    private cartaoService: CartaoService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.obtemOferta(queryParams['id']);
    });

    this.carregarComissao();
    this.listarCartoes();
  }

  obtemOferta(id: any){
    this.service.obter(id).then((resultado) =>{
      if(resultado){
        this.oferta = resultado.offer as Oferta;
        if(this.oferta.limitByClient > 0) {
          this.limiteCliente = this.oferta.limitByClient;
        } else {
          this.limiteCliente = this.oferta.offersAvailable;
        }

        this.populateThumbnails(this.oferta);
        this.listarOfertas(this.oferta.provider.entityId);
      }
    })
  }
  
  listarOfertas(id: number){
    this.service.listarUsuario(id, 4).then((resultado) =>{
      console.log(resultado);
      if(resultado.ok){
        this.ofertas = resultado.offers as OfertaResumida[];
      }
    });
  }
  
  comprar(){
    if(this.quantidade > this.oferta.offersAvailable){
      this.message("Quantidade informada indisponível!");
      return;
    }

    if(this.oferta.limitByClient > 0 && this.quantidade > this.oferta.limitByClient){
      this.message("Quantidade informada é maior que o limite por cliente!");
      return;
    }

    var token = getToken();
    var accountId = getAccountId();

    if(!token || token == null || !accountId || accountId == null){
      this.navCtrl.navigateForward('/onboarding/login');
    }

    if(this.oferta.isUnderBudget){
      this.orcamento = true;
    }else{
      if(this.oferta.price > this.comissao.xBalance)
        this.message('Saldo de X$ insuficiente');
      else
        this.confirmacao = true;
    }
  }

  whatsapp(){
    this.router.navigate([]).then(result => {  window.open(`https://api.whatsapp.com/send?phone=${this.oferta.provider.countryCode.countryCode + this.oferta.provider.cellNumber}&text=Olá!`, '_blank'); });
  }

  compartilhar(){
    var texto = `Achei essa oferta legal e resolvi compartilhar com você!! (: ${window.location.href}`;
    this.router.navigate([]).then(result => {  window.open(`https://api.whatsapp.com/send?phone=${this.oferta.provider.countryCode.countryCode + this.oferta.provider.cellNumber}&text=${texto}`, '_blank'); });
  }

  denuncia(){
    this.router.navigate([]).then(result => {  window.open(`https://app.pipefy.com/public/form/ipQW8BF4`, '_blank'); });
  }

  onFavoritar(){
    var token = getToken();
    var accountId = getAccountId();

    if(!token || token == null || !accountId || accountId == null){
      this.message("Você precisa estar logado para favoritar essa empresa!");
    }
  }

  fechar(){
    this.orcamento = false;
    this.confirmacao = false;
  }

  pagar(){
    var token = getToken();
    var accountId = getAccountId();

    if(!token || token == null || !accountId || accountId == null){
      this.fechar();
      this.navCtrl.navigateForward('/onboarding/login');
    }

    if(this.getValorPendente() > 0){
      if(!this.cartaoSelecionado){
        this.message("Selecione um cartão!");
        return;
      }

      if(!this.cvcCartaoSelecionado){
        this.message("Informe o código de segurança do cartão!");
        return;
      }

      if(this.getValorPendente() < 200){
        this.parcelas = 1;
      }else{
        if(!this.parcelas){
          this.message("Informe a quantidade de parcelas!");
          return;
        }
      }
    }

    if(this.cartaoSelecionado){
      this.service.compraCartao(this.oferta.id, this.quantidade, token, accountId, this.cartaoSelecionado.id, this.cvcCartaoSelecionado, this.parcelas).then((resultado) => {
        console.log(resultado);
        if(resultado.ok == true && resultado.id){
          this.service.voucher(resultado.id, token, accountId).then((voucher) => {
            if(voucher.listVoucherCode && voucher.listVoucherCode.length > 0)
              this.voucherConfirmacao = voucher.listVoucherCode[0].code;
          })
        }else{
          this.message(resultado.msg)
        }
      })
    }else{
      this.service.compra(this.oferta.id, this.quantidade, token, accountId).then((resultado) => {
        if(resultado.ok == true && resultado.id){
          this.service.voucher(resultado.id, token, accountId).then((voucher) => {
            if(voucher.listVoucherCode && voucher.listVoucherCode.length > 0)
              this.voucherConfirmacao = voucher.listVoucherCode[0].code;
          })
        }else{
          this.message(resultado.msg)
        }
      });
    }
  }

  carregarComissao(){
    var token = getToken();
    var accountId = getAccountId();
    this.comissaoService.obter(token, accountId).then((resultado) =>{
      this.comissao = resultado as Comissao;
    })
  }

  getValor(){
    return this.oferta.price * this.quantidade;
  }

  getValorPendente(){
    return (this.getValor() * this.comissao.comissionPercentage) - this.comissao.rBalance;
  }

  onAdicionar(){
    var token = getToken();
    var accountId = getAccountId();
    var cartao = new Cartao;
    cartao.cardNumber = this.numeroCartao;
    cartao.holder = this.nomeCartao;
    cartao.expirationDate = this.validadeCartao;
    cartao.saveCard = this.salvarCartao;
    cartao.brand = this.verificarBandeiraCartao(this.numeroCartao);

    this.cartaoService.adicionar(token, accountId, cartao).then((resultado) => {
      if(resultado.ok === true){
        this.listarCartoes();
        this.showCartao = false;
      }else{
        this.message(resultado.msg);
      }
    }).catch((ex) =>{
      console.log(ex.data);
    });
  }

  verificarBandeiraCartao(numeroCartao: string) {
    if (/^4/.test(numeroCartao)) {
      return "Visa";
    } else if (/^5[1-5]/.test(numeroCartao)) {
      return "Master";
    } else if (/^3[47]/.test(numeroCartao)) {
      return "American Express";
    } else if (/^6(011|5)/.test(numeroCartao)) {
      return "Discover";
    } else if (/^3(0[0-5]|6|8)/.test(numeroCartao)) {
      return "Diners Club";
    } else if (/^35/.test(numeroCartao)) {
      return "JCB";
    } else {
      return "Desconhecida";
    }
  }

  listarCartoes(){
    var token = getToken();
    var accountId = getAccountId();
    this.cartaoService.listar(token, accountId).then((resultado) =>{
      if(resultado.ok === true){
        this.cartoes = resultado.savedCards as Cartao[];
      }
    });
  }

  removerCartao(id: number){
    var token = getToken();
    var accountId = getAccountId();
    this.cartaoService.remover(id, token, accountId).then((resultado) =>{
      if(resultado.ok === true){
        this.listarCartoes();
        this.cartaoSelecionado = undefined;
        this.parcelas = undefined;
      }else{
        this.message(resultado.msg);
      }
    });
  }

  selecionaCartao(cartao: Cartao){
    cartao.selected = !cartao.selected;
    if(cartao.selected === true){
      this.cartaoSelecionado = cartao;
    }
    else
      this.cartaoSelecionado = undefined;
  }

  onSelectParcelas(id: any){
    this.parcelas = id;
  }

  emitirVoucher(){
    this.fechar();
    setTimeout(() => {
      this.navCtrl.navigateForward('/compras/painel-compras');
    }, 1000);
  }

  onChangeThumbnail(thumbnail: string) {
    this.currentThumbnail = thumbnail;
  }

  populateThumbnails(oferta: Oferta) {
    if (oferta.images) {
      this.thumbnails = [
        oferta.images.urlImage1,
        oferta.images.urlImage2,
        oferta.images.urlImage3,
        oferta.images.urlImage4,
        oferta.images.urlImage5,
        oferta.images.urlImage6,
      ].filter((url) => url !== '');

      this.currentThumbnail = this.thumbnails[0];
    }
  }
}
