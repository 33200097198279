import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { Conta } from "src/app/shared/model/perfil/conta.model";
import { Router } from "@angular/router";

@Component({
  selector: 'cmp-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent extends BaseComponent {

  verificado!: boolean;
  premium!: boolean;
  usuario: Usuario;
  conta: Conta;

  constructor(
    private service: UsuarioService,
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaUsuario()
  }

  carregaUsuario(){
    var token = getToken();
    var accountId = getAccountId();
    this.service.get(token, accountId).then((resultado: any) =>{
      this.usuario = resultado.entity as Usuario;
      this.premium = resultado.plan != null;
      this.conta = resultado.account as Conta;
    }).catch((ex) =>{
      
    });
  }

  formatName(name: string){
    if(name){
      var names = name.split(' ');

      if(names.length == 1)
        return names[0];
      
      return `${names[0]} ${names[1]}`; 
    }

    return name;
  }
}