import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cmp-tipo-oferta',
  templateUrl: './tipo-oferta.component.html',
  styleUrls: ['./tipo-oferta.component.scss']
})
export class TipoOfertaComponent {

  tipos: any[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor() { 
  }

  ngOnInit(): void {
      this.listar();
  }

  listar(): void {
    this.tipos = [
        {name: 'Produto', value: '1', description: 'Produto'},
        {name: 'Serviço', value: '2', description: 'Serviço'}
    ];
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }

  setTipo(tipo: string){
    this.tipos.forEach((item) =>{
      if(tipo === item.value)
        this.valor = item.value;
    });
  }
}
