import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PainelOfertasCadastroPage } from './painel-ofertas-cadastro.page';
import { PainelOfertasCadastroComponent } from 'src/app/arquitetura/minhas-ofertas/painel-ofertas-cadastro/painel-ofertas-cadastro.component';

@NgModule({
  declarations: [
    PainelOfertasCadastroPage,
    PainelOfertasCadastroComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PainelOfertasCadastroModule { }
