import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ParceirosService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(limit: number, offset: number) {
    return this.http.post(`${environment.api}v2/entity`, 
    {   filters: {"limit":limit,"offset":offset},
        order: {"premium":"desc","nickName":"asc"},
        alternativeSelection: true
    }, this.config()).then(data => JSON.parse(data.data));
  }
}