import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { EditarEnderecoPage } from './editar-endereco.page';
import { EditarEnderecoComponent } from 'src/app/arquitetura/perfil/editar-endereco/editar-endereco.component';

@NgModule({
  declarations: [
    EditarEnderecoPage,
    EditarEnderecoComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class EditarEnderecoModule { }
