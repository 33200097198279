import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from 'src/app/arquitetura/base.component';
import { getAccountId, getMobile, getToken } from 'src/app/arquitetura/service/base.service';
import { Conta } from 'src/app/shared/model/perfil/conta.model';
import { Usuario } from 'src/app/shared/model/perfil/usuario.model';
import { UsuarioService } from 'src/app/shared/service/perfil/usuario.service';

@Component({
  selector: 'cmp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent {
  @Input() content!: TemplateRef<any>;
  usuario: Usuario;
  conta: Conta;

  constructor(
    private service: UsuarioService,
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaUsuario();
  }

  carregaUsuario() {
    const token = getToken();
    const accountId = getAccountId();
    this.service.get(token, accountId).then((resultado: any) => {
      this.usuario = resultado.entity as Usuario;
      this.conta = resultado.account as Conta;
    }).catch((ex) => {

    });
  }

  isMobile(): boolean {
    return getMobile();
  }
}
