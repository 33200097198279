import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Transacao } from "src/app/shared/model/financeiro/transacao.model";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { DatePipe } from "@angular/common";
import { TransacaoService } from "src/app/shared/service/financeiro/transacao.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: [
    './saldo.component.scss',
    `../../../../themes/xpory/css/component/financeiro/saldo.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/saldo.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/saldo.component.scss`,
  ]
})
export class SaldoComponent extends BaseComponent {
  theme = environment.theme_name;
  isReal: boolean = false;
  saldos: Transacao[] = [];
  inicio: Date;
  fim: Date;
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count: number = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private service: TransacaoService,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaSaldo();
  }

  carregaSaldo(){
    this.offset = 0;
    this.max = 12;
    this.isReal = false;
    var token = getToken();
    var accountId = getAccountId();
    if(this.inicio && this.fim){
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, this.offset, this.max, inicio, fim).then((resultado) =>{
        this.count = resultado.listFullSize;
        this.saldos = resultado.transactions;
      }).catch((ex) =>{
                
      });
    }else{
      this.isReal = false;
      var token = getToken();
      var accountId = getAccountId();
      this.service.listar(token, accountId, this.offset, this.max).then((resultado) =>{
        console.log(resultado);
        this.count = resultado.listFullSize;
        this.saldos = resultado.transactions;
      }).catch((ex) =>{
        
      });
    }
  }

  carregaSaldoReal(){
    this.offset = 0;
    this.max = 12;
    this.isReal = true;
    var token = getToken();
    var accountId = getAccountId();
    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarRealData(token, accountId, this.offset, this.max, inicio, fim).then((resultado) =>{
        this.count = resultado.listFullSize;
        this.saldos = resultado.transactions;
      }).catch((ex) =>{
        
      });
    }else{
      this.isReal = true;
      var token = getToken();
      var accountId = getAccountId();
      this.service.listarReal(token, accountId, this.offset, this.max).then((resultado) =>{
        console.log(resultado);
        this.count = resultado.listFullSize;
        this.saldos = resultado.transactions;
      }).catch((ex) =>{
        
      });
    }
  }

  async loadData(event){    
    if(this.isReal){
      this.loadDataReal(event);
    }
    else {
      this.loadDataX(event);
    }
  }

  loadDataX(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, this.offset, this.max, inicio, fim).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var saldos = resultado.transactions;
            this.saldos = this.saldos.concat(saldos);
          }
        }
      });
    }
    else {
      this.service.listar(token, accountId, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var saldos = resultado.transactions;
            this.saldos = this.saldos.concat(saldos);
          }
        }
      });
    }
  }

  loadDataReal(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarRealData(token, accountId, this.offset, this.max, inicio, fim).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var saldos = resultado.transactions;
            this.saldos = this.saldos.concat(saldos);
          }
        }
      });
    }
    else {
      this.service.listarReal(token, accountId, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var saldos = resultado.transactions;
            this.saldos = this.saldos.concat(saldos);
          }
        }
      });
    }
  }

  onSelectInicio(inicio: Date){
    this.inicio = inicio;
    if(this.isReal === true)
      this.carregaSaldoReal();
    else
      this.carregaSaldo();
  }

  onSelectFim(fim: Date){
    this.fim = fim;
    if(this.isReal === true)
      this.carregaSaldoReal();
    else
      this.carregaSaldo();
  }
}