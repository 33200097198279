import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { TransferenciaService } from "src/app/shared/service/transferencias/transferencia.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-solicitar-transferencia',
  templateUrl: './solicitar-transferencia.component.html',
  styleUrls: [
    './solicitar-transferencia.component.scss',
    `../../../../themes/xpory/css/component/transferencias/solicitar-transferencia.component.scss`,
    `../../../../themes/xpory-futebol/css/component/transferencias/solicitar-transferencia.component.scss`,
    `../../../../themes/fcj-barter/css/component/transferencias/solicitar-transferencia.component.scss`,
  ]
})
export class SolicitarTransferenciaComponent extends BaseComponent {
  theme = environment.theme_name;
  descricao!: string;
  valor!: number;
  
  constructor(
    private service: TransferenciaService,
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  solicitar(){
    this.service.solicitar(this.descricao, this.valor).then((resultado) =>{
      console.log(resultado);
      if(resultado.requestId){
        this.router.navigate(['/transferencias/solicitar-transferencia-detalhe', { requestId: resultado.requestId, description: this.descricao, value: this.valor }]);
      }
    })
  }
}