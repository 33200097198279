import { Component } from "@angular/core";

@Component({
  selector: 'pg-solicitar-emprestimo',
  templateUrl: './solicitar-emprestimo.page.html',
  styleUrls: ['./solicitar-emprestimo.page.scss']
})
export class SolicitarEmprestimoPage {

  constructor(){
  }
}