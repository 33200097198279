import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { RodadaDeNegociosService } from "src/app/shared/service/rodada-de-negocios/rodada-de-negocios.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-meu-video',
  templateUrl: './meu-video.component.html',
  styleUrls: [
    './meu-video.component.scss',
    `../../../../themes/xpory/css/component/rodada-negocios/meu-video.component.scss`,
    `../../../../themes/xpory-futebol/css/component/rodada-negocios/meu-video.component.scss`,
    `../../../../themes/fcj-barter/css/component/rodada-negocios/meu-video.component.scss`,
  ]
})
export class MeuVideoComponent extends BaseComponent {
  theme = environment.theme_name;
  url!: string;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private service: RodadaDeNegociosService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    
  }

  onEnviar(){
    const urlPattern = /^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;

    if (!urlPattern.test(this.url)) {
      this.message("Link inválido!");
      return;
    }

    this.service.avaliacao(this.url).then((resultado) => {
      if(resultado.videoPitch){
        if(resultado.videoPitch.videoPitchStatus){
          if(resultado.videoPitch.videoPitchStatus.name == 'PENDING')
            this.message('Seu vídeo está em análise!');
        }
      }
    }).catch((ex) => {
      this.message(ex.error.msg);
    });
  }
}