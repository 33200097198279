import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { CategoriaService } from 'src/app/shared/service/categoria/categoria.service';
import { Categoria } from 'src/app/shared/model/categoria/categoria.model';
import { Router } from '@angular/router';

@Component({
  selector: 'cmp-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent extends BaseComponent {

  categorias: Categoria[] = [];
  valor: number;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected service: CategoriaService) { 
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
      this.listar();
  }

  listar(): void {
    this.categorias = [];
    this.service.listar().then((resultado) =>{
      this.categorias = resultado.categories as Categoria[];
    });
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }

  setCategoria(id: any){
    console.log(id);
    this.categorias = [];
    this.service.listar().then((resultado) =>{
      this.categorias = resultado.categories as Categoria[];

      console.log(this.categorias);
      this.categorias.forEach((categoria) =>{
        if(categoria.id == id){
          this.valor = categoria.id;
        }
      })
    });
  }
}
