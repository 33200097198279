import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cmp-tipo-perfil',
  templateUrl: './tipo-perfil.component.html',
  styleUrls: ['./tipo-perfil.component.scss']
})
export class TipoPerfilComponent {

  tipos: any[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor() { 
  }

  ngOnInit(): void {
      this.listar();
  }

  listar(): void {
    this.tipos = [
        {name: 'Empresa', value: 'company', description: 'Empresa'},
        {name: 'Freelancer', value: 'freelancer', description: 'Profissional liberal'}
    ];
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }
}
