import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { LandingPage } from './landingpage.page';

@NgModule({
  declarations: [
    LandingPage
  ],
  exports: [
    LandingPage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class LandingPageModule { }
