import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { MinhasTransferenciasPage } from './minhas-transferencias.page';
import { MinhasTransferenciasComponent } from 'src/app/arquitetura/transferencias/minhas-transferencias/minhas-transferencias.component';

@NgModule({
  declarations: [
    MinhasTransferenciasPage,
    MinhasTransferenciasComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class MinhasTransferenciasModule { }
