import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { ParceirosComponent } from 'src/app/arquitetura/core/parceiros/parceiros.component';
import { ParceirosPage } from './parceiros.page';

@NgModule({
  declarations: [
    ParceirosPage,
    ParceirosComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class ParceirosModule { }
