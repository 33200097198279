import { AfterViewChecked, Component, ViewChild } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { Oferta } from "src/app/shared/model/ofertas/oferta.model";
import { getAccountId, getToken } from "../../service/base.service";
import { DatePipe } from "@angular/common";
import { FirebaseComponent } from "../../firebase.component";
import { Storage } from "@angular/fire/storage";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { TipoOfertaComponent } from "../../selects/tipo-oferta/tipo-oferta.component";
import { CategoriaComponent } from "../../selects/categoria/categoria.component";
import { CondicaoProdutoComponent } from "../../selects/condicao-produto/condicao-produto.component";
import { ImagemComponent } from "../../cards/imagem/imagem.component";
import { TipoFreteComponent } from "../../selects/tipo-frete/tipo-frete.component";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-painel-ofertas-cadastro',
  templateUrl: './painel-ofertas-cadastro.component.html',
  styleUrls: [
    './painel-ofertas-cadastro.component.scss',
    `../../../../themes/xpory/css/component/minhas-ofertas/painel-ofertas-cadastro.component.scss`,
    `../../../../themes/xpory-futebol/css/component/minhas-ofertas/painel-ofertas-cadastro.component.scss`,
    `../../../../themes/fcj-barter/css/component/minhas-ofertas/painel-ofertas-cadastro.component.scss`,
  ]
})
export class PainelOfertasCadastroComponent extends FirebaseComponent implements AfterViewChecked {
  theme = environment.theme_name;
  step: number = 1;
  orcamento: boolean;
  nacionalmente: boolean;
  limitar: boolean;
  oferta: Oferta = new Oferta;
  imagem1: Blob;
  imagem2: Blob;
  imagem3: Blob;
  imagem4: Blob;
  edit: boolean;
  checked: boolean = true;

  minData: any;

  @ViewChild('tipooferta') tipooferta: TipoOfertaComponent;
  @ViewChild('categoria') categoria: CategoriaComponent;
  @ViewChild('condicao') condicao: CondicaoProdutoComponent;
  @ViewChild('frete') frete: TipoFreteComponent;
  @ViewChild('imagem1') imagemComponent1: ImagemComponent;
  @ViewChild('imagem2') imagemComponent2: ImagemComponent;
  @ViewChild('imagem3') imagemComponent3: ImagemComponent;
  @ViewChild('imagem4') imagemComponent4: ImagemComponent;

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected storage: Storage,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private service: OfertasService) {
    super(router, toastController, loadingController, storage);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.id !== undefined) {
        this.obtemOferta(queryParams.id);
      } else {
        console.log('fsk Não foi encontrado o ID da oferta!');
      }
    });

    const data = new Date();
    this.minData = `${data.toISOString().slice(0, 10)}T00:00:00`;
  }

  ngAfterViewChecked(): void {
    if (!this.checked) {
      this.atualizarFormulario();
      this.checked = true;
    }
  }

  obtemOferta(id: any) {
    if (!id) {
      return;
    }

    this.service.obter(id).then((resultado) => {
      if (resultado && resultado.offer) {
        this.oferta = resultado.offer as Oferta;
        this.edit = true;

        this.atualizarFormulario();
      }
    })
  }

  atualizarFormulario() {
    setTimeout(() => {
      if (this.tipooferta)
        if (this.oferta.isProduct === true)
          this.tipooferta.setTipo('1');
        else
          this.tipooferta.setTipo('2');

      if (this.categoria) {
        if (this.oferta.category.id) {
          this.categoria.setCategoria(this.oferta.category.id);
        } else {
          this.categoria.setCategoria(this.oferta.categoryId);
        }
      }

      if (this.condicao) {
        if (this.oferta.isProductNew === true)
          this.condicao.setCondicao('1');
        else
          this.condicao.setCondicao('2');
      }

      if (this.oferta.isUnderBudget != undefined)
        this.orcamento = this.oferta.isUnderBudget;
      if (this.oferta.nationalOffer != undefined)
        this.nacionalmente = this.oferta.nationalOffer;

      if (this.frete)
        this.frete.setTipo(this.oferta.shippingOptions);

      if (this.oferta.images) {

        if (this.oferta.images.urlImage1 !== undefined) {
          this.oferta.urlImage1 = this.oferta.images.urlImage1;
          if (this.imagemComponent1) {
            this.imagemComponent1.url = this.oferta.urlImage1;
          }
        }

        if (this.oferta.images.urlImage2 !== undefined) {
          this.oferta.urlImage2 = this.oferta.images.urlImage2;
          if (this.imagemComponent2) {
            this.imagemComponent2.url = this.oferta.urlImage2;
          }
        }

        if (this.oferta.images.urlImage3 !== undefined) {
          this.oferta.urlImage3 = this.oferta.images.urlImage3;
          if (this.imagemComponent3) {
            this.imagemComponent3.url = this.oferta.urlImage3;
          }
        }

        if (this.oferta.images.urlImage4 !== undefined) {
          this.oferta.urlImage4 = this.oferta.images.urlImage4;
          if (this.imagemComponent4) {
            this.imagemComponent4.url = this.oferta.urlImage4;
          }
        }
      }
    });
  }

  onTipoOfertaSelected(id: any) {
    if (id == 1)
      this.oferta.isProduct = true;
    else
      this.oferta.isProduct = false;
  }

  onCategoriaSelected(id: any) {
    this.oferta.categoryId = id;
  }

  onAnterior() {
    if (this.step > 1)
      this.step--;
    this.checked = false;
  }

  onProximo() {
    if (this.step == 1) {
      if (!this.validarStep1())
        return;
    }

    if (this.step == 2) {
      if (!this.validarStep2())
        return;
    }

    if (this.step < 3)
      this.step++;

    this.checked = false;
  }

  validarStep1(): boolean {
    if (this.oferta.isProduct == undefined) {
      this.message("Tipo da oferta deve ser informado!");
      return false;
    }

    if (this.oferta.name == undefined) {
      this.message("Título da oferta deve ser informado!");
      return false;
    }

    if (this.oferta.categoryId == undefined) {
      this.message("Categoria da oferta deve ser informada!")
      return false;
    }

    return true;
  }

  validarStep2(): boolean {
    if (!this.orcamento && this.oferta.price == undefined) {
      this.message("Valor da oferta deve ser informado!");
      return false;
    }

    if (!this.orcamento && this.oferta.offersAvailable == undefined) {
      this.message("Quantidade dispoível deve ser informada!");
      return false;
    }

    if (this.oferta.description == undefined) {
      this.message("Descrição deve ser informada!");
      return false;
    }

    if (this.oferta.isProductNew == undefined) {
      this.message("Condição do produto deve ser informada!");
      return false;
    }

    return true;
  }

  onSelectVencimento(vencimento: Date) {
    this.oferta.endDate = vencimento;
  }

  onCondicaoProduto(condicao: number) {
    if (condicao == 1)
      this.oferta.isProductNew = true
    else
      this.oferta.isProductNew = false;
  }

  onTipoFrete(id: any) {
    this.oferta.shippingOptions = id;
  }

  onSelectImagem1(imagem: any) {
    this.imagem1 = imagem;
  }

  onSelectImagem2(imagem: any) {
    this.imagem2 = imagem;
  }

  onSelectImagem3(imagem: any) {
    this.imagem3 = imagem;
  }

  onSelectImagem4(imagem: any) {
    this.imagem4 = imagem;
  }

  async onSalvar() {
    if (!this.edit)
      if (!this.imagem1 && !this.imagem2 && !this.imagem3 && !this.imagem4) {
        this.message("Uma imagem é obrigatória!");
        return;
      }
      else {
        if (!this.imagem1 && !this.imagem2 && !this.imagem3 && !this.imagem4 &&
          !this.oferta.urlImage1 && !this.oferta.urlImage2 && !this.oferta.urlImage3 && !this.oferta.urlImage4) {
          this.message("Uma imagem é obrigatória!");
          return;
        }
      }

    this.openLoading('Salvando...');
    var accountId = getAccountId();
    var token = getToken();

    this.oferta.endDate = this.datePipe.transform(this.oferta.endDate, 'yyyy-MM-dd');
    this.oferta.activate = true;
    this.oferta.isPhysicalStoreOffer = false;
    this.oferta.nationalOffer = this.nacionalmente;
    this.oferta.specialityId = 126;
    this.oferta.isUnderBudget = this.orcamento;
    if (!this.edit) {
      this.oferta.urlImage1 = '';
      this.oferta.urlImage2 = '';
      this.oferta.urlImage3 = '';
      this.oferta.urlImage4 = '';
    }
    this.oferta.keywords = this.oferta.keywords.replace(' ', ',');

    if (this.imagem1 && this.oferta.urlImage1 === '') {
      this.oferta.urlImage1 = await this.uploadImageFile(this.oferta.name, this.imagem1);
    } else if (this.imagem1 && this.oferta.urlImage1) {
      this.oferta.urlImage1 = await this.updateExistingImage(this.oferta.urlImage1, this.imagem1);
    }

    if (this.imagem2 && this.oferta.urlImage2 === '') {
      this.oferta.urlImage2 = await this.uploadImageFile(this.oferta.name, this.imagem2);
    } else if (this.imagem2 && this.oferta.urlImage2) {
      this.oferta.urlImage2 = await this.updateExistingImage(this.oferta.urlImage2, this.imagem2);
    }

    if (this.imagem3 && this.oferta.urlImage3 === '') {
      this.oferta.urlImage3 = await this.uploadImageFile(this.oferta.name, this.imagem3);
    } else if (this.imagem3 && this.oferta.urlImage3) {
      this.oferta.urlImage3 = await this.updateExistingImage(this.oferta.urlImage3, this.imagem3);
    }

    if (this.imagem4 && this.oferta.urlImage4 === '') {
      this.oferta.urlImage4 = await this.uploadImageFile(this.oferta.name, this.imagem4);
    } else if (this.imagem4 && this.oferta.urlImage4) {
      this.oferta.urlImage4 = await this.updateExistingImage(this.oferta.urlImage4, this.imagem4);
    }

    if (this.edit)
      this.service.editar(this.oferta, accountId, token).then((resultado) => {
        if (resultado.ok == true) {
          this.message('Oferta alterada com sucesso!');
          this.router.navigate(['/minhas-ofertas/painel-ofertas']);
        } else {
          this.message(resultado.msg);
        }
      }).catch((ex) => {
        console.log(ex);
      }).finally(() => {
        this.closeLoading();
      });
    else {
      this.service.salvar(this.oferta, accountId).then((resultado) => {
        if (resultado.ok == true) {
          this.message('Oferta cadastrada com sucesso!');
          this.router.navigate(['/minhas-ofertas/painel-ofertas']);
        } else {
          this.message(resultado.msg);
        }
      }).catch((ex) => {
        console.log(ex);
      }).finally(() => {
        this.closeLoading();
      });
    }
  }

  uploadImageFile(name: any, image: any): Promise<string> {
    return new Promise((resolve, reject) => {
      this.uploadImage(`profiles/${this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS')}Z/${name.replace(' ', '-')}`, image, (url: string) => {
        resolve(url);
      });
    });
  }

  updateExistingImage(name: string, image: any): Promise<string> {
    return new Promise((resolve, reject) => {
      this.uploadImage(
        name,
        image,
        (url: string) => {
          resolve(`${name}?last_modified=${new Date().getTime()}`);
        });
    });
  }
}