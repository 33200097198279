import { Component, OnInit, ViewChild } from "@angular/core";
import { CabecalhoComponent } from "src/app/arquitetura/cabecalho/cabecalho.component";

@Component({
  selector: 'pg-ead-detalhe',
  templateUrl: './ead-detalhe.page.html',
  styleUrls: ['./ead-detalhe.page.scss']
})
export class EADDetalhePage implements OnInit {
  @ViewChild('cabecalho') cabecalho: CabecalhoComponent;

  constructor(){
  }

  ngOnInit(): void {
    if (this.cabecalho) {
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }

  ionViewWillEnter() {
    if (this.cabecalho) {
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }

  ionViewDidEnter() {
    if (this.cabecalho) {
      this.cabecalho.verificarLogin();
      this.cabecalho.atualizarLocalizacao();
    }
  }
}