import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExpirarService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, expired: number, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-x-transaction-maturities?token=${token}&accountId=${accountId}&offset=${offset}&max=${max}&expired=${expired}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, expired: number, inicio: string, fim: string, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-x-transaction-maturities?token=${token}&accountId=${accountId}&offset=${offset}&max=${max}&expired=${expired}&initialDate=${inicio}&endDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }
}