import { Component } from "@angular/core";

@Component({
  selector: 'pg-saldo',
  templateUrl: './saldo.page.html',
  styleUrls: ['./saldo.page.scss']
})
export class SaldoPage {

  constructor(){
  }
}