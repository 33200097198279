import { Component } from "@angular/core";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Module } from "src/app/shared/model/ead/module.model";
import { EADService } from "src/app/shared/service/ead/ead.service";
import { getAccountId, getToken } from "../../service/base.service";
import { Question } from "src/app/shared/model/ead/question.model";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-ead-detalhe',
  templateUrl: './detalhe.component.html',
  styleUrls: [
    './detalhe.component.scss',
    `../../../../themes/xpory/css/component/ead/detalhe.component.scss`,
    `../../../../themes/xpory-futebol/css/component/ead/detalhe.component.scss`,
    `../../../../themes/fcj-barter/css/component/ead/detalhe.component.scss`,
  ]
})
export class DetalheComponent extends BaseComponent {
  theme = environment.theme_name;

  modulo: Module = new Module;
  question: Question = new Question;
  currentQuestion: number = 0;
  urlVideo: string;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private service: EADService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
        this.carregar(queryParams['id']);
    });
  }

  carregar(moduleNumber: any){
    console.log(moduleNumber);
    var token = getToken();
    var accountId = getAccountId();
    this.service.get(token, accountId, moduleNumber).then((resultado) =>{
        console.log(resultado);
      if(resultado.ok == false){
        this.message(resultado.msg);
        this.navCtrl.navigateForward('/ead/ead');
      }else{
        this.modulo = resultado.eadModule as Module;
        this.carregarQuestoes(token, accountId, this.modulo.id, this.currentQuestion);
        this.urlVideo = this.modulo.videoUrl.replace('https://youtu.be', 'https://www.youtube.com/embed');
      }
    }).catch((ex) =>{
      console.log(ex);
      this.navCtrl.navigateForward('/ead/ead');
    });
  }

  carregarQuestoes(token: string, accountId: number, moduleId: number, question: number){
    console.log("questions");
    this.service.getQuestion(token, accountId, moduleId, question).then((resultado) => {
      console.log(resultado);
      this.question = resultado.question as Question;
    }).catch((ex) =>{
      console.log(ex);
    })
  }

  isAnterior(){
    return this.currentQuestion <= 0;
  }

  isSeguinte(){
    if(!this.question.answer)
      return true;

    return this.currentQuestion >= this.modulo.totalQuestions;
  }

  isModuloSeguinte(){
    return !this.modulo.haveNextModule;
  }

  perguntaAnterior(){
    if(!this.isAnterior()){
      this.currentQuestion--;
      var token = getToken();
      var accountId = getAccountId();
      this.carregarQuestoes(token, accountId, this.modulo.id, this.currentQuestion);
    }
  }

  perguntaSeguinte(){
    if(!this.isSeguinte()){
      this.currentQuestion++;
      var token = getToken();
      var accountId = getAccountId();
      this.carregarQuestoes(token, accountId, this.modulo.id, this.currentQuestion);
    }
  }

  moduloSeguinte(){
    if(this.modulo.haveNextModule){
      var number = this.modulo.moduleNumber + 1;
      this.navCtrl.navigateForward('/ead/ead-detalhe', { queryParams: { id: number } });
    }
  }

  onResposta(opcao: string){
    if(this.question.answer && this.question.answer.optionAnswered)
      return;

    var token = getToken();
    var accountId = getAccountId();
    this.service.answer(this.question.id, opcao, token, accountId).then((resultado) => {
      if(resultado.msg){
        this.message(resultado.msg);
      }

      this.carregar(this.modulo.moduleNumber);
    })
  }

  getCor(valor: string){
    console.log(valor);
    if(this.question.answer && this.question.answer.optionAnswered == valor){
      if(this.question.answer.correctAnswer == valor)
        return {'background-color': '#27AE60'};
      else
        return {'background-color': '#EB5757'};
    }

    return {'background-color': '#F4F4F4'};
  }
}