import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionSheetController, LoadingController, ToastController } from "@ionic/angular";
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";

@Component({
  selector: 'cmp-imagem',
  templateUrl: './imagem.component.html',
  styleUrls: ['./imagem.component.scss']
})
export class ImagemComponent extends BaseComponent {

  @Input() imagem: string;
  @Input() url: string;

  @Output() select: EventEmitter<any> = new EventEmitter;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private camera: Camera,
    public actionSheetController: ActionSheetController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    
  }

  base64ToBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: 'image/jpeg' }); // Defina o tipo de arquivo apropriado aqui
  }
  
  async mudarImagem() {
    const actionSheet = await this.actionSheetController.create({
      header: "Selecione uma imagem",
      buttons: [{
        text: 'Galeria',
        handler: () => {
          this.abrirCamera(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      }, {
        text: 'Camera',
        handler: () => {
          this.abrirCamera(this.camera.PictureSourceType.CAMERA);
        }
      }, {
        text: 'Cancelar',
        role: 'cancel'
      }]
    });
    await actionSheet.present();
  }

  abrirCamera(sourceType){
    const options: CameraOptions = {
        quality: 100,
        sourceType: sourceType,
        targetWidth: 800,
        targetHeight: 600,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
        console.log(imageData);
        this.imagem = 'data:image/jpeg;base64,' + imageData;
        this.select.emit(this.base64ToBlob(imageData));
    }, (err) => {
        this.message(err);
    });
  }

  onRemover(){
    this.imagem = null;
    this.url = null;
    this.select.emit(this.imagem);
  }
}