import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { ExpirarPage } from './expirar.page';
import { ExpirarComponent } from 'src/app/arquitetura/financeiro/expirar/expirar.component';

@NgModule({
  declarations: [
    ExpirarPage,
    ExpirarComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class ExpirarModule { }
