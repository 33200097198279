import { Component } from "@angular/core";

@Component({
  selector: 'pg-painel-indicacao',
  templateUrl: './painel-indicacao.page.html',
  styleUrls: ['./painel-indicacao.page.scss']
})
export class PainelIndicacaoPage {

  constructor(){
  }
}