import { Component } from "@angular/core";

@Component({
  selector: 'pg-editar-perfil',
  templateUrl: './editar-perfil.page.html',
  styleUrls: ['./editar-perfil.page.scss']
})
export class EditarPerfilPage {

  constructor(){
  }
}