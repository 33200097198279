import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { CadastrarPage } from './cadastrar.page';
import { CadastrarComponent } from 'src/app/arquitetura/onboarding/cadastrar/cadastrar.component';

@NgModule({
  declarations: [
    CadastrarPage,
    CadastrarComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class CadastrarModule { }
