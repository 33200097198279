import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { SaldoPage } from './saldo.page';
import { SaldoComponent } from 'src/app/arquitetura/financeiro/saldo/saldo.component';

@NgModule({
  declarations: [
    SaldoPage,
    SaldoComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class SaldoModule { }
