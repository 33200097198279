import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from 'src/environments/environment';
import { LoginCadastroModel } from '../../model/login/login-cadastro.model';
import { BaseService } from 'src/app/arquitetura/service/base.service';

@Injectable()
export class LoginService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  login(email: string, senha: string) {
    return this.http.get(`${environment.api}v1/login?email=${email}&password=${senha}&platform=web&appVersion=1`, null, this.configSimple()).then(data => JSON.parse(data.data));
  }

  cadastrar(model: LoginCadastroModel) {
    return this.http.sendRequest(
      `${environment.api}v1/customers`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        data: model,
        serializer: 'json',
    })
      .then(data => JSON.parse(data.data))
  }

  recuperar(email: string) {
    return this.http.post(`${environment.api}v1/forgot-password?email=${email}`, {}, this.configSimple()).then(data => JSON.parse(data.data));
  }

  confirmar(celular: string, codigo: string){
    return this.http.get(`${environment.api}v1/cellphones/${celular}/${codigo}`, null, this.configSimple()).then(data => JSON.parse(data.data));
  }

  reenvio(id: number, celular: string){
    return this.http.get(`${environment.api}v1/customers/${id}/contacts/cellphones/${celular}/validation`, null, this.configSimple()).then(data => JSON.parse(data.data));
  }

  mudarSenha(token: string, accountId: number, password: string, newPassword: string, confirmNewPassword: string){
    return this.http.post(`${environment.api}v1/change-password?password=${password}&newPassword=${newPassword}&confirmNewPassword=${confirmNewPassword}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  remover(reason: string){
    return this.http.post(`${environment.api}v1/customers/accounts/excludes?reason=${reason}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}