import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { BaseComponent } from "../../base.component";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { LoginService } from "src/app/shared/service/login/login.service";
import { LoginCadastroModel } from "src/app/shared/model/login/login-cadastro.model";
import { CidadeComponent } from "../../selects/cidade/cidade.component";
import { Router } from "@angular/router";
import { DDIComponent } from "../../selects/ddi/ddi.component";
import { HttpClient } from "@angular/common/http";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { EstadoComponent } from "../../selects/estado/estado.component";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: [
    './cadastrar.component.scss',
    `../../../../themes/xpory/css/component/onboarding/cadastrar.component.scss`,
    `../../../../themes/xpory-futebol/css/component/onboarding/cadastrar.component.scss`,
    `../../../../themes/fcj-barter/css/component/onboarding/cadastrar.component.scss`,
  ]
})
export class CadastrarComponent extends BaseComponent implements OnInit, AfterViewInit {
  theme = environment.theme_name;
  model: LoginCadastroModel = new LoginCadastroModel;
  confirmacao!: string;

  @ViewChild('estado') estado: EstadoComponent;
  @ViewChild('cidade') cidade: CidadeComponent;
  @ViewChild('ddi') ddi: DDIComponent;

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected navCtrl: NavController,
    protected service: LoginService,
    private http: HttpClient,
    private geolocation: Geolocation) {
    super(router, toastController, loadingController);
  }

  ngAfterViewInit(): void {
    if (this.model.countryCodeCellNumber) {
      this.ddi.setDDI(this.model.countryCodeCellNumber);
      this.onSelectDDI(this.model.countryCodeCellNumber);
    } else {
      this.onSelectDDI(3870123);
      this.ddi.setDDI(3870123);
    }
  }

  ngOnInit(): void {
    this.obterLocalizacao();

    if (this.model.countryCodeCellNumber) {
      this.ddi.setDDI(this.model.countryCodeCellNumber);
    } else {
      this.ddi.setDDI(3870123);
    }
  }

  onSelectDDI(id: number) {
    this.model.countryCodeCellNumber = id;
  }

  onSelectEstado(id: any) {
    this.model.state = id;
    this.cidade.listar(id);
  }

  onSelectCidade($event) {
    this.model.city = $event;
  }

  cadastrar() {
    if (this.model.password != this.confirmacao) {
      this.message('Senha deve ser igual à repetir senha!');
      return;
    }

    this.openLoading('Carregando...');
    console.log(this.model);
    this.service.cadastrar(this.model).then((resultado) => {
      console.log(resultado);
      if (resultado.id)
        this.navCtrl.navigateForward('/onboarding/confirmar-cadastro', { queryParams: { id: resultado.id, celular: this.model.cellNumber } });
    }).catch((ex) => {
      console.log(ex);
      if (JSON.parse(ex.error))
        this.message(JSON.parse(ex.error).msg);
    }).finally(() => {
      this.closeLoading();
    });
  }

  login() {
    this.navCtrl.navigateForward('/onboarding/login');
  }

  onTermo() {
    this.router.navigate([]).then(result => { window.open(`https://firebasestorage.googleapis.com/v0/b/xpory-images-617e8.appspot.com/o/public%2FRegulamento_XporY.com.pdf?alt=media&token=1e8a490a-b72e-4223-9d6a-728e66cbcd72`, '_blank'); });
  }

  onPolitica() {
    this.router.navigate([]).then(result => { window.open(`https://firebasestorage.googleapis.com/v0/b/xpory-images-617e8.appspot.com/o/public%2FPolitica_de_Privacidade_Plataforma_XporY.pdf?alt=media&token=01268c5a-80b3-4035-8df5-bb1367b34844`, '_blank'); });
  }

  obterLocalizacao() {
    this.geolocation.getCurrentPosition().then((resp) => {
      const lat = resp.coords.latitude;
      const long = resp.coords.longitude;
      this.obterNomeCidadeEstado(lat, long);
    });
  }

  obterNomeCidadeEstado(lat: number, long: number) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${environment.googleAPI}`;

    this.http.get(url).subscribe((data: any) => {
      let estado = '';
      let cidade = '';

      if (data.results.length > 0) {
        data.results.forEach(result => {
          result.address_components.forEach(component => {
            if (component.types.includes('administrative_area_level_1')) {
              return estado = component.short_name;
            }
            if (component.types.includes('administrative_area_level_2')) {
              return cidade = component.long_name;
            }
          });
        });

        if (estado !== '') {
          this.estado.setEstado(estado);
        }
        if (cidade !== '') {
          this.cidade.setCidade(estado, cidade);
        }
      }
    });
  }
}
