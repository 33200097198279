import { Component } from "@angular/core";

@Component({
  selector: 'pg-regulamentos',
  templateUrl: './regulamentos.page.html',
  styleUrls: ['./regulamentos.page.scss']
})
export class RegulamentosPage {

  constructor(){
  }
}