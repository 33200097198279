import { Component } from "@angular/core";

@Component({
  selector: 'pg-planos',
  templateUrl: './planos.page.html',
  styleUrls: ['./planos.page.scss']
})
export class PlanosPage {

  constructor(){
  }
}