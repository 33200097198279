import { Component, Input } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { Estorno } from "src/app/shared/model/financeiro/estorno.model";
import { EstornoService } from "src/app/shared/service/financeiro/estorno.service";
import { getAccountId } from "../../service/base.service";

@Component({
  selector: 'cmp-estorno',
  templateUrl: './estorno.component.html',
  styleUrls: [
    './estorno.component.scss',
    `../../../../themes/xpory/css/component/cards/estorno.component.scss`,
    `../../../../themes/xpory-futebol/css/component/cards/estorno.component.scss`,
    `../../../../themes/fcj-barter/css/component/cards/estorno.component.scss`,
  ]
})
export class EstornoComponent extends BaseComponent {

  showResposta: boolean;
  aprovado: boolean;
  reprovado: boolean;
  resposta: string;

  @Input() estorno: Estorno;
  
  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private service: EstornoService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    
  }

  onAprovar(){
    this.showResposta = true;
    this.aprovado = true;
    this.reprovado = false;
  }

  onReprovar(){
    this.showResposta = true;
    this.aprovado = false;
    this.reprovado = true;
  }

  onResponder(){
    var accountId = getAccountId();
    this.service.responder(accountId, this.estorno.id, this.aprovado, this.resposta).then((resultado) =>{
        this.message(resultado.msg);
        if(resultado.ok === true){
            if(this.aprovado)
                this.estorno.statusCode = 3;
            if(this.reprovado)
                this.estorno.statusCode = 9;

            this.showResposta = false;
        }
    })
  }
}