import { Categoria } from "../categoria/categoria.model";
import { Imagens } from "../geral/images.model";
import { QualificationModel } from "../geral/qualification.model";
import { Usuario } from "../perfil/usuario.model";

export class Oferta{
    id: number;
    name: string;
    price: number;
    offersAvailable: number;
    endDate: any;
    limitByClient: number;
    linkForVideo: string;
    activate: boolean;
    description: string
    isUnderBudget: boolean;
    isProductNew: boolean;
    isProduct: boolean;
    isBum: boolean;
    qualify: number;
    qualification: QualificationModel;
    comments: any;
    category: Categoria;
    categoryId: number;
    shippingOptions: number;
    speciality: string;
    specialityId: number;
    city: string;
    state: string
    lastUpdated: string;
    isPhysicalStoreOffer: boolean;
    images: Imagens;
    provider: Usuario;
    nationalOffer: boolean;
    following: boolean;
    keywords: string;
    urlImage1: string;
    urlImage2: string;
    urlImage3: string;
    urlImage4: string;

    toSalvar() : string{
        return `%7B"name":"${this.name}",%22price%22:%22${this.price}%22,%22offersAvailable%22:${this.offersAvailable},%22endDate%22:%2205%2F16%2F2024%22,%22limitByClient%22:${this.limitByClient},%22activate%22:${this.activate},%22description%22:%22${this.description.replace(' ', '+')}%22,%22keywords%22:%22${this.keywords}%22,%22isUnderBudget%22:${this.isUnderBudget},%22isProductNew%22:${this.isProductNew},%22isProduct%22:${this.isProduct},%22isPhysicalStoreOffer%22:%22${this.isPhysicalStoreOffer}%22,%22city%22:%22Goi%C3%A2nia%22,%22state%22:%22Goi%C3%A1s%22,%22urlImage1%22:%22${this.urlImage1}%22,%22urlImage2%22:%22${this.urlImage2}%22,%22urlImage3%22:%22${this.urlImage3}%22,%22urlImage4%22:%22${this.urlImage4}%22,%22categoryId%22:%22${this.categoryId}%22,%22specialityId%22:${this.specialityId},%22nationalOffer%22:${this.nationalOffer}%7D`;
    }
}