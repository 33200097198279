import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TipsService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar() {
    return this.http.get(`${environment.api}v1/get-tips?randomOrder=true`, null, this.config()).then(data => JSON.parse(data.data));
  }
}