import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PlanoDetalhePage } from './plano-detalhe.page';

@NgModule({
  declarations: [
    PlanoDetalhePage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PlanoDetalheModule { }
