import { Component } from "@angular/core";

@Component({
  selector: 'pg-ead',
  templateUrl: './ead.page.html',
  styleUrls: ['./ead.page.scss']
})
export class EADPage {

  constructor(){
  }
}