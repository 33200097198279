import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { RodadaDeNegociosPage } from './rodada-de-negocios.page';

@NgModule({
  declarations: [
    RodadaDeNegociosPage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class RodadaDeNegociosModule { }
