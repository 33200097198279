import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { MeusEmprestimosPage } from './meus-emprestimos.page';
import { MeusEmprestimosComponent } from 'src/app/arquitetura/financeiro/meus-emprestimos/meus-emprestimos.component';

@NgModule({
  declarations: [
    MeusEmprestimosPage,
    MeusEmprestimosComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class MeusEmprestimosModule { }
