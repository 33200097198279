import { Component, Output, EventEmitter, Input } from '@angular/core';
import { EnderecoService } from 'src/app/shared/service/endereco/endereco.service';
import { BaseComponent } from '../../base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { EstadoModel } from 'src/app/shared/model/endereco/estado.model';
import { Router } from '@angular/router';

@Component({
  selector: 'cmp-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.scss']
})
export class EstadoComponent extends BaseComponent {

  estados: EstadoModel[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected service: EnderecoService) { 
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.service.estados().then((resultado) =>{
      this.estados = resultado.states as EstadoModel[];
    });
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }

  setEstado(uf: string){
    console.log(uf);
    
    this.service.estados().then((resultado) =>{
      this.estados = resultado.states as EstadoModel[];

      this.estados.forEach((estado) =>{
        if(estado.code === uf){
          this.valor = estado.id;
          this.selected.emit(estado.id);
        }
      })
    });
  }

  setEstadoTexto(nome: string){
    console.log(nome);
    
    this.service.estados().then((resultado) =>{
      this.estados = resultado.states as EstadoModel[];

      this.estados.forEach((estado) =>{
        if(estado.name === nome){
          this.valor = estado.id;
          this.selected.emit(estado.id);
        }
      })
    });
  }
}
