import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class BlogService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar() {
    return this.http.get(`${environment.api_blog}v2/posts?per_page=12`, null, {}).then(data => JSON.parse(data.data));
  }

  obter(id: number){
    return this.http.get(`${environment.api_blog}v2/media?parent=${id}`, null, {}).then(data => JSON.parse(data.data));
  }
}