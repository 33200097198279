import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { FinalizarCompraPage } from './finalizar-compra.page';
import { FinalizarCompraComponent } from 'src/app/arquitetura/core/finalizar-compra/finalizar-compra.component';

@NgModule({
  declarations: [
    FinalizarCompraPage,
    FinalizarCompraComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class FinalizarCompraModule { }
