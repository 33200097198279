import { Component } from "@angular/core";

@Component({
  selector: 'pg-recuperar',
  templateUrl: './recuperar.page.html',
  styleUrls: ['./recuperar.page.scss']
})
export class RecuperarPage {

  constructor(){
  }
}