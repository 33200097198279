import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { ExpirarService } from "src/app/shared/service/financeiro/expirar.service";
import { Expirar } from "src/app/shared/model/financeiro/expirar.model";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-expirar',
  templateUrl: './expirar.component.html',
  styleUrls: [
    './expirar.component.scss',
    `../../../../themes/xpory/css/component/financeiro/expirar.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/expirar.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/expirar.component.scss`,
  ]
})
export class ExpirarComponent extends BaseComponent {
  theme = environment.theme_name;
  expired: number = 0;
  expirar: Expirar[] = [];
  inicio: Date;
  fim: Date;
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count: number = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private service: ExpirarService,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaSaldo(this.expired);
  }

  carregaSaldo(expired: number){
    this.offset = 0;
    this.max = 12;
    this.expirar = [];
    this.expired = expired;
    var token = getToken();
    var accountId = getAccountId();
    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, this.expired, inicio, fim, this.offset, this.max).then((resultado) =>{
        this.count = resultado.listFullSize;
        this.expirar = resultado.xTransactionMaturitiesList as Expirar[];
      }).catch((ex) =>{
        
      });
    }else{
      this.service.listar(token, accountId, this.expired, this.offset, this.max).then((resultado) =>{
        this.count = resultado.listFullSize;
        this.expirar = resultado.xTransactionMaturitiesList as Expirar[];
      }).catch((ex) =>{
        
      });
    }
  }

  loadData(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, this.expired, inicio, fim, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var expirar = resultado.xTransactionMaturitiesList;
            this.expirar = this.expirar.concat(expirar);
          }
        }
      });
    }
    else {
      this.service.listar(token, accountId, this.expired, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var expirar = resultado.xTransactionMaturitiesList;
            this.expirar = this.expirar.concat(expirar);
          }
        }
      });
    }
  }
  
  onSelectInicio(inicio: Date){
    this.inicio = inicio;
    this.carregaSaldo(this.expired);
  }

  onSelectFim(fim: Date){
    this.fim = fim;
    this.carregaSaldo(this.expired);
  }
}