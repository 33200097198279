import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/shared/service/perfil/usuario.service';
import { Usuario } from 'src/app/shared/model/perfil/usuario.model';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { clearStorage, getMobile, getUsuarioLogado } from 'src/app/arquitetura/service/base.service';

@Component({
  selector: 'cmp-menu-perfil',
  templateUrl: './menu-perfil.component.html',
  styleUrls: [
    './menu-perfil.component.css',
    `${environment.component_css}/component/menu-perfil.component.css`,
  ]
})
export class MenuPerfilComponent {

  isLead: boolean;
  usuario: Usuario;
  exibeMenu: boolean;
  @Input() itemExpand!: string;
  @Input() itemSelected!: string;

  showConfirmTelefone: boolean = false;
  codigoConfirmacao: string;


  label = environment.label;
  theme = environment.theme_name;

  logado: boolean;
  financeiro: boolean;
  transferencia: boolean;
  vendas: boolean;
  ofertas: boolean;
  indicacoes: boolean;
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private navCtrl: NavController,
    private servicoUsuario: UsuarioService) {
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.verificarLogin();
    });
  }

  carregaUsuario() {
  }

  editPerfil(){
    this.router.navigate(['/perfil-editar-perfil']);
  }

  confirmarTelefone(){
    this.showConfirmTelefone = true;
  }

  fecharConfirmacao(){
    this.showConfirmTelefone = false;
  }

  confirmar(){
  }

  reenviarCodigo(){
  }

  isMobile(){
    return getMobile();
  }

  verificarLogin() {
    var usuario = JSON.parse(getUsuarioLogado());
    if (usuario)
      this.logado = true;
  }

  fecharMenu() {
    this.menu.close('menuPrincipal');
  }

  abrirEAD() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/ead/ead']);
  }

  abrirRodadaNegocio() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/rodada-de-negocios/meu-video-pitch']);
  }

  abrirFinanceiro() {
    this.financeiro = !this.financeiro;
    this.transferencia = false;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirSaldoExtrato() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/saldo']);
  }

  abrirEstornos() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/estornos']);
  }

  abrirSaldoExpirar() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/expirar']);
  }

  abrirMeusEmprestimos() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/meus-emprestimos']);
  }

  abrirMeusEmprestimosEmRS() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/meus-emprestimos-em-rs']);
  }

  abrirSolicitarEmprestimo() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/solicitar-emprestimo']);
  }

  abrirSolicitarAntecipacao() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/solicitar-antecipacao']);
  }

  abrirTransferencia() {
    this.financeiro = false;
    this.transferencia = !this.transferencia;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirMinhasTransferencias() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/minhas-transferencias']);
  }

  abrirFazerTransferencia() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/fazer-transferencia']);
  }

  abrirSolicitarTransferencia() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/solicitar-transferencia']);
  }

  abrirVendas() {
    this.financeiro = false;
    this.transferencia = false;
    this.vendas = !this.vendas;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirPainelVendas() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/vendas/painel-vendas']);
  }

  abrirValidarVoucher() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/vendas/validar-voucher']);
  }

  abrirCompras() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/compras/painel-compras']);
  }

  abrirParceiros() {
    if (this.logado) {
      this.financeiro = false;
      this.transferencia = false;
      this.vendas = false;
      this.ofertas = !this.ofertas;
      this.indicacoes = false;
    } else {
      this.menu.close('menuPrincipal');
      this.navCtrl.navigateForward(['/core/parceiros']);
    }
  }

  abrirOfertas() {
    if (this.logado) {
      this.financeiro = false;
      this.transferencia = false;
      this.vendas = false;
      this.ofertas = !this.ofertas;
      this.indicacoes = false;
    } else {
      this.menu.close('menuPrincipal');
      this.navCtrl.navigateForward(['/core/ofertas']);
    }
  }

  abrirMinhasOfertas() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/minhas-ofertas/painel-ofertas']);
  }

  abrirCadastroOferta() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/minhas-ofertas/painel-ofertas-cadastro']);
  }

  abrirIndicacoes() {
    this.financeiro = false;
    this.transferencia = false;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = !this.indicacoes;
  }

  abrirMinhasIndicacoes() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/indicacoes/painel-indicacao']);
  }

  abrirNovaIndicacao() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/indicacoes/painel-minhas-indicacoes']);
  }

  onSair() {
    clearStorage();
    this.navCtrl.navigateForward(['/core/home']).then(() => {
      window.location.reload();
    });
  }

  abrirPainelEmpreendedor() {
    this.router.navigate([]).then(result => { window.open(`https://empreendedor.xpory.com/`, '_blank'); });
  }

  onFinalizarConta() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/excluir-conta']);
  }
}
