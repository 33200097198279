import { Component, ViewChild } from "@angular/core";
import { CabecalhoComponent } from "src/app/arquitetura/cabecalho/cabecalho.component";

@Component({
  selector: 'pg-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  @ViewChild('cabecalho') cabecalho: CabecalhoComponent;

  constructor(){
  }

  onEntrar(){
    console.log('onEntrar()');
    this.cabecalho.verificarLogin();
  }
}