import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EADService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number){
    return this.http.post(`${environment.api}v1/list-ead-modules?token=${token}&accountId=${accountId}`, {}, this.config()).then(data => JSON.parse(data.data));
  }

  get(token: string, accountId: number, moduleNumber: number){
    return this.http.get(`${environment.api}v1/get-ead-module?token=${token}&accountId=${accountId}&moduleNumber=${moduleNumber}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  getQuestion(token: string, accountId: number, moduleId: number, question: number){
    return axios.get(
      `${environment.api}v1/get-ead-module-question?token=${token}&accountId=${accountId}&moduleId=${moduleId}&currentQuestion=${question}`,
      {
        headers:  this.configToken()
      }
    ).then(response => response.data);
  }

  answer(quizId: number, answer: string, token: string, accountId: string){
    return this.http.post(`${environment.api}v1/answer-question?quizId=${quizId}&countOnlyCorrectAnswers=false&answer=${answer}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}