import { Component } from "@angular/core";

@Component({
  selector: 'pg-painel-vendas',
  templateUrl: './painel-vendas.page.html',
  styleUrls: ['./painel-vendas.page.scss']
})
export class PanielVendasPage {

  constructor(){
  }
}