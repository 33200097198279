import { Component } from "@angular/core";

@Component({
  selector: 'pg-fazer-transferencia',
  templateUrl: './fazer-transferencia.page.html',
  styleUrls: ['./fazer-transferencia.page.scss']
})
export class FazerTransferenciaPage {

  constructor(){
  }
}