import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { Endereco, Usuario } from '../../model/perfil/usuario.model';
import { DatePipe } from '@angular/common';
import axios from 'axios';

@Injectable()
export class UsuarioService extends BaseService {

  constructor(http: HTTP,
    private datePipe: DatePipe) {
    super('', http);
  }

  simplificado(id: number){
    return this.http.get(`${environment.api}v1/search-entity-id?entityId=${id}&simpleInformation=true`, null, this.configToken()).then(data => JSON.parse(data.data));
  }

  get(token: string, accountId: number) {
    return axios.post(`${environment.api}v1/auth?token=${token}&accountId=${accountId}&platform=web&appVersion=1`, {}, {
      headers: {
        ...this.config()
      }
    }).then(response => response.data);
  }

  salvarUsuario(usuario: Usuario) {
    const response = axios.patch(`${environment.api}v1/customers/${usuario.id}/`,
      {
        cnpj: usuario.cnpj,
        cpf: usuario.cpf,
        email: usuario.email,
        name: usuario.name,
        nickName: usuario.nickName,
        responsible: usuario.responsible,
        from: usuario.register,
        to: (usuario.cnpj !== '' ? 'company' : 'freelancer'),
        birthdate: this.datePipe.transform(usuario.birthdate, 'dd/MM/yyyy')
      },
      {
        headers: {
          ...this.configToken(),
        },
      }).then(data => data.data);

    return response;
  }

  salvarContato(usuario: Usuario) {
    const response = axios.patch(`${environment.api}v1/customers/${usuario.id}/contacts/`,
      {
        cellNumber: usuario.cellNumber,
        countryCodeCellNumber: usuario.countryCode.id
      },
      {
        headers: {
          ...this.configToken(),
        },
      }).then(data => data.data);

    return response;
  }

  salvarEndereco(endereco: Endereco, id: number) {
    const response = axios.patch(`${environment.api}v1/customers/${id}/addresses/`,
      {
        cep: endereco.cep,
        city: endereco.cityId,
        complement: endereco.complement,
        district: endereco.district,
        state: endereco.stateId,
        street: endereco.street
      },
      {
        headers: {
          ...this.configToken(),
        },
      }).then(data => data.data);

    return response;
  }

  salvarInformacoes(usuario: Usuario){
    const interests: any[] = [];

    usuario.categoriesInterest.forEach((categoria) => {
      interests.push(categoria.id);
    });

    const response = axios.patch(`${environment.api}v1/customers/${usuario.id}/additional-information/`,
    {
      interests,
      category: usuario.category.id,
      description: usuario.description
    },
    {
      headers: {
        ...this.configToken(),
      },
    }).then(data => JSON.parse(data.data));

    return response;
  }

  atualizarImagem(id: number, url: string){
    return this.http.patch(`${environment.api}v1/customers/${id}/pictures`, {
      urlPhoto: url
    },
    this.configToken()).then(data => JSON.parse(data.data));
  }
}
