import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { Cartao } from '../../model/financeiro/cartao.model';

@Injectable()
export class CartaoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  adicionar(token: string, accountId: number, cartao: Cartao){
    return this.http.post(`${environment.api}v1/create-credit-card?holder=${cartao.holder}&cardNumber=${cartao.cardNumber}&expirationDate=${encodeURIComponent(cartao.expirationDate)}&brand=${cartao.brand}&saveCard=${cartao.saveCard ? cartao.saveCard : false}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  listar(token: string, accountId: number){
    return this.http.get(`${environment.api}v1/get-c?token=${token}&accountId=${accountId}`, null, this.configToken()).then(data => JSON.parse(data.data));
  }

  remover(id: number, token: string, accountId: number){
    return this.http.post(`${environment.api}v1/delete-credit-card?id=${id}&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}