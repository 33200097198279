import { Component } from "@angular/core";

@Component({
  selector: 'pg-central-de-ajuda',
  templateUrl: './central-de-ajuda.page.html',
  styleUrls: ['./central-de-ajuda.page.scss']
})
export class CentralDeAjudaPage {

  constructor(){
  }
}