import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { OfertaResumida } from "src/app/shared/model/ofertas/oferta-resumida.model";
import { Ordenacao } from "src/app/shared/model/geral/order.model";
import { getCityName, getState, getStateName } from "../../service/base.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: [
    './ofertas.component.scss',
    `../../../../themes/xpory/css/component/core/ofertas.component.scss`,
    `../../../../themes/xpory-futebol/css/component/core/ofertas.component.scss`,
    `../../../../themes/fcj-barter/css/component/core/ofertas.component.scss`,
  ]
})
export class OfertasComponent extends BaseComponent {
  theme = environment.theme_name;
  location = {
    city: getCityName(),
    state: getStateName()
  };

  limit: number = 12;
  page: number = 0;
  ofertas: OfertaResumida[] = [];
  ordenacao: Ordenacao;
  showFiltro: boolean;
  estadoProduto: number;
  categorias: number[] = [];
  minPrice: string;
  maxPrice: string;

  count: number;
  offset: number;
  max: number;
  range: any;

  texto: string = '';

  isOrcamento: boolean;
  isProduto: boolean;
  isServico: boolean;
  isLoja: boolean;

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected service: OfertasService,
    private route: ActivatedRoute) {
    super(router, toastController, loadingController);

    this.route.queryParams.subscribe(queryParams => {
      this.texto = queryParams['texto'];
    });
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.criaOrdenacao();
    this.listar(this.texto);
  }

  criaOrdenacao() {
    this.ordenacao = new Ordenacao();
    this.ordenacao.name = 'lastUpdated';
    this.ordenacao.value = 'desc';
  }

  listar(texto: string) {
    const state = getState();
    this.texto = texto;

    this.openLoading('Carregando...');
    this.service.listar(texto, this.offset, this.limit, state, this.ordenacao).then((resultado) => {
      if (resultado) {
        if (resultado.msg) {
          this.message(resultado.msg);
        } else {
          this.count = resultado.listFullSize;
          this.ofertas = resultado.offers as OfertaResumida[];
        }
      }
    }).catch((error) => {
      this.message(JSON.parse(error.error).msg);
    }).finally(() => {
      this.closeLoading();
    });
  }

  async loadData(event) {
    this.offset += this.max;

    var state = getState();
    this.texto = this.texto;

    this.service.listar(
      this.texto,
      this.offset,
      this.limit,
      state,
      this.ordenacao,
      this.estadoProduto,
      this.categorias,
      this.minPrice,
      this.maxPrice
    ).then((resultado) => {
      if (event.target) {
        event.target.complete();
      }

      if (resultado) {
        if (!resultado.msg) {
          var ofertas = resultado.offers as OfertaResumida[];
          this.ofertas = this.ofertas.concat(ofertas);
        }
      }
    }).catch((error) => {
      this.message(JSON.parse(error.error).msg);
    });
  }

  onFechar() {
    this.showFiltro = false;
  }

  onFiltrar() {
    this.listar(this.texto);
    this.showFiltro = false;
  }

  onSelectCategorias(categorias: any) {
    this.categorias = categorias;
    this.listar(this.texto);
  }


  onSelectOrdenarPor($event: any) {
    if ($event) {
      this.ordenacao.name = $event.name;
      this.ordenacao.value = $event.value;
    }

  }

  aplicarFiltro() {
    this.showFiltro = false;
    this.listar(this.texto);
  }
}
