import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EnderecoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  estados() {
    return axios.get(`${environment.api}v1/list-state`, {
      headers: this.configSimple(),
    }).then(data => data.data);
  }

  cidades(estadoId: number){
    return axios.get(`${environment.api}v1/list-city?stateId=${estadoId}`, {
      headers: this.configSimple(),
    }).then(data => data.data);
  }

  ddis(){
    return axios.get(`${environment.api}v2/get-idd-country-codes`, {
      headers: this.configSimple(),
    }).then(data => data.data);
  }
}