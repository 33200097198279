import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { RegulamentosPage } from './regulamentos.page';

@NgModule({
  declarations: [
    RegulamentosPage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class RegulamentosModule { }
