import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { MeusEmprestimosEmRSPage } from './meus-emprestimos-em-rs.page';
import { MeusEmprestimosEmRSComponent } from 'src/app/arquitetura/financeiro/meus-emprestimos-em-rs/meus-emprestimos-em-rs.component';

@NgModule({
  declarations: [
    MeusEmprestimosEmRSPage,
    MeusEmprestimosEmRSComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class MeusEmprestimosEmRSModule { }
