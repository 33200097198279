import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cmp-ordenar-por',
  templateUrl: './ordenar-por.component.html',
  styleUrls: ['./ordenar-por.component.scss']
})
export class OrdenarPorComponent {

  tipos: any[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor() { 
  }

  ngOnInit(): void {
      this.listar();
  }

  listar(): void {
    this.tipos = [
      {name: 'lastUpdated', value: 'desc', description: 'Atualizadas recentemente'},
      {name: 'relevancy', value: 'desc', description: 'Mais relevante'},
      {name: 'price', value: 'desc', description: 'Maior preço'},
      {name: 'price', value: 'asc', description: 'Menor preço'},
      {name: 'bestSellers', value: 'desc', description: 'Mais vendidas'},
      {name: 'stars', value: 'desc', description: 'Melhor avaliadas'}
    ];
  }

  onSelected($event: any){
    const payload = this.tipos.find(tipo => tipo.name === $event.detail.value);
    console.log(`🥷🏼 fsk >> OrdenarPorComponent >> payload:`, payload);
    this.selected.emit(payload);
  }
}
