import { Directive, OnInit } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getMobile } from './service/base.service';

@Directive()
export class BaseComponent implements OnInit{
    label = environment.label;
    loading: any = null;
    fullScreenLoading: any = null;

    protected constructor(
        public router: Router,
        public toastController: ToastController,
        public loadingController: LoadingController) {
    }

    ngOnInit(): void {

    }

    public async message(text: string) {
        const toast = await this.toastController.create({
          message: text,
          duration: 10000
        });
        toast.present();
    }

    public async openLoading(mensagem: string) {
        if(this.loading == null){
            this.loading = this.loadingController.create({
                message: mensagem,
                spinner: 'circles',
                cssClass: 'custom-loading'
            });
        }

        (await this.loading).present();
    }

    public async openFullLoading(mensagem: string) {
        if(this.fullScreenLoading == null){
            this.fullScreenLoading = this.loadingController.create({
                message: mensagem,
                spinner: 'circles',
                cssClass: 'custom-full-screen-loading',
            });
            (await this.fullScreenLoading).present();
        }

    }

    public async closeFullLoading() {
        if(this.fullScreenLoading != null){
            (await this.fullScreenLoading).dismiss().then(() =>{
                this.fullScreenLoading = null;
            });
        }
    }

    public async closeLoading() {
        if(this.loading != null){
            (await this.loading).dismiss().then(() =>{
                this.loading = null;
            });
        }
    }

    public formatData(date: string){
        return new Date(date);
    }

    public isMobile(){
        return getMobile();
    }
 }
