import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { MeuPerfilPage } from './meu-perfil.page';
import { MeuPerfilComponent } from 'src/app/arquitetura/perfil/meu-perfil/meu-perfil.component';

@NgModule({
  declarations: [
    MeuPerfilPage,
    MeuPerfilComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class MeuPerfilModule { }
