import { Component, Input } from "@angular/core";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";

@Component({
  selector: 'cmp-modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss']
})
export class ModuloComponent extends BaseComponent {

  @Input() titulo!: string;
  @Input() subTitulo!: string;
  @Input() total: number = 1;
  @Input() valor: number = 1;
  @Input() numero: number = 0;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected navCtrl: NavController){
    super(router, toastController, loadingController);
  }

  onDetalhar(){
    this.navCtrl.navigateForward('/ead/ead-detalhe', { queryParams: { id: this.numero } });
  }

  getValor(){
    return (this.valor / this.total) * 100;
  }
}