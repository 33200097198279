import { Component } from "@angular/core";

@Component({
  selector: 'pg-oferta-detalhe',
  templateUrl: './oferta-detalhe.page.html',
  styleUrls: ['./oferta-detalhe.page.scss']
})
export class OfertaDetalhePage {

  constructor(){
    
  }
}