import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { SolicitarEmprestimoPage } from './solicitar-emprestimo.page';
import { SolicitarEmprestimoComponent } from 'src/app/arquitetura/financeiro/solicitar-emprestimo/solicitar-emprestimo.component';
import { ReCaptchaModule } from 'angular2-recaptcha';

@NgModule({
  declarations: [
    SolicitarEmprestimoPage,
    SolicitarEmprestimoComponent
  ],
  imports: [
    ArquiteturaModule,
    ReCaptchaModule,
  ]
})
export class SolicitarEmprestimoModule { }
