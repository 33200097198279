import { Component, Output, EventEmitter, Input } from '@angular/core';
import { EnderecoService } from 'src/app/shared/service/endereco/endereco.service';
import { BaseComponent } from '../../base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { CidadeModel } from 'src/app/shared/model/endereco/cidade.model';
import { Router } from '@angular/router';
import { EstadoModel } from 'src/app/shared/model/endereco/estado.model';

@Component({
  selector: 'cmp-cidade',
  templateUrl: './cidade.component.html',
  styleUrls: ['./cidade.component.scss']
})
export class CidadeComponent extends BaseComponent {

  cidades: CidadeModel[] = [];
  @Input() valor: any;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected service: EnderecoService) { 
    super(router, toastController, loadingController);
  }

  listar(estadoId: number): void {
    this.cidades = [];
    this.service.cidades(estadoId).then((resultado) =>{
      console.log(resultado);
      this.cidades = resultado.cities as CidadeModel[];
    });
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }

  setCidade(uf: string, cidadeNome: string){
    console.log(uf);
    console.log(cidadeNome);

    this.service.estados().then((estado) =>{
      var estados = estado.states as EstadoModel[];

      estados.forEach((estado) =>{
        if(estado.code === uf){
          this.service.cidades(estado.id).then((resultado) =>{
            this.cidades = resultado.cities as CidadeModel[];
      
            this.cidades.forEach((cidade) =>{
              if(cidade.name === cidadeNome){
                this.valor = cidade.id;
                this.selected.emit(cidade.id);
              }
            })
          });
        }
      })
    });
  }

  setCidadeTexto(estadoNome: string, cidadeNome: string){
    console.log(estadoNome);
    console.log(cidadeNome);

    this.service.estados().then((estado) =>{
      var estados = estado.states as EstadoModel[];

      estados.forEach((estado) =>{
        if(estado.name === estadoNome){
          this.service.cidades(estado.id).then((resultado) =>{
            this.cidades = resultado.cities as CidadeModel[];
      
            this.cidades.forEach((cidade) =>{
              if(cidade.name === cidadeNome){
                this.valor = cidade.id;
                this.selected.emit(cidade.id);
              }
            })
          });
        }
      })
    });
  }
}
