import { Component } from "@angular/core";

@Component({
  selector: 'pg-meus-emprestimos',
  templateUrl: './meus-emprestimos.page.html',
  styleUrls: ['./meus-emprestimos.page.scss']
})
export class MeusEmprestimosPage {

  constructor(){
  }
}