import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { ValidarVoucherPage } from './validar-voucher.page';
import { ValidarVoucherComponent } from 'src/app/arquitetura/vendas/validar-voucher/validar-voucher.component';

@NgModule({
  declarations: [
    ValidarVoucherPage,
    ValidarVoucherComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class ValidarVoucherModule { }
