import { Component } from "@angular/core";

@Component({
  selector: 'pg-minhas-transferencias',
  templateUrl: './minhas-transferencias.page.html',
  styleUrls: ['./minhas-transferencias.page.scss']
})
export class MinhasTransferenciasPage {

  constructor(){
  }
}