import { Component } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { clearStorage, getAccountId, getToken } from '../../service/base.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { EmprestimoService } from 'src/app/shared/service/financeiro/emprestimo.service';
import { Emprestimo } from 'src/app/shared/model/financeiro/emprestimo.model';
import { addMonths } from 'date-fns/addMonths';
import { Adiantamento } from 'src/app/shared/model/financeiro/adiantamento.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmp-meus-emprestimos',
  templateUrl: './meus-emprestimos-em-rs.component.html',
  styleUrls: [
    './meus-emprestimos-em-rs.component.scss',
    `../../../../themes/xpory/css/component/financeiro/meus-emprestimos-em-rs.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/meus-emprestimos-em-rs.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/meus-emprestimos-em-rs.component.scss`,
  ]
})
export class MeusEmprestimosEmRSComponent extends BaseComponent {
  theme = environment.theme_name;
  adiantamentos: Adiantamento[] = [];
  inicio: Date = addMonths(new Date(), -1);
  fim: Date = new Date();
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private service: EmprestimoService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaEmprestimo();
  }

  carregaEmprestimo() {
    this.offset = 0;
    this.max = 12;

    const inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
    const fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

    this.service.listAdvances({
      inicio, fim,
      offset: this.offset,
      max: this.max
    }).then((resultado) => {
      this.count = resultado.listFullSize;
      this.adiantamentos = resultado.advanceList;
    });
  }

  loadData(event) {
    this.offset += this.max;
    const inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
    const fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

    this.service.listAdvances({
      inicio,
      fim,
      offset: this.offset,
      max: this.max
    }).then((resultado) => {
      if (event.target) {
        event.target.complete();
      }

      this.adiantamentos = this.adiantamentos.concat(resultado.advanceList);
    });
  }

  onSelectInicio(inicio: Date) {
    this.inicio = inicio;
    this.carregaEmprestimo();
  }

  onSelectFim(fim: Date) {
    this.fim = fim;
    this.carregaEmprestimo();
  }

  getAdvanceStatus(status: number) {
    const green = 'btn-advance-green';
    const yelllow = 'btn-advance-yellow';
    const red = 'btn-advance-red';

    const statusMap = {
      0: {
        class: yelllow,
        text: 'Aberto'
      },
      1: {
        class: green,
        text: 'Pago no prazo'
      },
      2: {
        class: red,
        text: 'Vencido'
      },
      3: {
        class: green,
        text: 'Pago após o vencimento'
      },
      4: {
        class: green,
        text: 'Pago após o vencimento com valor bloqueado'
      },
      5: {
        class: green,
        text: 'Pago com valor bloqueado'
      }
    };

    return statusMap[status];
  }
}
