import { Component } from "@angular/core";
import { BaseComponent } from "../../base.component";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { LoginService } from "src/app/shared/service/login/login.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-recuperar',
  templateUrl: './recuperar.component.html',
  styleUrls: [
    './recuperar.component.scss',
    `../../../../themes/xpory/css/component/onboarding/recuperar.component.scss`,
    `../../../../themes/xpory-futebol/css/component/onboarding/recuperar.component.scss`,
    `../../../../themes/fcj-barter/css/component/onboarding/recuperar.component.scss`,
  ]
})
export class RecuperarComponent extends BaseComponent{
  theme = environment.theme_name;
  email!: string;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected navCtrl: NavController,
    protected service: LoginService){
        super(router, toastController, loadingController);
  }

  recuperar(){
    this.openLoading('Carregando...');
    this.service.recuperar(this.email).then((resultado) => {
      if(resultado.ok)
        this.message(resultado.msg);
      else if(resultado.msg)
        this.message(resultado.msg);
    }).catch((ex) =>{
      console.log(ex);
    }).finally(() =>{
      this.closeLoading();
    })
  }

  login(){
    this.navCtrl.navigateForward('/onboarding/login');
  }

  cadastrar(){
    this.navCtrl.navigateForward('/onboarding/cadastrar');
  }
}