import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { RecuperarPage } from './recuperar.page';
import { RecuperarComponent } from 'src/app/arquitetura/onboarding/recuperar/recuperar.component';

@NgModule({
  declarations: [
    RecuperarPage,
    RecuperarComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class RecuperarModule { }
