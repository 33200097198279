import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PainelMinhasIndicacoesPage } from './painel-minhas-indicacoes.page';
import { PainelMinhasIndicacoesComponent } from 'src/app/arquitetura/indicacoes/painel-minhas-indicacoes/painel-minhas-indicacoes.component';

@NgModule({
  declarations: [
    PainelMinhasIndicacoesPage,
    PainelMinhasIndicacoesComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PainelMinhasIndicacoesModule { }
