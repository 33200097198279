import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { FazerTransferenciaPage } from './fazer-transferencia.page';
import { FazerTransferenciaComponent } from 'src/app/arquitetura/transferencias/fazer-transferencia/fazer-transferencia.component';

@NgModule({
  declarations: [
    FazerTransferenciaPage,
    FazerTransferenciaComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class FazerTransferenciaModule { }
