import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { DashboardHomeComponent } from 'src/app/arquitetura/perfil/dashboard/dashboard-home.component';
import { DashboardPage } from './dashboard.page';
import { UiModule } from "../../../shared/ui.module";

@NgModule({
  declarations: [
    DashboardPage,
    DashboardHomeComponent
  ],
  imports: [
    ArquiteturaModule,
    UiModule
  ]
})
export class DashboardModule { }
