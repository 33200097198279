import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { Module } from "src/app/shared/model/ead/module.model";
import { EADService } from "src/app/shared/service/ead/ead.service";
import { getAccountId, getToken } from "../../service/base.service";

@Component({
  selector: 'cmp-ead-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss']
})
export class PainelComponent extends BaseComponent {

  modulos: Module[] = [];

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private servico: EADService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregarModulos();    
  }

  carregarModulos(){
    var token = getToken();
    var accountId = getAccountId();
    this.servico.listar(token, accountId).then((resultado) => {
        console.log(resultado);
      if(resultado.ok){
        this.modulos = resultado.moduleList as Module[];
        if(this.modulos.length > 0){
          this.modulos = this.modulos.sort((a, b) => {return a.moduleNumber - b.moduleNumber});
        }
      }
    });
  }
}