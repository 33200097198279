import { Component, OnInit, ViewChild } from "@angular/core";
import { CabecalhoComponent } from "src/app/arquitetura/cabecalho/cabecalho.component";

@Component({
  selector: 'pg-painel-compras',
  templateUrl: './painel-compras.page.html',
  styleUrls: ['./painel-compras.page.scss']
})
export class PainelComprasPage implements OnInit{

  @ViewChild('cabecalho') cabecalho: CabecalhoComponent;

  constructor(){
  }

  ngOnInit(): void {
    if(this.cabecalho)
      this.cabecalho.atualizarLocalizacao();
  }
}