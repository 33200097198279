import { Component } from "@angular/core";

@Component({
  selector: 'pg-rodada-de-negocios',
  templateUrl: './rodada-de-negocios.page.html',
  styleUrls: ['./rodada-de-negocios.page.scss']
})
export class RodadaDeNegociosPage {

  constructor(){
  }
}