import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { IndicacaoService } from "src/app/shared/service/indicacao/indicacao.service";
import { clearStorage, getAccountId } from "../../service/base.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-painel-indicacao',
  templateUrl: './painel-indicacao.component.html',
  styleUrls: [
    './painel-indicacao.component.scss',
    `../../../../themes/xpory/css/component/indicacoes/painel-indicacao.component.scss`,
    `../../../../themes/xpory-futebol/css/component/indicacoes/painel-indicacao.component.scss`,
    `../../../../themes/fcj-barter/css/component/indicacoes/painel-indicacao.component.scss`,
  ]
})
export class PainelIndicacaoComponent extends BaseComponent {
  theme = environment.theme_name;
  indicacoes: any[] = []

  count: number = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private service: IndicacaoService){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaIndicacoes();
  }

  carregaIndicacoes(){
    this.offset = 0;
    this.max = 12;

    var accountId = getAccountId();

    this.service.listar(accountId, this.offset, this.max).then((resultado) =>{
      this.count = resultado.listFullSize;
      this.indicacoes = resultado.listIndications;
    }).catch((ex) =>{
      
    });
  }

  loadData(event){
    this.offset += this.max;
    var accountId = getAccountId();

    this.service.listar(accountId, this.offset, this.max).then((resultado) =>{
      if(event.target){
        event.target.complete();
      }

      if(resultado){
        if(!resultado.msg){
          var indicacoes = resultado.listIndications;
          this.indicacoes = this.indicacoes.concat(indicacoes);
        }
      }
    });
  }

  onAdicionar(){
    this.router.navigate(['/indicacoes/painel-minhas-indicacoes']);
  }
}