import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { OfertaDetalhePage } from './oferta-detalhe.page';
import { OfertaDetalheComponent } from 'src/app/arquitetura/core/oferta-detalhe/oferta-detalhe.component';

@NgModule({
  declarations: [
    OfertaDetalhePage,
    OfertaDetalheComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class OfertaDetalheModule { }
