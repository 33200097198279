import { Imagens } from "../geral/images.model";
import { QualificationModel } from "../geral/qualification.model";

export class OfertaResumida{
    nationalOffer: boolean;
	qualification: QualificationModel;
	images: Imagens;
	entityPhoto: string; 
	price: number;
	entityName: string;
	name: string;
	entityNickName: string;
	entityId: number;
	id: number;
	qualificationAverage: any;
	isPhysicalStoreOffer: boolean;
    isUnderBudget: boolean;
    urlImage1: string;
    activate: boolean;
}

export class OfertasResumida{
    offerList: OfertaResumida[];
}

export class OfertaResumidaCategoria{
    cityId:  number;
    entityId: number; 
    entityName: string;
    entityPhoto: string;
    isPhysicalStoreOffer: boolean;
    isUnderBudget: boolean;
    nationalOffer: boolean;
    offerId: number;
    offerName: string;
    offerPrice: number;
    qualification: QualificationModel;
    stars: number;
    urlImage1: string;
}

export class OfertasResumidaCategoria{
    offerList: OfertaResumidaCategoria[];
}