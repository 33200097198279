import { Component } from "@angular/core";

@Component({
  selector: 'pg-finalizar-compra',
  templateUrl: './finalizar-compra.page.html',
  styleUrls: ['./finalizar-compra.page.scss']
})
export class FinalizarCompraPage {

  constructor(){
    
  }
}