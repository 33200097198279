import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EstornoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, received: boolean, offset: number, max: number) {
    return this.http.get(`${environment.api}v2/reversal?token=${token}&accountId=${accountId}&received=${received}&offset=${offset}&max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, received: boolean, inicio: string, fim: string, offset: number, max: number) {
    return this.http.get(`${environment.api}v2/reversal?token=${token}&accountId=${accountId}&received=${received}&offset=${offset}&max=${max}&reversalInitialDate=${inicio}&reversalEndDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarTransferencia(token: string, accountId: number, received: boolean, offset: number, max: number) {
    return this.http.post(`${environment.api}v2/list-transfer-reversal?token=${token}&accountId=${accountId}&received=${received}&offset=${offset}&max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarTransferenciaData(token: string, accountId: number, received: boolean, inicio: string, fim: string, offset: number, max: number) {
    return this.http.post(`${environment.api}v2/list-transfer-reversal?token=${token}&accountId=${accountId}&received=${received}&offset=${offset}&max=${max}&initialDate=${inicio}&endDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  solicitar(token: string, accountId: number, purchaseId: number, reason: string) {
    return axios.post(
      `${environment.api}v1/reversal-purchase-request?token=${token}&accountId=${accountId}&purchaseId=${purchaseId}&reason=${reason}`,
      null,
      {
        headers: this.configToken(),
      }
    ).then(data => data.data);
  }

  responder(accountId: number, reversalPurchaseId: number, approve: boolean, answer: string) {
    return this.http.post(`${environment.api}v1/reversal-purchase-approve?reversalPurchaseId=${reversalPurchaseId}&approve=${approve}&answer=${answer}`, null, this.config()).then(data => JSON.parse(data.data));
  }
}