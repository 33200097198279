import { Component } from "@angular/core";

@Component({
  selector: 'pg-validar-voucher',
  templateUrl: './validar-voucher.page.html',
  styleUrls: ['./validar-voucher.page.scss']
})
export class ValidarVoucherPage {

  constructor(){
  }
}