import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { LoginPage } from './login.page';
import { LoginComponent } from 'src/app/arquitetura/onboarding/login/login.component';

@NgModule({
  declarations: [
    LoginPage,
    LoginComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class LoginModule { }
