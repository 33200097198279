import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PainelOfertasPage } from './painel-ofertas.page';
import { PainelOfertasComponent } from 'src/app/arquitetura/minhas-ofertas/painel-ofertas/painel-ofertas.component';

@NgModule({
  declarations: [
    PainelOfertasPage,
    PainelOfertasComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PainelOfertasModule { }
