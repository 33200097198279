import { Component } from "@angular/core";

@Component({
  selector: 'pg-painel-minhas-indicacoes',
  templateUrl: './painel-minhas-indicacoes.page.html',
  styleUrls: ['./painel-minhas-indicacoes.page.scss']
})
export class PainelMinhasIndicacoesPage {

  constructor(){
  }
}