import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { RegulamentoDetalhePage } from './regulamento-detalhe.page';

@NgModule({
  declarations: [
    RegulamentoDetalhePage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class RegulamentoDetalheModule { }
