import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmp-card-menu-perfil',
  templateUrl: './card-menu-perfil.component.html',
  styleUrls: [
    './card-menu-perfil.component.css',
    `${environment.component_css}/component/card-menu-perfil.component.css`,
  ]
})
export class CardMenuPerfilComponent {

  @Input() titulo!: string;
  @Input() icone!: string;
  @Input() link!: string;
  @Input() itens: any[] = [];
  @Input() expand!: boolean;
  @Input() forecolor!: boolean;
  @Input() isLead: boolean;

  constructor(
    private router: Router
  ) { 
  }

  menuClick() {
    if (this.isLead) {
      return;
    }
  
    if (!this.itens || this.itens.length <= 0) {
      this.router.navigate([this.link]); // Navega sem recarregar a página
    } else {
      this.expand = !this.expand;
    }
  }

  itemClick(link: string) {
    if (this.isLead) {
      return;
    }

    this.router.navigate([link]); // Navega sem recarregar a página
  }
}
