import { Component, Input } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { Oferta } from 'src/app/shared/model/ofertas/oferta.model';
import { OfertaResumida } from 'src/app/shared/model/ofertas/oferta-resumida.model';
import { OfertasService } from 'src/app/shared/service/ofertas/ofertas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Estorno } from 'src/app/shared/model/financeiro/estorno.model';
import { Voucher } from 'src/app/shared/model/compras/compras.model';
import { getAccountId, getToken } from '../../service/base.service';
import { environment } from 'src/environments/environment';
import { EstornoService } from 'src/app/shared/service/financeiro/estorno.service';
import { Location } from '@angular/common';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'cmp-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: [
    './oferta.component.scss',
    `../../../../themes/xpory/css/component/cards/oferta.component.scss`,
    `../../../../themes/xpory-futebol/css/component/cards/oferta.component.scss`,
    `../../../../themes/fcj-barter/css/component/cards/oferta.component.scss`,
  ]
})
export class OfertaComponent extends BaseComponent {
  theme = environment.theme_name;
  ofertaDetalhada: Oferta;
  showVouchers: boolean;
  showCurrentVoucher: boolean;
  showPDF: boolean;
  showEstorno: boolean;
  motivoEstorno: string;
  estornoSolicitado: boolean;

  @Input() ofertaId: number;
  @Input() oferta: OfertaResumida;
  @Input() preco = true;
  @Input() venda: boolean;
  @Input() compra: boolean;
  @Input() status: string;
  @Input() estorno: Estorno;
  @Input() dataCompra: string;
  @Input() vouchers: Voucher[];
  @Input() compraId: number;
  @Input() editar: boolean;
  @Input() mostraAtiva: boolean;
  @Input() cardGrande: boolean;
  pdfUrl?: string;
  currentVoucher: Voucher;

  constructor(
    public router: Router,
    private iab: InAppBrowser,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected navCtrl: NavController,
    protected service: OfertasService,
    private estornoService: EstornoService,
    private location: Location,
    private route: ActivatedRoute,
    private file: File,
    private http: HTTP,
    private fileOpener: FileOpener,
    private socialSharing: SocialSharing,
  ) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregarOferta();
  }

  carregarOferta() {
    if (this.oferta) {
      this.service.obter(this.oferta.id).then((resultado) => {
        if (resultado) {
          this.ofertaDetalhada = resultado.offer as Oferta;
        }
      });
    } else if (this.ofertaId) {
      this.service.obter(this.ofertaId).then((resultado) => {
        if (resultado) {
          this.ofertaDetalhada = resultado.offer as Oferta;
        }
      });
    }
  }

  detalhar() {
    if (!this.venda && !this.compra && !this.editar) {
      const regex = new RegExp(`\\boferta-detalhe\\b`, 'i');
      if (regex.test(this.location.path())) {
        this.route.queryParams.subscribe(params => {
          const queryParams = { ...params, id: this.oferta.id };
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge'
          });
        });
      }
      else {
        this.navCtrl.navigateForward('/core/oferta-detalhe', { queryParams: { id: this.oferta.id } });
      }
    }
  }

  getSituacao(isUsed: boolean) {
    if (isUsed) { return 'Indisponível'; }

    return 'Disponível';
  }

  onImprimir(code: string) {
    const token = getToken();
    const accountId = getAccountId();
    const browser = this.iab.create(`${environment.api}v1/download-voucher?accountId=${accountId}&token=${token}&voucherCode=${code}`, '_system');
  }

  openVoucherDetails(voucher: Voucher) {
    this.showCurrentVoucher = true;
    this.currentVoucher = voucher;
  }

  async downloadPdf(): Promise<any> {
    try {
      const token = getToken();
      const accountId = getAccountId();

      this.pdfUrl = `${environment.api}v1/download-voucher?accountId=${accountId}&token=${token}&voucherCode=${this.currentVoucher.code}`;

      const cordova = window['cordova'];

      let storagePath;
      if (cordova.platformId === 'android') {
        storagePath = cordova.file.externalRootDirectory + '/Download';
      }
      if (cordova.platformId === 'ios') {
        storagePath = cordova.file.documentsDirectory;
      }

      const httpResponse = await this.http.sendRequest(this.pdfUrl, { method: 'get', responseType: 'arraybuffer' });
      const downloadedFile = new Blob([httpResponse.data], { type: 'application/pdf' });

      const pdfName = `${this.currentVoucher.code}.pdf`;
      const filedSaved = await this.file.createFile(storagePath, pdfName, true);
      await this.file.writeFile(storagePath, pdfName, downloadedFile, { replace: true, append: false });

      return {
        url: filedSaved.nativeURL,
        data: downloadedFile
      };
    } catch (error) {
      console.log(error);
    }
  }

  async onVerPdf() {
    this.openLoading('Abrindo PDF...');
    const { url } = await this.downloadPdf();
    this.closeLoading();
    this.fileOpener.open(url, 'application/pdf');
  }

  async onBaixarPdf() {
    this.openLoading('Baixando PDF...');
    await this.downloadPdf();
    const toast = await this.toastController.create({
      message: 'PDF baixado com sucesso!',
      duration: 10000
    });

    await toast.present();
    this.closeLoading();
  }

  async onCompartilharPdf() {
    this.openLoading('Compartilhando PDF...');
    const { url } = await this.downloadPdf();
    this.closeLoading();
    this.socialSharing.share('Compartilhar PDF', null, url, null)
  }

  onSolicitar() {
    if (!this.motivoEstorno && this.motivoEstorno.length < 10) {
      this.message('O motivo de estorno deve ter no mínimo 10 caracteres!');
      return;
    }

    const token = getToken();
    const accountId = getAccountId();
    this.estornoService.solicitar(token, accountId, this.compraId, this.motivoEstorno).then((resultado) => {
      this.message(resultado.msg);

      this.estornoSolicitado = resultado.ok;
      this.showEstorno = false;

      if (resultado.ok) {
        this.navCtrl.back();
      }
    });
  }

  onDesativar() {
    this.openLoading('Desativando...');
    this.service.setStatus(this.oferta.id, false).then((resultado) => {
      console.log(resultado);
      this.message(resultado.msg);
      this.carregarOferta();
    }).catch((ex) => {
      console.log(ex);
      this.message('Oferta não desativada!');
    }).finally(() => {
      this.closeLoading();
    });
  }

  onEditar() {
    this.navCtrl.navigateForward('/minhas-ofertas/painel-ofertas-cadastro', { queryParams: { id: this.oferta.id } });
  }
}
