import { Component, OnInit, ViewChild } from "@angular/core";
import { CabecalhoComponent } from "src/app/arquitetura/cabecalho/cabecalho.component";

@Component({
  selector: 'pg-estornos',
  templateUrl: './estornos.page.html',
  styleUrls: ['./estornos.page.scss']
})
export class EstornosPage implements OnInit{

  @ViewChild('cabecalho') cabecalho: CabecalhoComponent;

  constructor(){
  }

  ngOnInit(): void {
    if(this.cabecalho)
      this.cabecalho.atualizarLocalizacao();
  }
}