import { Component } from "@angular/core";

@Component({
  selector: 'pg-meu-video-pitch',
  templateUrl: './meu-video-pitch.page.html',
  styleUrls: ['./meu-video-pitch.page.scss']
})
export class MeuVideoPitchPage {

  constructor(){
  }
}