import { Component } from "@angular/core";

@Component({
  selector: 'pg-fazer-transferencia-detalhe',
  templateUrl: './fazer-transferencia-detalhe.page.html',
  styleUrls: ['./fazer-transferencia-detalhe.page.scss']
})
export class FazerTransferenciaDetalhePage {

  constructor(){
  }
}