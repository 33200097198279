import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { FazerTransferenciaDetalhePage } from './fazer-transferencia-detalhe.page';
import { FazerTransferenciaDetalheComponent } from 'src/app/arquitetura/transferencias/fazer-transferencia-detalhe/fazer-transferencia-detalhe.component';

@NgModule({
  declarations: [
    FazerTransferenciaDetalhePage,
    FazerTransferenciaDetalheComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class FazerTransferenciaDetalheModule { }
