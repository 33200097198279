import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { OfertaResumida, OfertaResumidaCategoria } from '../../model/ofertas/oferta-resumida.model';
import { Imagens } from '../../model/geral/images.model';
import { Oferta } from '../../model/ofertas/oferta.model';
import { Ordenacao } from '../../model/geral/order.model';
import axios from 'axios';

@Injectable()
export class OfertasService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listarDestaque(max: number) {
    return this.http.get(`${environment.api}v2/list-random-boom-offers?max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarRandom(max: number) {
    return this.http.get(`${environment.api}v2/featured-offers?randomOrder=true&max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarPorCategoria(categoriaId: number, max: number) {
    return this.http.get(`${environment.api}v2/random-offer/${categoriaId}?max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  obter(id: number) {
    return axios.get(`${environment.api}v2/offer-details?offerId=${id}`, {
      headers: this.config()
    }).then(data => data.data);
  }

  listarUsuario(id: number, max: number) {
    const param = `filters=%7B"entityId":${id},"max":${max},"offset":0,"type":"offer","activate":true%7D&status=entity&offset=0`;

    return this.http.get(`${environment.api}v1/list-offers-and-entities?${param}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listar(texto: string, page: number, limit: any, state: string, ordenacao: Ordenacao, estadoProduto?: any, categorias?: number[], min?: string, max?: string) {
    const param = new URLSearchParams();

    let parametros = `"limit":${limit}`;
    parametros = parametros + `,"offset":${page}`;
    parametros = parametros + `,"isUnderBudget":${false}`;

    if (texto) {
      parametros = parametros + `,"textFilter":"${texto}"`;
    }
    if (estadoProduto) {
      parametros = parametros + `,"isProductNew":${estadoProduto == '1'}`;
    }
    if (categorias && categorias.length > 0) {
      let aux = '';
      categorias.forEach(cat => {
        if (aux && aux.length > 0) {
          aux = aux + ',';
        }
        aux = aux + `${cat}`;
      });
      console.log(aux);
      aux = `[${aux}]`;
      console.log(aux);
      parametros = parametros + `,"categories":${aux}`;
    }
    if (min) {
      parametros = parametros + `,"minPrice":${min}`;
    }
    if (max) {
      parametros = parametros + `,"maxPrice":${max}`;
    }

    param.append('filters', `{${parametros}}`);
    if (ordenacao) {
      param.append('order', `{${ordenacao.name}: "${ordenacao.value}"}`);
    } else {
      param.append('order', `activate-desc`);
    }
    param.append('numBoom', '1');


    // this.http.post(`${environment.api}v2/offer`, param, this.config()).then(data => JSON.parse(data.data));
    return axios.post(
      `${environment.api}v2/offer?${param}`,
      param,
      {
        headers: this.config()
      }
    ).then(data => data.data);
  }

  dePara(offerList: OfertaResumidaCategoria[]) {
    const ofertas = [] as OfertaResumida[];

    offerList.forEach((offer) => {
      const temp = new OfertaResumida();
      temp.entityId = offer.entityId;
      temp.entityName = offer.entityName;
      temp.entityNickName = offer.entityName;
      temp.entityPhoto = offer.entityPhoto;
      temp.id = offer.offerId;
      temp.images = new Imagens();
      temp.images.urlImage1 = offer.urlImage1;
      temp.isPhysicalStoreOffer = offer.isPhysicalStoreOffer;
      temp.name = offer.offerName;
      temp.nationalOffer = offer.nationalOffer;
      temp.price = offer.offerPrice;
      temp.qualification = offer.qualification;

      ofertas.push(temp);
    });

    return ofertas;
  }

  listarMinhas(token: string, accountId: number, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-my-offers?offset=${offset}&max=${max}&token=${token}&accountId=${accountId}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarMinhasData(token: string, accountId: number, inicio: string, fim: string, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-my-offers?offset=${offset}&max=${max}&token=${token}&accountId=${accountId}&initialDate=${inicio}&endDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  salvar(oferta: Oferta, accountId: number) {
    return this.http.post(`${environment.api}v1/register-offer?offer=${encodeURIComponent(`{"name":"${oferta.name}",${oferta.isUnderBudget ? `"price":0` : `"price":"${oferta.price}"`},${oferta.isUnderBudget ? `"offersAvailable":1` : `"offersAvailable":${oferta.offersAvailable}`},"endDate":"${oferta.endDate}",${oferta.limitByClient ? `"limitByClient":${oferta.limitByClient}` : `"limitByClient":""`},"activate":${oferta.activate},"description":"${oferta.description}","keywords":"${oferta.keywords}","isUnderBudget":${oferta.isUnderBudget},"isProductNew":${oferta.isProductNew},"isProduct":${oferta.isProduct},"isPhysicalStoreOffer":${oferta.isPhysicalStoreOffer},"city":"${oferta.city}","state":"${oferta.state}","urlImage1":"${oferta.urlImage1}","urlImage2":"${oferta.urlImage2}","urlImage3":"${oferta.urlImage3}","urlImage4":"${oferta.urlImage4}","categoryId":"${oferta.categoryId}","specialityId":${oferta.specialityId},"nationalOffer":${oferta.nationalOffer},${oferta.shippingOptions ? `"shippingOptions":"${oferta.shippingOptions}"` : ``}}`)}&responsibleId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  editar(oferta: Oferta, accountId: number, token: string) {
    return this.http.post(`${environment.api}v1/edit-offer?offer=${encodeURIComponent(`{"id": ${oferta.id}, "name":"${oferta.name}",${oferta.isUnderBudget ? `"price":0` : `"price":"${oferta.price}"`},${oferta.isUnderBudget ? `"offersAvailable":1` : `"offersAvailable":${oferta.offersAvailable}`},"endDate":"${oferta.endDate}",${oferta.limitByClient ? `"limitByClient":${oferta.limitByClient}` : `"limitByClient":""`},"activate":${oferta.activate},"description":"${oferta.description}","keywords":"${oferta.keywords}","isUnderBudget":${oferta.isUnderBudget},"isProductNew":${oferta.isProductNew},"isProduct":${oferta.isProduct},"isPhysicalStoreOffer":${oferta.isPhysicalStoreOffer},"city":"${oferta.city}","state":"${oferta.state}","urlImage1":"${oferta.urlImage1}","urlImage2":"${oferta.urlImage2}","urlImage3":"${oferta.urlImage3}","urlImage4":"${oferta.urlImage4}","categoryId":"${oferta.categoryId}","specialityId":${oferta.specialityId},"nationalOffer":${oferta.nationalOffer},${oferta.shippingOptions ? `"shippingOptions":"${oferta.shippingOptions}"` : ``}}`)}&token=${token}&accountId=${accountId}&responsibleId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  orcamento(ofertaId: number) {
    return this.http.post(`${environment.api}v2/send-email-of-interest`, { offerId: ofertaId }, this.config()).then(data => JSON.parse(data.data));
  }

  compra(ofertaId: number, quantidade: number, token: string, accountId: number) {
    return this.http.post(`${environment.api}v1/buy-offer?quantity=${quantidade}&offerId=${ofertaId}&paymentParams=%7B%22paymentType%22:%22balanceAccount%22,%22installments%22:1%7D&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  compraCartao(ofertaId: number, quantidade: number, token: string, accountId: number, cartao: number, securityCode: string, parcelas: number) {
    return this.http.post(`${environment.api}v1/buy-offer?quantity=${quantidade}&offerId=${ofertaId}&paymentParams=%7B%22paymentType%22:%22creditCard%22,%22securityCode%22:${securityCode},%22cieloTokenCardId%22:${cartao},%22installments%22:${parcelas}%7D&token=${token}&accountId=${accountId}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  voucher(id: number, token: string, accountId: number) {
    return this.http.get(`${environment.api}v1/list-voucher-code-by-id?id=${id}&token=${token}&accountId=${accountId}`, null, this.configToken()).then(data => JSON.parse(data.data));
  }

  setStatus(id: number, status: boolean) {
    return this.http.put(`${environment.api}v2/set-offer-status/${id}?status=${status}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}
