import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { ConfirmarCadastroPage } from './confirmar-cadastro.page';
import { ConfirmarCadastroComponent } from 'src/app/arquitetura/onboarding/confirmar-cadastro/confirmar-cadastro.component';

@NgModule({
  declarations: [
    ConfirmarCadastroPage,
    ConfirmarCadastroComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class ConfirmarCadastroModule { }
