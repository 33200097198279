import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from '@angular/fire/storage';
import { BaseComponent } from './base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable()
export class FirebaseComponent extends BaseComponent{
    
    constructor(
        public router: Router,
        public toastController: ToastController, 
        public loadingController: LoadingController,
        protected storage: Storage){
        super(router, toastController, loadingController);
    }

    protected async uploadImage(name: string, image: any, callback = new Function()){
        const storageRef = ref(this.storage, name);
        const uploadTask = uploadBytesResumable(storageRef, image, { contentType: 'image/jpeg' });
        
        await uploadTask.on('state_changed', 
            (snapshot) =>{
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                return true;
            },
            (error) =>{
                console.log(error);
                return false;
            },
            () =>{
                getDownloadURL(uploadTask.snapshot.ref).then((url) =>{
                    console.log(url);
                    callback(url);
                    return true;
                });
            }
        );
    }

    protected async deleteImage(name: string){
        const defaultOfferImg =
            'https://storage.googleapis.com/xpory-images-617e8.appspot.com/default-images/offer.png';
        const defaultProfileImg =
            'https://storage.googleapis.com/xpory-images-617e8.appspot.com/default-images/user.png';

        if (name !== defaultOfferImg && name !== defaultProfileImg) {
            const storageRef = ref(this.storage, name);
            return deleteObject(storageRef).then(() => {
                
            }).catch((error) => {
                
            });
        }
    }
}