import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';

@Injectable()
export class CategoriaService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar() {
    return axios
      .get(
        `${environment.api}v1/list-category-speciality?icon=true&image=false&noSpecialities=true`,
        {
          headers: this.config()
        }
      ).then(data => data.data);
  }
}
