import { Component } from "@angular/core";

@Component({
  selector: 'pg-guia-do-usuario',
  templateUrl: './guia-do-usuario.page.html',
  styleUrls: ['./guia-do-usuario.page.scss']
})
export class GuiaDoUsuarioPage {

  constructor(){
  }
}