import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "src/app/arquitetura/base.component";
import { clearStorage } from "src/app/arquitetura/service/base.service";
import { LoginService } from "src/app/shared/service/login/login.service";

@Component({
  selector: 'pg-excluir-conta',
  templateUrl: './excluir-conta.page.html',
  styleUrls: ['./excluir-conta.page.scss']
})
export class ExcluirContaPage extends BaseComponent{

    reason: string;

    constructor(
        private service: LoginService,
        public router: Router,
        public toastController: ToastController, 
        public loadingController: LoadingController,
        private navCtrl: NavController){
        super(router, toastController, loadingController);
      }

  onCancelar(){
    this.navCtrl.navigateForward(['/core/home']);
  }

  onConfirmar(){
    if(!this.reason){
      this.message('Informe o motivo para a exclusão da conta!');
    }else{
      this.openLoading('Excluindo...')
      this.service.remover(this.reason).then((resultado) =>{
        console.log(resultado);
        this.message(resultado.msg);
        clearStorage();
        this.navCtrl.navigateForward('/core/home').then(() =>{
          window.location.reload();
       });
      }).catch((ex) =>{
        console.log(ex);
        this.message(JSON.parse(ex.error).msg);
      }).finally(() =>{
        this.closeLoading();
      })
    }
  }
}