import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LoadingController, MenuController, NavController, Platform, ToastController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { clearStorage, getAccountId, getCity, getCityName, getMobile, getState, getStateName, getToken, getUsuarioLogado, removeState, setCity, setCityName, setState, setStateName } from "../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { EnderecoService } from "src/app/shared/service/endereco/endereco.service";
import { BaseComponent } from "../base.component";
import { Router } from "@angular/router";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { EstadoComponent } from "../selects/estado/estado.component";
import { CidadeComponent } from "../selects/cidade/cidade.component";
import { EstadoModel } from "src/app/shared/model/endereco/estado.model";
import { CidadeModel } from "src/app/shared/model/endereco/cidade.model";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'cmp-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: [
    './cabecalho.component.scss',
  ]
})
export class CabecalhoComponent extends BaseComponent {
  logo = `${environment.theme_assets}image/logo.png`;
  logo_sebrae = `${environment.theme_assets}image/logo-sebrae-transparente.png`;
  mostra_sebrae = environment.mostra_sebrae;
  logado: boolean = false;
  usuario: Usuario;
  showLocalizacao: boolean;
  showBuscar: boolean;
  buscar: boolean;

  cidade: string;
  estado: string;
  estadoId: number;
  cidadeId: number;
  uf: string;
  mensagemLocalizacao: string;

  texto: string;

  loadingAuthState: boolean;

  isMobileMenuOpen: boolean = false;
  isMobileSearchMenuOpen: boolean = false;

  @Input() parent!: string;
  @Input() voltar!: boolean;

  @Output() mudarLocalizacao: EventEmitter<any> = new EventEmitter;
  @Output() buscarOferta: EventEmitter<any> = new EventEmitter;

  @ViewChild('estados') estados: EstadoComponent;
  @ViewChild('cidades') cidades: CidadeComponent;

  constructor(
    public router: Router,
    private platform: Platform,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected menu: MenuController,
    protected navCtrl: NavController,
    protected service: UsuarioService,
    protected serviceEstado: EnderecoService,
    private http: HttpClient,
    private geolocation: Geolocation){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.platform.ready().then(()=>{
      this.closeMobileMenu();
      this.verificarLogin();
      this.obterLocalizacao();
      this.showBuscar = false;
    });

    this.router.events.subscribe((val) => {
      this.closeMobileMenu();
      this.closeMobileSearchMenu();
    });
  }

  ngOnDestroy(){
    this.closeMobileMenu();
  }

  ionViewWillEnter(){
    this.atualizarLocalizacao();
  }

  ionViewDidEnter(){
    this.atualizarLocalizacao();
  }

  isMobile(){
    return getMobile();
  }

  openMobileMenu() {
    this.isMobileMenuOpen = true;
  }

  closeMobileMenu() {
    this.isMobileMenuOpen = false;
  }

  openMobileSearchMenu() {
    this.isMobileSearchMenuOpen = true;
  }

  closeMobileSearchMenu() {
    this.isMobileSearchMenuOpen = false;
  }

  verificarLogin(){
    this.usuario = JSON.parse(getUsuarioLogado());

    if(this.usuario) {
      this.loadingAuthState = true;
      var token = getToken();
      var accountId = getAccountId();

      this.loadingAuthState = true;

      this.service.get(token, accountId).then((resultado) =>{
        if(resultado.ok === false){
          clearStorage();
          this.navCtrl.navigateForward(['/core/home']).then(() =>{
            window.location.reload();
          });
        }else{
          this.usuario = resultado.entity as Usuario;
          this.logado = true;
        }
        this.loadingAuthState = false;
        this.closeLoading();
      }).catch((ex) =>{
        console.log(ex);
      });
    }
  }

  abrirMenuPrincipal(){
    this.menu.open('menuPrincipal');
  }

  home(){
    this.navCtrl.navigateForward('/core/home').then(() => {
      window.location.reload();
    });
  }

  login(){
    this.navCtrl.navigateForward('/onboarding/login').then(() => {
      window.location.reload();
    });
  }

  cadastrar(){
    this.navCtrl.navigateForward('/onboarding/cadastrar').then(() => {
      window.location.reload();
    });
  }

  onVoltar(){
    this.navCtrl.navigateForward(this.parent);
  }

  atualizarLocalizacao(){
    this.uf = getState();
    this.cidade = getCityName();
    this.estado = getStateName();
  }

  obterLocalizacao() {
    this.uf = getState();
    this.cidade = getCityName();
    this.estado = getStateName();

    const updateLocation = () => {
      this.geolocation.getCurrentPosition({ enableHighAccuracy: true }).then((resp) => {
        this.openLoading('Carregando...');
        const lat = resp.coords.latitude;
        const long = resp.coords.longitude;
        this.obterNomeCidadeEstado(lat, long);
      }, () => {
        removeState();
        this.showLocalizacao = true;
      }).finally(() => {
        this.closeLoading();
      });
    };

    if(!this.uf || !this.cidade || !this.estado){
      const cordova = window['cordova'];

      if (!cordova) {
        updateLocation();
      };

      if (cordova.platformId === 'android') {
        const permissions = cordova.plugins.permissions as any;

        permissions.requestPermission(
          permissions.ACCESS_FINE_LOCATION,
          (status) => {
            if (status.hasPermission) {
              updateLocation();
            } else {
              this.message('Sua localização está em um território não suportado, informe um estado e cidade do Brasil!');
            }
          },
          (error) => {
            console.log(error);
            this.message('Sua localização está em um território não suportado, informe um estado e cidade do Brasil!');
          }
        );
      } else {
        updateLocation();
      }
    }
  }

  obterNomeCidadeEstado(lat: number, long: number) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${environment.googleAPI}`;
  
    this.http.get(url).subscribe((data: any) => {
      if (data.results.length > 0) {
        const endereco = data.results[0].address_components;
        const cidade = endereco.find(comp => comp.types.includes('administrative_area_level_2'));
        const estado = endereco.find(comp => comp.types.includes('administrative_area_level_1'));
        const pais = endereco.find((comp: any) => comp.types.includes("country"));

        if(pais.short_name !== 'BR'){
          this.mensagemLocalizacao = 'Sua localização está em um território não suportado, informe um estado e cidade do Brasil!'
          this.showLocalizacao = true;
        }else{
          this.mensagemLocalizacao = undefined;
          if (cidade && estado) {
            this.serviceEstado.estados().then((states) =>{
              var estados = states.states as EstadoModel[];
        
              estados.forEach((state) =>{
                if(state.code === estado.short_name){
                  this.serviceEstado.cidades(state.id).then((resultado) =>{
                    var cidades = resultado.cities as CidadeModel[];
              
                    cidades.forEach((city) =>{
                      if(city.name === cidade.long_name){
                        setState(state.code);
                        setCity(city.id);
                        setCityName(city.name);
                        setStateName(state.name);
                        
                        this.cidade = city.name;
                        this.estado = state.name;
                        this.uf = state.code;

                        console.log(`1 - ${this.cidade} ${this.estado} ${this.uf}`);

                        this.showLocalizacao = false;
                        this.mudarLocalizacao.emit();
                      }
                    })
                  });
                }
              });
            });
          }
        }
      }
    });
  }

  onSelectEstado(id: any){
    console.log(id + ' estado');
    this.estadoId = id;
    this.cidades.listar(id);
  }

  onSelectCidade(id: any){
    this.cidadeId = id;
  }

  onAbrir(){
    this.showLocalizacao = true;

    this.uf = getState();
    this.cidade = getCityName();
    this.estado = getStateName();

    console.log(`${this.uf} - ${this.cidade} - ${this.estado}`);

    this.estados.setEstado(this.uf);
    this.cidades.setCidade(this.uf, this.cidade);
  }

  onConfirmar(){
    this.serviceEstado.estados().then((states) =>{
      var estados = states.states as EstadoModel[];

      estados.forEach((estado) =>{
        if(estado.id === this.estadoId){
          this.serviceEstado.cidades(estado.id).then((resultado) =>{
            var cidades = resultado.cities as CidadeModel[];
      
            cidades.forEach((cidade) =>{
              if(cidade.id === this.cidadeId){
                setState(estado.code);
                setCity(cidade.id);
                setCityName(cidade.name);
                setStateName(estado.name);

                this.estado = estado.name;
                this.uf = estado.code;
                this.cidade = cidade.name;

                console.log(`${estado.code} - ${cidade.name} - ${estado.name}`);
                this.showLocalizacao = false;
                this.mudarLocalizacao.emit();
              }
            })
          });
        }
      })
    });
  }

  onCancelar(){
    this.showLocalizacao = false;
    this.showBuscar = false;
    this.buscar = false;
  }

  onBuscar(){
    this.showBuscar = false;
    this.buscar = true;
  }

  onFiltrar() {
    if (this.texto){
      // this.buscarOferta.emit(this.texto);
      // this.adicionarTermoDePesquisaRecente(this.texto);
      this.navCtrl.navigateForward('/core/ofertas', { queryParams: { texto: this.texto } }).then(
        () => {
          if (this.isMobile()) {
            window.location.reload();
          }
        }
      );
    }
  }

  onFiltrarUsandoTermoRecente(termo: string) {
    this.texto = termo;
    this.onFiltrar();
  }

  adicionarTermoDePesquisaRecente(value: string) {
    const limiteMaximo = 5;
    const key = 'xpory.termos-de-pesquisa-recentes';
    const termos = localStorage.getItem(key);
    if (termos) {
      const termosDePesquisaRecentes = JSON.parse(termos);

      if (termosDePesquisaRecentes.includes(value)) {
        termosDePesquisaRecentes.splice(termosDePesquisaRecentes.indexOf(value), 1);
      }

      termosDePesquisaRecentes.push(value);
      if (termosDePesquisaRecentes.length > limiteMaximo) {
        localStorage.setItem(key, JSON.stringify(termosDePesquisaRecentes.slice(-limiteMaximo)));
      } else{
        localStorage.setItem(key, JSON.stringify(termosDePesquisaRecentes));
      }
    } else {
      localStorage.setItem(key, JSON.stringify([value]));
    }
  }

  getTermosDePesquisaRecentes() {
    const key = 'xpory.termos-de-pesquisa-recentes';
    const termos = localStorage.getItem(key);
    if (termos) {
      const termosDePesquisaRecentes = JSON.parse(termos);
      return termosDePesquisaRecentes.toReversed().slice(0, 5);
    }
    return [];
  }

  onLimparLocalizacao(){
    this.geolocation.getCurrentPosition().then((resp) => {
      this.openLoading('Carregando...');
      const lat = resp.coords.latitude;
      const long = resp.coords.longitude;
      this.obterNomeCidadeEstado(lat, long);
    }).finally(() =>{
      this.closeLoading();
    });
  }

  onFecharLocalizacao(){
    var state = getState();
    if(!state)
      this.message("Informe um estado e uma cidade!");
    else
      this.showLocalizacao = false;
  }
}