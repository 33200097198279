import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EstatisticasService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  get(token: string, accountId: number) {
    return axios.get(`${environment.api}v2/entity-statistics?token=${token}&accountId=${accountId}&totalOffers=true&activeOffers=true&numSales=true&numPurchases=true&numReceivedTransfers=true&numMadeTransfers=true`).then(response => response.data);
  }

  getData(token: string, accountId: number, inicio: string, fim: string) {
    return this.http.get(`${environment.api}v2/entity-statistics?token=${token}&accountId=${accountId}&totalOffers=true&activeOffers=true&numSales=true&numPurchases=true&numReceivedTransfers=true&numMadeTransfers=true&currentIntervalInitialDate=${inicio}&currentIntervalEndDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }
}