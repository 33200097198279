import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { ComprasService } from "src/app/shared/service/compras/compras.service";
import { getAccountId, getToken } from "../../service/base.service";
import { Compras } from "src/app/shared/model/compras/compras.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'cmp-painel-compras',
  templateUrl: './painel-compras.component.html',
  styleUrls: [
    './painel-compras.component.scss',
    `../../../../themes/xpory/css/component/compras/painel-compras.component.scss`,
    `../../../../themes/xpory-futebol/css/component/compras/painel-compras.component.scss`,
    `../../../../themes/fcj-barter/css/component/compras/painel-compras.component.scss`,
  ]
})
export class PainelComprasComponent extends BaseComponent {
  
  compras: Compras[] = [];
  inicio: Date;
  fim: Date;
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count: number = 0;
  offset: number;
  max: number;
  isLoading: boolean;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private service: ComprasService,
    private datePipe: DatePipe){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.isLoading = true;
    this.openLoading('Carregando...');
    this
      .carregaSaldo()
      .then(() => {
        this.closeLoading();
        this.isLoading = false;
      });
  }

  async carregaSaldo() {
    this.offset = 0;
    this.max = 12;
    this.compras = [];

    const token = getToken();
    const accountId = getAccountId();

    try {
      let resultado;

      if (this.inicio && this.fim) {
        const inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
        const fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;
        resultado = await this.service.listarData(token, accountId, inicio, fim, this.offset, this.max);
      } else {
        resultado = await this.service.listar(token, accountId, this.offset, this.max);
      }

      this.count = resultado.listFullSize;
      if(this.count === 0){
        this.compras = [];
      } else {
        this.compras = resultado.offersPurchased as Compras[]; // Ensure empty array if no purchases
      }
    } catch (error) {
      console.error('Error carregando saldo:', error);
    }
  }

  loadData(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, inicio, fim, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var compras = resultado.offersPurchased;
            this.compras = this.compras.concat(compras);
          }
        }
      });
    }
    else {
      this.service.listar(token, accountId, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var compras = resultado.offersPurchased;
            this.compras = this.compras.concat(compras);
          }
        }
      });
    }
  }

  onSelectInicio(inicio: Date){
    this.inicio = inicio;
    this.carregaSaldo();
  }

  onSelectFim(fim: Date){
    this.fim = fim;
    this.carregaSaldo();
  }
}