import { Component } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import * as qrcode from 'qrcode';
import { getAccountId } from '../../service/base.service';

@Component({
  selector: 'cmp-solicitar-transferencia-detalhe',
  templateUrl: './solicitar-transferencia-detalhe.component.html',
  styleUrls: ['./solicitar-transferencia-detalhe.component.scss']
})
export class SolicitarTransferenciaDetalheComponent extends BaseComponent {

  description!: string;
  value!: string;
  requestId!: string;

  url!: string;
  qrCode!: string;
  generated!: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private clipboard: Clipboard,
    private sharing: SocialSharing){
    super(router, toastController, loadingController);
    this.requestId = this.route.snapshot.paramMap.get('requestId');
    this.description = this.route.snapshot.paramMap.get('description');
    this.value = this.route.snapshot.paramMap.get('value');
  }

  ngOnInit(): void {
    this.url = `https://sandbox.xpory.com.br/transferir/${this.requestId}`;

    const data = JSON.stringify({
      id: getAccountId(),
      value: this.value,
      description: this.description,
    });

    qrcode
      .toDataURL(data)
      .then(url => {
        this.qrCode = url;
      });
  }

  onCompartilharTexto(texto: string){
    this.sharing.share(null, null, null, texto).then(() => {

    });
  }

  onCopiar(){
    this.clipboard.copy(this.url);
    this.message(`Link copiado!`);
  }
}
