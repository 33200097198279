import { Component } from "@angular/core";

@Component({
  selector: 'pg-solicitar-antecipacao',
  templateUrl: './solicitar-antecipacao.page.html',
  styleUrls: ['./solicitar-antecipacao.page.scss']
})
export class SolicitarAntecipacaoPage {

  constructor(){
  }
}