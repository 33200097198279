import { Component } from "@angular/core";

@Component({
  selector: 'pg-expirar',
  templateUrl: './expirar.page.html',
  styleUrls: ['./expirar.page.scss']
})
export class ExpirarPage {

  constructor(){
  }
}