import { Component } from "@angular/core";

@Component({
  selector: 'pg-regulamento-detalhe',
  templateUrl: './regulamento-detalhe.page.html',
  styleUrls: ['./regulamento-detalhe.page.scss']
})
export class RegulamentoDetalhePage {

  constructor(){
  }
}