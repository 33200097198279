import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { PanielVendasPage } from './painel-vendas.page';
import { PainelVendasComponent } from 'src/app/arquitetura/vendas/painel-vendas/painel-vendas.component';

@NgModule({
  declarations: [
    PanielVendasPage,
    PainelVendasComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class PanielVendasModule { }
