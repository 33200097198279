import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Balanco } from "src/app/shared/model/perfil/balanco.model";
import { BaseComponent } from "../../base.component";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-card-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: [
    './saldo.component.scss',
    `../../../../themes/xpory/css/component/cards/saldo.component.scss`,
    `../../../../themes/xpory-futebol/css/component/cards/saldo.component.scss`,
    `../../../../themes/fcj-barter/css/component/cards/saldo.component.scss`,
  ]
})
export class SaldoComponent extends BaseComponent {
  theme = environment.theme_name;
  balanco: Balanco;
  show: boolean;
  modal: boolean;

  constructor(
    public router: Router,
    private service: UsuarioService,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaBalanco();
  }

  carregaBalanco(){
    var token = getToken();
    var accountId = getAccountId();
    this.service.get(token, accountId).then((resultado: any) =>{
      this.balanco = resultado.balance as Balanco;
    }).catch((ex) =>{
      
    });
  }

  abrir(){
    this.modal = true;
  }

  fechar() {
    this.modal = false;
  }

  informar() {
    this.router.navigate([]).then(result => {  window.open(`https://app.pipefy.com/public/form/ES1DxjVl`, '_blank'); });
    this.modal = false;
  }
}