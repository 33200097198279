import { Component } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { getAccountId, getToken } from '../../service/base.service';
import { Router } from '@angular/router';
import { EmprestimoService } from 'src/app/shared/service/financeiro/emprestimo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmp-solicitar-emprestimo',
  templateUrl: './solicitar-emprestimo.component.html',
  styleUrls: [
    './solicitar-emprestimo.component.scss',
    `../../../../themes/xpory/css/component/financeiro/solicitar-emprestimo.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/solicitar-emprestimo.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/solicitar-emprestimo.component.scss`,
  ]
})
export class SolicitarEmprestimoComponent extends BaseComponent {
  theme = environment.theme_name;
  termo = false;
  valor: number;
  valorSolicitado: number;
  modalShow = false;
  vencimento: any;
  messageSucesso: string;
  taxaEmRS: number;

  public declarativeFormCaptchaValue: string;

  constructor(
    public router: Router,
    private service: EmprestimoService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {

  }

  solicitar() {
    if (!this.declarativeFormCaptchaValue) {
      return;
    }

    if (!this.termo) {
      this.message('Termo deve ser selecionado!');
    } else {
      const token = getToken();
      const accountId = getAccountId();
      this.service.request(token, accountId, this.termo, this.valor, this.declarativeFormCaptchaValue).then((resultado) => {
        if (resultado.ok == false) {
          this.message(resultado.msg);
        } else if (resultado.msg) {
          this.messageSucesso = resultado.msg;
          this.valorSolicitado = resultado.newLimit;
          this.vencimento = resultado.dueDate;
          this.taxaEmRS = this.valor * 0.05;
          this.modalShow = true;
        }
      }).catch((ex) => {
        if (ex && ex.error) {
          const json = JSON.parse(ex.error);
          if (json)
            {this.message(json.msg);}
        }
      });
    }
  }

  fecharConfirmacao() {
    this.modalShow = false;
  }

  formatData(date: string) {
    return new Date(date);
  }

  handleCorrectCaptcha(captchaResponse: any) {
    this.declarativeFormCaptchaValue = captchaResponse;
  }
}
