import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { SolicitarTransferenciaPage } from './solicitar-transferencia.page';
import { SolicitarTransferenciaComponent } from 'src/app/arquitetura/transferencias/solicitar-transferencia/solicitar-transferencia.component';

@NgModule({
  declarations: [
    SolicitarTransferenciaPage,
    SolicitarTransferenciaComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class SolicitarTransferenciaModule { }
