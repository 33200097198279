import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { SolicitarAntecipacaoPage } from './solicitar-antecipacao.page';
import { SolicitarAntecipacaoComponent } from 'src/app/arquitetura/financeiro/solicitar-antecipacao/solicitar-antecipacao.component';

@NgModule({
  declarations: [
    SolicitarAntecipacaoPage,
    SolicitarAntecipacaoComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class SolicitarAntecipacaoModule { }
