import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class VendasService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/my-sales?token=${token}&accountId=${accountId}&max=${max}&offset=${offset}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, inicio: string, fim: string, offset: number, max: number){
    return this.http.get(`${environment.api}v1/my-sales?initialDate=${inicio}&endDate=${fim}&token=${token}&accountId=${accountId}&max=${max}&offset=${offset}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  consultaVoucher(token: string, accountId: number, voucher: string){
    return this.http.get(`${environment.api}v1/search-voucher?voucherCode=${voucher}&token=${token}&accountId=${accountId}`, null, this.config()).then(data => JSON.parse(data.data));
  }
}