import { CurrencyPipe } from '@angular/common';
import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cmp-parcelas',
  templateUrl: './parcelas.component.html',
  styleUrls: ['./parcelas.component.scss']
})
export class ParcelasComponent {

  parcelas: any[] = [];
  @Input() valor: any;
  @Input() amount: number;
  @Output() selected: EventEmitter<any> = new EventEmitter;

  constructor(private currencyPipe: CurrencyPipe) { 
  }

  ngOnInit(): void {
    this.listar();
  }

  listar(): void {
    this.parcelas = [
        {name: `1x de ${this.currencyPipe.transform(this.amount / 1, 'BRL', true, '1.2-2')}`, value: '1', description: `1x de ${this.currencyPipe.transform(this.amount / 1, 'BRL', true, '1.2-2')}`},
        {name: `2x de ${this.currencyPipe.transform(this.amount / 2, 'BRL', true, '1.2-2')}`, value: '2', description: `2x de ${this.currencyPipe.transform(this.amount / 2, 'BRL', true, '1.2-2')}`},
        {name: `3x de ${this.currencyPipe.transform(this.amount / 3, 'BRL', true, '1.2-2')}`, value: '3', description: `3x de ${this.currencyPipe.transform(this.amount / 3, 'BRL', true, '1.2-2')}`},
        {name: `4x de ${this.currencyPipe.transform(this.amount / 4, 'BRL', true, '1.2-2')}`, value: '4', description: `4x de ${this.currencyPipe.transform(this.amount / 4, 'BRL', true, '1.2-2')}`},
        {name: `5x de ${this.currencyPipe.transform(this.amount / 5, 'BRL', true, '1.2-2')}`, value: '5', description: `5x de ${this.currencyPipe.transform(this.amount / 5, 'BRL', true, '1.2-2')}`},
        {name: `6x de ${this.currencyPipe.transform(this.amount / 6, 'BRL', true, '1.2-2')}`, value: '6', description: `6x de ${this.currencyPipe.transform(this.amount / 6, 'BRL', true, '1.2-2')}`}
    ];
  }

  onSelected($event: any){
    this.selected.emit($event.detail.value);
  }
}
