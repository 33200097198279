import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Ordenacao } from "src/app/shared/model/geral/order.model";
import { getState } from "../../service/base.service";
import { Parceiros } from "src/app/shared/model/parceiros/parceiros.model";
import { ParceirosService } from "src/app/shared/service/parceiros/parceiros.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: [
    './parceiros.component.scss',
    `../../../../themes/xpory/css/component/core/parceiros.component.scss`,
    `../../../../themes/xpory-futebol/css/component/core/parceiros.component.scss`,
    `../../../../themes/fcj-barter/css/component/core/parceiros.component.scss`,
  ]
})
export class ParceirosComponent extends BaseComponent {
  theme = environment.theme_name;

  limit: number = 12;
  page: number = 0;
  parceiros: Parceiros[] = [];
  ordenacao: Ordenacao;
  showFiltro: boolean;

  count: number;
  offset: number;
  max: number;
  range: any;
  
  texto: string = '';

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected service: ParceirosService,
    private route: ActivatedRoute){
    super(router, toastController, loadingController);

    this.route.queryParams.subscribe(queryParams => {
      this.texto = queryParams['texto'];
    });
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.criaOrdenacao();
    this.listar(this.texto);
  }

  criaOrdenacao(){
    this.ordenacao = new Ordenacao();
    this.ordenacao.name = 'lastUpdated';
    this.ordenacao.value = 'desc';
  }

  listar(texto: string){
    this.texto = texto;
    this.service.listar(this.limit, this.offset).then((resultado) =>{
      if(resultado){
        if(resultado.msg){
          this.message(resultado.msg);
        }else{
          this.count = resultado.listFullSize;
          this.parceiros = resultado.entities as Parceiros[];
        }
      }
    });
  }

  async loadData(event){
    this.offset += this.max;

    this.texto = this.texto;
    this.service.listar(this.limit, this.offset).then((resultado) =>{
      if(event.target){
        event.target.complete();
      }

      if(resultado){
        if(!resultado.msg){
          var parceiros = resultado.entities as Parceiros[];
          this.parceiros = this.parceiros.concat(parceiros);
        }
      }
    });
  }

  onFechar(){
    this.showFiltro = false;
  }

  onFiltrar(){
    this.listar(this.texto);
    this.showFiltro = false;
  }
}