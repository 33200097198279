import { NgModule } from '@angular/core';
import { HomePage } from './home.page';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { HomeComponent } from 'src/app/arquitetura/core/home/home.component';

@NgModule({
  declarations: [
    HomePage,
    HomeComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class HomeModule { }
