import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ComprasService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, offset: number, max: number){
    return axios.post(`${environment.api}v1/purchased-offers?max=${max}&offset=${offset}&token=${token}&accountId=${accountId}`, {}, {
      headers: this.config()
    }).then(response => response.data);
  }

  listarData(token: string, accountId: number, inicio: string, fim: string, offset: number, max: number){
    return axios.post(`${environment.api}v1/purchased-offers?initialDate=${inicio}&endDate=${fim}&max=${max}&offset=${offset}&token=${token}&accountId=${accountId}`, {}, {
      headers: this.config()
    }).then(response => response.data);
  }
}