import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class LandingpageService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  register(name: string, email: string, cellNumber: string) {
    // return this.http.post<any>(`${environment.api}v2/landing-page-pipe-register`, { name, email, cellNumber }, this.config()).pipe(map((response: any) => response));
  }
}
