import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { EmprestimoService } from "src/app/shared/service/financeiro/emprestimo.service";
import { Emprestimo } from "src/app/shared/model/financeiro/emprestimo.model";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-meus-emprestimos',
  templateUrl: './meus-emprestimos.component.html',
  styleUrls: [
    './meus-emprestimos.component.scss',
  ]
})
export class MeusEmprestimosComponent extends BaseComponent {
  emprestimos: Emprestimo[] = [];
  inicio: Date;
  fim: Date;
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count: number = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private service: EmprestimoService,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaEmprestimo();
  }

  carregaEmprestimo(){
    this.offset = 0;
    this.max = 12;
    var token = getToken();
      var accountId = getAccountId();
      if(this.inicio && this.fim){   
        var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
        var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

        this.service.listarData(token, accountId, inicio, fim, this.offset, this.max).then((resultado) =>{
          this.count = resultado.listFullSize;
          this.emprestimos = resultado.listLoanRequest;
        }).catch((ex) =>{
          
        });
      }else{
        this.service.listar(token, accountId, this.offset, this.max).then((resultado) =>{
          this.count = resultado.listFullSize;
          this.emprestimos = resultado.listLoanRequest;
        }).catch((ex) =>{
          
        });
      }
  }

  loadData(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){   
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarData(token, accountId, inicio, fim, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var emprestimos = resultado.listLoanRequest;
            this.emprestimos = this.emprestimos.concat(emprestimos);
          }
        }
      });
    }
    else {
      this.service.listar(token, accountId, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var emprestimos = resultado.listLoanRequest;
            this.emprestimos = this.emprestimos.concat(emprestimos);
          }
        }
      });
    }
  }
  
  onSelectInicio(inicio: Date){
    this.inicio = inicio;
    this.carregaEmprestimo();
  }

  onSelectFim(fim: Date){
    this.fim = fim;
    this.carregaEmprestimo();
  }
}