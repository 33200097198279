import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { EstornosPage } from './estornos.page';
import { EstornosComponent } from 'src/app/arquitetura/financeiro/estornos/estornos.component';

@NgModule({
  declarations: [
    EstornosPage,
    EstornosComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class EstornosModule { }
