import { Component } from "@angular/core";

@Component({
  selector: 'pg-solicitar-transferencia-detalhe',
  templateUrl: './solicitar-transferencia-detalhe.page.html',
  styleUrls: ['./solicitar-transferencia-detalhe.page.scss']
})
export class SolicitarTransferenciaDetalhePage {

  constructor(){
  }
}