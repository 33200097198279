import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginRouter } from './page/onboarding/login/login.router';
import { HomeRouter } from './page/core/home/home.router';
import { CadastrarRouter } from './page/onboarding/cadastrar/cadastrar.router';
import { RecuperarRouter } from './page/onboarding/recuperar/recuperar.router';
import { OfertaDetalheRouter } from './page/core/oferta-detalhe/oferta-detalhe.router';
import { OfertasRouter } from './page/core/ofertas/ofertas.router';
import { PlanosRouter } from './page/planos/planos/planos.router';
import { PlanoDetalheRouter } from './page/planos/plano-detalhe/plano-detalhe.router';
import { CentralDeAjudaRouter } from './page/central-de-ajuda/central-de-ajuda/central-de-ajuda.router';
import { FaqRouter } from './page/central-de-ajuda/faq/faq.router';
import { GuiaDoUsuarioRouter } from './page/central-de-ajuda/guia-do-usuario/guia-do-usuario.router';
import { RegulamentoDetalheRouter } from './page/central-de-ajuda/regulamento-detalhe/regulamento-detalhe.router';
import { RegulamentosRouter } from './page/central-de-ajuda/regulamentos/regulamentos.router';
import { EditarPerfilRouter } from './page/perfil/editar-perfil/editar-perfil.router';
import { MeuPerfilRouter } from './page/perfil/meu-perfil/meu-perfil.router';
import { EstornosRouter } from './page/financeiro/estornos/estornos.router';
import { ExpirarRouter } from './page/financeiro/expirar/expirar.router';
import { MeusEmprestimosRouter } from './page/financeiro/meus-emprestimos/meus-emprestimos.router';
import { MeusEmprestimosEmRsRouter } from './page/financeiro/meus-emprestimos-em-rs/meus-emprestimos-em-rs.router';
import { SaldoRouter } from './page/financeiro/saldo/saldo.router';
import { SolicitarEmprestimoRouter } from './page/financeiro/solicitar-emprestimo/solicitar-emprestimo.router';
import { FazerTransferenciaRouter } from './page/transferencias/fazer-transferencia/fazer-transferencia.router';
import { FazerTransferenciaDetalheRouter } from './page/transferencias/fazer-transferencia-detalhe/fazer-transferencia-detalhe.router';
import { MinhasTransferenciasRouter } from './page/transferencias/minhas-transferencias/minhas-transferencias.router';
import { SolicitarTransferenciaRouter } from './page/transferencias/solicitar-transferencia/solicitar-transferencia.router';
import { SolicitarTransferenciaDetalheRouter } from './page/transferencias/solicitar-transferencia-detalhe/solicitar-transferencia-detalhe.router';
import { PainelVendasRouter } from './page/vendas/painel-vendas/painel-vendas.router';
import { ValidarVoucherRouter } from './page/vendas/validar-voucher/validar-voucher.router';
import { PainelComprasRouter } from './page/compras/painel-compras/painel-compras.router';
import { PainelOfertasRouter } from './page/minhas-ofertas/painel-ofertas/painel-ofertas.router';
import { PainelOfertasCadastroRouter } from './page/minhas-ofertas/painel-ofertas-cadastro/painel-ofertas-cadastro.router';
import { PainelIndicacaoRouter } from './page/indicacoes/painel-indicacao/painel-indicacao.router';
import { PainelMinhasIndicacoesRouter } from './page/indicacoes/painel-minhas-indicacoes/painel-minhas-indicacoes.router';
import { EADRouter } from './page/ead/ead/ead.router';
import { EADDetalheRouter } from './page/ead/ead-detalhe/ead-detalhe.router';
import { MeuVideoPitchRouter } from './page/rodada-de-negocios/meu-video-pitch/meu-video-pitch.router';
import { RodadaDeNegociosRouter } from './page/rodada-de-negocios/rodada-de-negocios/rodada-de-negocios.router';
import { ConfirmarCadastroRouter } from './page/onboarding/confirmar-cadastro/confirmar-cadastro.router';
import { ParceirosRouter } from './page/core/parceiros/parceiros.router';
import { SolicitarAntecipacaoRouter } from './page/financeiro/solicitar-antecipacao/solicitar-antecipacao.router';
import { ExcluirContaRouter } from './page/excluir-conta/excluir-conta.router';
import { LandingPage } from './page/landingpage/landingpage.page';
import { FinalizarCompraRouter } from './page/core/finalizar-compra/finalizar-compra.router';
import { DashboardRouter } from './page/perfil/dashboard/dashboard.router';
import { EditarEnderecoRouter } from './page/perfil/editar-endereco/editar-endereco.router';

const routes: Routes = [
  { path: '', redirectTo: '/core/home', pathMatch: 'full' },

  // { path: 'landingpage', component: LandingPage },
  {
    path: 'core',
    children: [
      ...HomeRouter,
      ...ParceirosRouter,
      ...OfertasRouter,
      ...OfertaDetalheRouter,
      ...FinalizarCompraRouter,
    ]
  },
  {
    path: 'onboarding',
    children: [
      ...LoginRouter,
      ...CadastrarRouter,
      ...ConfirmarCadastroRouter,
      ...RecuperarRouter
    ]
  },
  {
    path: 'planos',
    children: [
      ...PlanosRouter,
      ...PlanoDetalheRouter
    ]
  },
  {
    path: 'central-de-ajuda',
    children: [
      ...CentralDeAjudaRouter,
      ...FaqRouter,
      ...GuiaDoUsuarioRouter,
      ...RegulamentoDetalheRouter,
      ...RegulamentosRouter
    ]
  },
  {
    path: 'perfil',
    children: [
      ...EditarPerfilRouter,
      ...EditarEnderecoRouter,
      ...DashboardRouter,
      ...MeuPerfilRouter,
    ]
  },
  {
    path: 'financeiro',
    children: [
      ...EstornosRouter,
      ...ExpirarRouter,
      ...MeusEmprestimosRouter,
      ...MeusEmprestimosEmRsRouter,
      ...SaldoRouter,
      ...SolicitarEmprestimoRouter,
      ...SolicitarAntecipacaoRouter
    ]
  },
  {
    path: 'transferencias',
    children: [
      ...FazerTransferenciaRouter,
      ...FazerTransferenciaDetalheRouter,
      ...MinhasTransferenciasRouter,
      ...SolicitarTransferenciaRouter,
      ...SolicitarTransferenciaDetalheRouter
    ]
  },
  {
    path: 'vendas',
    children: [
      ...PainelVendasRouter,
      ...ValidarVoucherRouter
    ]
  },
  {
    path: 'compras',
    children:[
      ...PainelComprasRouter
    ]
  },
  {
    path: 'minhas-ofertas',
    children: [
      ...PainelOfertasRouter,
      ...PainelOfertasCadastroRouter
    ]
  },
  {
    path: 'indicacoes',
    children: [
      ...PainelIndicacaoRouter,
      ...PainelMinhasIndicacoesRouter
    ]
  },
  {
    path: 'ead',
    children: [
      ...EADRouter,
      ...EADDetalheRouter
    ]
  },
  {
    path: 'rodada-de-negocios',
    children: [
      ...MeuVideoPitchRouter,
      ...RodadaDeNegociosRouter
    ]
  },
  ...ExcluirContaRouter,
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
