import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { EditarPerfilPage } from './editar-perfil.page';
import { EditarPerfilComponent } from 'src/app/arquitetura/perfil/editar-perfil/editar-perfil.component';

@NgModule({
  declarations: [
    EditarPerfilPage,
    EditarPerfilComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class EditarPerfilModule { }
