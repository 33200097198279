import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { SolicitarTransferenciaDetalhePage } from './solicitar-transferencia-detalhe.page';
import { SolicitarTransferenciaDetalheComponent } from 'src/app/arquitetura/transferencias/solicitar-transferencia-detalhe/solicitar-transferencia-detalhe.component';

@NgModule({
  declarations: [
    SolicitarTransferenciaDetalhePage,
    SolicitarTransferenciaDetalheComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class SolicitarTransferenciaDetalheModule { }
