import { Component } from "@angular/core";

@Component({
  selector: 'pg-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {

  constructor(){
  }
}