export const environment = {
  production: false,
  api: "https://xpory.andre.sampa.br/api/",
  api_blog: "https://blog.xpory.com/wp-json/wp/",
  title: 'XporY Homologação',
  theme_name: 'xpory',
  theme_css: '../../../themes/xpory',
  component_css: '../../../themes/xpory/css',
  component_json: '../../../../assets/theme/json',
  theme_assets: './assets/themes/xpory/',
  mostra_sebrae: false,
  label: {
    title: 'XporY',
    website: 'XporY.com',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAF9iDn0i61uVkfa7Q4waZ5kKHfXJn0LII',
    authDomain: 'xpory-images-dev.firebaseapp.com',
    databaseURL: 'https://xpory-images-dev.firebaseio.com',
    projectId: 'xpory-images-dev',
    storageBucket: 'xpory-images-dev.appspot.com',
    messagingSenderId: '926359720168',
    appId: '1:926359720168:web:58b8044b961c52c7',
    measurementId: 'G-2ZQ23SWFWY',
  },
  googleAPI: 'AIzaSyDO-KzAl_oHb1CJGPoA15sMHgZ6p03mwpI'
};