import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { OfertasPage } from './ofertas.page';
import { OfertasComponent } from 'src/app/arquitetura/core/ofertas/ofertas.component';

@NgModule({
  declarations: [
    OfertasPage,
    OfertasComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class OfertasModule { }
