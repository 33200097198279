import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EmprestimoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-loan-request?token=${token}&accountId=${accountId}&offset=${offset}&max=${max}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listarData(token: string, accountId: number, inicio: string, fim: string, offset: number, max: number) {
    return this.http.get(`${environment.api}v1/get-loan-request?token=${token}&accountId=${accountId}&offset=${offset}&max=${max}&initialDate=${inicio}&endDate=${fim}`, null, this.config()).then(data => JSON.parse(data.data));
  }

  listAdvances({ inicio, fim, offset, max }: { inicio: string; fim: string; offset: number; max: number }) {
    return axios.get(
      `${environment.api}v2/list-entity-advances`,
      {
        params: {
          initialDate: inicio,
          endDate: fim,
          offset,
          max
        },
        headers: this.config(),
      },
    ).then(response => response.data);
  }

  request(token: string, accountId: number, term: boolean, valor: number, captcha: string){
    return this.http.post(`${environment.api}v1/loan-request?token=${token}&accountId=${accountId}&terms=${term}&value=${valor}&captchaResponse=${captcha}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }

  delete(token: string, accountId: number, id: number){
    return this.http.delete(`${environment.api}v1/delete-loan-request?token=${token}&accountId=${accountId}&loanRequestId=${id}`, null, this.configToken()).then(data => JSON.parse(data.data));
  }

  advance(token: string, accountId: number, valor: number){
    return this.http.post(`${environment.api}v2/advance-reais?token=${token}&accountId=${accountId}&advanceValue=${valor}`, {}, this.configToken()).then(data => JSON.parse(data.data));
  }
}
