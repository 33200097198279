import { Component, Input } from "@angular/core";
import { LoadingController, MenuController, NavController, Platform, ToastController } from "@ionic/angular";
import { getAccountId, getMobile, getToken, getUsuarioLogado } from "../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { EnderecoService } from "src/app/shared/service/endereco/endereco.service";
import { BaseComponent } from "../base.component";
import { Router } from "@angular/router";

@Component({
  selector: 'cmp-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss']
})
export class RodapeComponent extends BaseComponent {

  logado: boolean = false;

  @Input() tabSelected!: string;

  constructor(
    public router: Router,
    private platform: Platform,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    protected menu: MenuController,
    protected navCtrl: NavController,
    protected service: UsuarioService,
    protected serviceEstado: EnderecoService){
    super(router, toastController, loadingController);
  }

  override ngOnInit(): void {
    this.platform.ready().then(()=>{
      this.verificarLogin();
    });
  }

  verificarLogin(){
    var usuario = JSON.parse(getUsuarioLogado());
    if(usuario)
      this.logado = true;
  }

  onHome(){
    this.navCtrl.navigateForward('/core/home');
  }

  onOfertas(){
    this.navCtrl.navigateForward('/core/ofertas');
  }

  onFinancas(){
    this.navCtrl.navigateForward('/financeiro/saldo');
  }

  onPerfil(){
    this.navCtrl.navigateForward('/perfil/meu-perfil');
  }

  isMobile(){
    // return getMobile();
    return false;
  }
}
