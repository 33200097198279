import { Component } from "@angular/core";

@Component({
  selector: 'pg-painel-ofertas',
  templateUrl: './painel-ofertas.page.html',
  styleUrls: ['./painel-ofertas.page.scss']
})
export class PainelOfertasPage {

  constructor(){
  }
}