import { ErrorHandler } from '@angular/core';
import * as Sentry from 'sentry-cordova';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error: any) {
    super.handleError(error);

    if (environment.production) {
      try {
        Sentry.captureException(error.originalError || error);
      } catch (e) {
        console.error('Erro ao enviar para o Sentry:', e);
      }
    } else {
      console.error('Erro capturado:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
    }

    const isNotConnectedToInternetError = error.message.includes('No network connection available');
    const isNotInternetError = error.message.includes('The Internet connection appears to be offline.');

    if (isNotConnectedToInternetError || isNotInternetError) {
      new ToastController().create({
        message: 'Sem conexão com a internet! Por favor, tente novamente mais tarde.',
        duration: 10000,
      }).then((toast) => {
        toast.present();
      });
    }
  }
}
