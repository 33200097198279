import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BaseComponent } from '../../base.component';
import { getAccountId, getToken } from '../../service/base.service';
import { UsuarioService } from 'src/app/shared/service/perfil/usuario.service';
import { Endereco, Usuario } from 'src/app/shared/model/perfil/usuario.model';
import { CidadeComponent } from '../../selects/cidade/cidade.component';
import { Categoria } from 'src/app/shared/model/categoria/categoria.model';
import { LoginService } from 'src/app/shared/service/login/login.service';
import { Router } from '@angular/router';
import { DDIComponent } from '../../selects/ddi/ddi.component';
import { EstadoComponent } from '../../selects/estado/estado.component';
import { CategoriaComponent } from '../../selects/categoria/categoria.component';
import { EnderecoService } from 'src/app/shared/service/endereco/endereco.service';
import { EstadoModel } from 'src/app/shared/model/endereco/estado.model';
import { CidadeModel } from 'src/app/shared/model/endereco/cidade.model';

import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmp-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: [
    './editar-perfil.component.scss',
    `../../../../themes/xpory/css/component/perfil/editar-perfil.component.scss`,
    `../../../../themes/xpory-futebol/css/component/perfil/editar-perfil.component.scss`,
    `../../../../themes/fcj-barter/css/component/perfil/editar-perfil.component.scss`,
  ]
})
export class EditarPerfilComponent extends BaseComponent implements AfterViewChecked {
  theme = environment.theme_name;

  step = 0;
  usuario: Usuario;
  endereco: Endereco;
  isPremium: boolean;
  isLead: boolean;
  senha: string;
  novaSenha: string;
  confNovaSenha: string;
  dateShow: boolean;
  atualizaStep: boolean;

  options = {
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 10,
    loop: false
  };

  @ViewChild('estado') estado: EstadoComponent;
  @ViewChild('cidade') cidade: CidadeComponent;
  @ViewChild('ddi') ddi: DDIComponent;
  @ViewChild('categoria') categoria: CategoriaComponent;

  constructor(
    private service: UsuarioService,
    private serviceLogin: LoginService,
    public router: Router,
    public toastController: ToastController,
    protected enderecoService: EnderecoService,
    public loadingController: LoadingController) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaUsuario();
  }

  ngAfterViewChecked(): void {
    if (!this.atualizaStep) {
      this.atualizarSelects();
      this.atualizaStep = true;
    }
  }

  carregaUsuario() {
    const token = getToken();
    const accountId = getAccountId();
    this.openLoading('Carregando...');
    this.service.get(token, accountId).then((resultado: any) => {
      console.log(resultado);
      this.usuario = resultado.entity as Usuario;
      this.endereco = resultado.address as Endereco;
      this.isPremium = resultado.plan != null;
      if (this.usuario) { this.isLead = this.usuario.register == 'lead'; }

      this.onAtualizaStep(0);
    }).catch((ex) => {

    }).finally(() => {
      this.closeLoading();
    });
  }

  onAtualizaStep(step: number) {
    this.step = step;
    this.atualizaStep = false;
  }

  atualizarSelects() {
    if (this.ddi) { this.ddi.setDDI(this.usuario.countryCode.id); }
    if (this.estado) { this.estado.setEstadoTexto(this.endereco.state); }
    if (this.cidade) { this.cidade.setCidadeTexto(this.endereco.state, this.endereco.city); }
    if (this.categoria) { this.categoria.setCategoria(this.usuario.category.id); }
  }

  onSelectedTipoPerfil(value: any) {
    this.usuario.to = value;
  }

  onSelectDDI(id: any) {
    this.usuario.countryCode.id = id;
  }

  onSelectEstado(id: any) {
    this.usuario.address.state = id;
    this.cidade.listar(id);
  }

  onSelectCidade(id: any) {
    this.usuario.address.cityId = id;
  }

  onSelectedCategoria(id: any) {
    this.usuario.category = new Categoria();
    this.usuario.category.id = id;
  }

  onSelectedCategorias(values: any) {
    this.usuario.categoriesInterest = values;
  }

  async atualizar() {
    try {
      const resultado1 = await this.service.salvarUsuario(this.usuario);
      this.message(resultado1.msg);

      if (this.step === 1) {
        await this.service.salvarEndereco(this.usuario.address, this.usuario.id);
        await this.service.salvarContato(this.usuario);
        await this.service.salvarInformacoes(this.usuario);
      } else {
        await this.service.salvarContato(this.usuario);
        await this.service.salvarInformacoes(this.usuario);
      }
    } catch (error) {
      console.error('An error occurred:', error.response.data.msg);
      this.message(error.response.data.msg);
    }
  }


  atualizarSenha() {
    const token = getToken();
    const accountId = getAccountId();
    this.serviceLogin.mudarSenha(token, accountId, this.senha, this.novaSenha, this.confNovaSenha).then((resultado) => {
      if (resultado.ok == false) {
        this.message(resultado.msg);
      } else if (resultado.ok == true) {
        this.message(resultado.msg);
      }
    });
  }

  async onCepChange(cep: string) {
    this.usuario.address.cep = cep.replace(/\D/g, '');;

    if (this.usuario.address.cep.length === 8) {
      const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = resultado.data;

      this.usuario.address.street = data.logradouro;
      this.usuario.address.complement = data.complemento;
      this.usuario.address.district = data.bairro;

      const estadosResultado = await this.enderecoService.estados();
      const estados = estadosResultado.states as EstadoModel[];

      estados.forEach((estado) => {
        if (estado.code === data.uf) {
          this.usuario.address.stateId = estado.id;
          this.onSelectEstado(estado.id);
          this.estado.setEstadoTexto(estado.name);
        }
      });

      const cidadesResultado = await this.enderecoService.cidades(this.usuario.address.stateId);
      const cidades = cidadesResultado.cities as CidadeModel[];

      cidades.forEach((cidade) => {
        if (cidade.name === data.localidade) {
          this.onSelectCidade(cidade.id);
        }
      });
    }
  }
}
