import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { GuiaDoUsuarioPage } from './guia-do-usuario.page';

@NgModule({
  declarations: [
    GuiaDoUsuarioPage
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class GuiaDoUsuarioModule { }
