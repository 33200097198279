import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { EADPage } from './ead.page';
import { PainelComponent } from 'src/app/arquitetura/ead/painel/painel.component';
import { ModuloComponent } from 'src/app/arquitetura/cards/modulo/modulo.component';

@NgModule({
  declarations: [
    EADPage,
    PainelComponent,
    ModuloComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class EADModule { }
