import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import axios from 'axios';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TransacaoService extends BaseService {

  constructor(http: HTTP) {
    super('', http);
  }

  listar(token: string, accountId: number, offset: number, max: number) {
    return axios.post(`${environment.api}v2/get-x-transactions`, 
      { accountId, token, max, offset },
      {
        headers: this.config()
      }
    ).then(response => response.data);
  }

  listarData(token: string, accountId: number, offset: number, max: number, inicio: string, fim: string){
    return axios.post(`${environment.api}v2/get-x-transactions`,
      { accountId, token, max, offset, initialDate: inicio, endDate: fim },
      {
        headers: this.config()
      }
    ).then(response => response.data);
  }

  listarReal(token: string, accountId: number, offset: number, max: number) {
    return axios.post(`${environment.api}v2/get-currency-transactions`,
      { accountId, token, max, offset },
      {
        headers: this.config()
      }
    ).then(response => response.data);
  }

  listarRealData(token: string, accountId: number, offset: number, max: number, inicio: string, fim: string){
    return axios.post(`${environment.api}v2/get-currency-transactions`,
      { accountId, token, max, offset, initialDate: inicio, endDate: fim },
      {
        headers: this.config()
      }
    ).then(response => response.data);
  }
}