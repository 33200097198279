import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { EstornoService } from "src/app/shared/service/financeiro/estorno.service";
import { Estorno } from "src/app/shared/model/financeiro/estorno.model";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-estornos',
  templateUrl: './estornos.component.html',
  styleUrls: [
    './estornos.component.scss',
    `../../../../themes/xpory/css/component/financeiro/estornos.component.scss`,
    `../../../../themes/xpory-futebol/css/component/financeiro/estornos.component.scss`,
    `../../../../themes/fcj-barter/css/component/financeiro/estornos.component.scss`,
  ]
})
export class EstornosComponent extends BaseComponent {
  theme = environment.theme_name;
  estornos: Estorno[] = [];
  inicio: Date;
  fim: Date;

  count: number = 0;
  offset: number;
  max: number;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private service: EstornoService,
    public toastController: ToastController, 
    public loadingController: LoadingController){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaSaldo();
  }

  carregaSaldo(){
    this.offset = 0;
    this.max = 12;
    var token = getToken();
    var accountId = getAccountId();
    
    if(this.inicio && this.fim){  
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;
        
      this.service.listarData(token, accountId, true, inicio, fim, this.offset, this.max).then((resultado) =>{
           this.count = resultado.listFullSize;
          this.estornos = resultado.listReversal as Estorno[];
      }).catch((ex) =>{
        
      });
    }else{
      this.service.listar(token, accountId, true, this.offset, this.max).then((resultado) =>{
          this.count = resultado.listFullSize;
          this.estornos = resultado.listReversal as Estorno[];
      }).catch((ex) =>{
        
      });
    }
  }

  loadData(event){
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if(this.inicio && this.fim){  
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;
        
      this.service.listarData(token, accountId, true, inicio, fim, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var estorno = resultado.listReversal as Estorno[];
            this.estornos = this.estornos.concat(estorno);
          }
        }
      }).catch((ex) =>{
        
      });
    }else{
      this.service.listar(token, accountId, true, this.offset, this.max).then((resultado) =>{
        if(event.target){
          event.target.complete();
        }

        if(resultado){
          if(!resultado.msg){
            var estorno = resultado.listReversal as Estorno[];
            this.estornos = this.estornos.concat(estorno);
          }
        }
      }).catch((ex) =>{
        
      });
    }
  }
  
  onSelectInicio(inicio: Date){
    this.inicio = inicio;
    this.carregaSaldo();
  }

  onSelectFim(fim: Date){
    this.fim = fim;
    this.carregaSaldo();
  }
}