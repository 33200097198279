import { HttpHeaders } from "@angular/common/http";
import { HTTP } from '@ionic-native/http/ngx';

export const LOCAL_STORAGE_ACCOUNT_ID: string = 'xpory.account.id';
export const LOCAL_STORAGE_ENTITY_ID: string = 'xpory.entity.id';
export const LOCAL_STORAGE_USER: string = 'xpory.user';
export const LOCAL_STORAGE_TOKEN: string = 'xpory.token';
export const LOCAL_STORAGE_STATE: string = 'xpory.state';
export const LOCAL_STORAGE_CITY: string = 'xpory.city';
export const LOCAL_STORAGE_STATE_NAME: string = 'xpory.stateName';
export const LOCAL_STORAGE_CITY_NAME: string = 'xpory.cityName';

export function clearStorage() {
    localStorage.removeItem(LOCAL_STORAGE_ACCOUNT_ID);
    localStorage.removeItem(LOCAL_STORAGE_ENTITY_ID);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER);
    // localStorage.removeItem(LOCAL_STORAGE_STATE);
    // localStorage.removeItem(LOCAL_STORAGE_STATE_NAME);
    // localStorage.removeItem(LOCAL_STORAGE_CITY);
}

export function getUsuarioLogado(): any {
    return localStorage.getItem(LOCAL_STORAGE_USER);
}
  
export function setUsuarioLogado(user: any) {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
}

export function getAccountId(): any {
  return localStorage.getItem(LOCAL_STORAGE_ACCOUNT_ID);
}

export function setAccountId(accountId: any) {
  localStorage.setItem(LOCAL_STORAGE_ACCOUNT_ID, accountId);
}

export function getEntityId(): any {
    return localStorage.getItem(LOCAL_STORAGE_ENTITY_ID);
}

export function setEntityId(entityId: any) {
    localStorage.setItem(LOCAL_STORAGE_ENTITY_ID, JSON.stringify(entityId));
}

export function getToken(): any {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN);
}

export function setState(state: string) {
  localStorage.setItem(LOCAL_STORAGE_STATE, state);
}

export function getState(): any {
  return localStorage.getItem(LOCAL_STORAGE_STATE);
}

export function removeState() {
  localStorage.removeItem(LOCAL_STORAGE_STATE);
  localStorage.removeItem(LOCAL_STORAGE_STATE_NAME);
}

export function setCity(city: any) {
  localStorage.setItem(LOCAL_STORAGE_CITY, city);
}

export function getCity(): any {
  return localStorage.getItem(LOCAL_STORAGE_CITY);
}

export function setCityName(city: string) {
  localStorage.setItem(LOCAL_STORAGE_CITY_NAME, city);
}

export function getCityName(): any {
  return localStorage.getItem(LOCAL_STORAGE_CITY_NAME);
}

export function setStateName(state: string) {
  localStorage.setItem(LOCAL_STORAGE_STATE_NAME, state);
}

export function getStateName(): any {
  return localStorage.getItem(LOCAL_STORAGE_STATE_NAME);
}

export function setToken(token: string) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
}

export function getMobile(): boolean{

  var userAgent = typeof navigator !== 'undefined' && navigator.userAgent || '';
  var isAndroid = /Android/.test(userAgent);
  var isIOS = /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent);

  return isAndroid || isIOS || window.innerWidth < 550;
}

export function getRandomElement(arr: any[]){
    return arr.length ? arr[Math.floor(Math.random() * arr.length)] : undefined;
}

export function isValidCPF(cpf: string) : boolean{
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    const cpfArray = cpf.split('').map(Number);
    const sum1 = cpfArray.slice(0, 9).reduce((acc, value, index) => acc + value * (10 - index), 0);
    const digit1 = (sum1 % 11) < 2 ? 0 : 11 - (sum1 % 11);

    const sum2 = cpfArray.slice(0, 10).reduce((acc, value, index) => acc + value * (11 - index), 0);
    const digit2 = (sum2 % 11) < 2 ? 0 : 11 - (sum2 % 11);

    return cpfArray[9] === digit1 && cpfArray[10] === digit2;
}

export function isValidCNPJ(cnpj: string) : boolean{
  cnpj = cnpj.replace(/[^\d]/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  const multiplicador1: number[] = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const multiplicador2: number[] = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let soma: number;
  let resto: number;
  let digito: string;
  let tempCnpj: string;

  tempCnpj = cnpj.substring(0, 12);
  soma = 0;

  for (let i = 0; i < 12; i++) {
    soma += parseInt(tempCnpj[i], 10) * multiplicador1[i];
  }

  resto = soma % 11;

  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }

  digito = resto.toString();
  tempCnpj = tempCnpj + digito;

  soma = 0;

  for (let i = 0; i < 13; i++) {
    soma += parseInt(tempCnpj[i], 10) * multiplicador2[i];
  }

  resto = soma % 11;

  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }

  digito = digito + resto.toString();

  return cnpj.endsWith(digito);
}

export class BaseService {

    protected constructor(protected path: string, protected http: HTTP, basePath?: string) {
    
    }

    protected configSimple() {
      return this.buildHeadersSimple()
    }

    protected config() {
      return this.buildHeaders()
    }
    
    protected buildHeaders() {
      var state = getState();
      var city = getCity();
      var token = getToken();
      var accountId = getAccountId();

      if(state && city && token && accountId){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`,
          'x-account-id': `${accountId}`,
          'X-Selected-State': `${state}`,
          'X-Selected-City': `${city}`
        };
      }else if(state && city && token){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`,
          'X-Selected-State': `${state}`,
          'X-Selected-City': `${city}`
        };
      }else if(state && city && accountId){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'x-account-id': `${accountId}`,
          'X-Selected-State': `${state}`,
          'X-Selected-City': `${city}`
        };
      }else if(state && city){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'X-Selected-State': `${state}`,
          'X-Selected-City': `${city}`
        };
      }else if(state){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'X-Selected-State': `${state}`
        };
      }else if(city){
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'X-Selected-City': `${city}`
        };
      }else {
        return { 
          'Content-Type': 'application/json',
          'Accept': '*/*'
        };
      }
    }

    protected buildHeadersSimple(){
      return { 
        'Content-Type': 'application/json',
        'Accept': '*/*'
      };
    }
    
    protected configToken() {
      console.log(this.buildHeadersToken());
      return this.buildHeadersToken()
    }
  
    protected buildHeadersToken() {
      return this.buildHeaders();
    }

    protected configTokenSemState() {
      console.log(this.buildHeadersTokenSemState());
      return this.buildHeadersTokenSemState()
    }
  
    protected buildHeadersTokenSemState() {
      return this.buildHeaders();
    }

    protected configForm() {
      return this.buildHeadersForm()
    }
    
    protected buildHeadersForm() {
      return { 
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      };
    }

    protected mapper(resp: any) {
      try {
        return (resp: { json: () => any; text: () => any; blob: () => any; }) => {
          return (resp.json() || resp.text() || resp.blob())
        }
      } catch (error) {
        return resp;
      }
    }
  
  }