import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { clearStorage, getAccountId, getToken } from "../../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { Usuario } from "src/app/shared/model/perfil/usuario.model";
import { IndicacaoService } from "src/app/shared/service/indicacao/indicacao.service";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-painel-minhas-indicacoes',
  templateUrl: './painel-minhas-indicacoes.component.html',
  styleUrls: [
    './painel-minhas-indicacoes.component.scss',
    `../../../../themes/xpory/css/component/indicacoes/painel-minhas-indicacoes.component.scss`,
    `../../../../themes/xpory-futebol/css/component/indicacoes/painel-minhas-indicacoes.component.scss`,
    `../../../../themes/fcj-barter/css/component/indicacoes/painel-minhas-indicacoes.component.scss`,
  ]
})
export class PainelMinhasIndicacoesComponent extends BaseComponent {
  theme = environment.theme_name;
  usuario: Usuario;
  email: string;

  constructor(
    public router: Router,
    public toastController: ToastController, 
    public loadingController: LoadingController,
    private usuarioService: UsuarioService,
    private service: IndicacaoService,
    private share: SocialSharing,
    private clipboard: Clipboard){
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.carregaUsuario();
  }

  carregaUsuario(){
    var token = getToken();
    var accountId = getAccountId();
    this.usuarioService.get(token, accountId).then((resultado: any) =>{
      this.usuario = resultado.entity as Usuario;
    }).catch((ex) =>{
      
    });
  }

  onEmail(){
    if(!this.email || this.email === ''){
      this.message('Informe um e-mail para a indicação!');
      return;
    }

    this.service.indicar(this.email.split(',')).then((resultado) =>{
      this.message(resultado.msg);
    })
  }

  onWhatsapp(){
    this.share.shareViaWhatsApp(`Você conhece a ${this.label.website}? É uma plataforma de permutas multilaterais onde você anuncia seus produtos e/ou serviços e aumenta seu poder de compra recebendo tudo em X$! Cadastre-se por este link e ganhe crédito de comissão para uso na plataforma após ativação de sua primeira oferta. https://sandbox.xpory.com.br/transferir/${this.usuario.codeIndication}`)
  }

  onFacebook(){
    this.share.shareViaFacebook(`Você conhece a ${this.label.website}? É uma plataforma de permutas multilaterais onde você anuncia seus produtos e/ou serviços e aumenta seu poder de compra recebendo tudo em X$! Cadastre-se por este link e ganhe crédito de comissão para uso na plataforma após ativação de sua primeira oferta. https://sandbox.xpory.com.br/transferir/${this.usuario.codeIndication}`)
  }

  onCopiar(){
    this.clipboard.copy(this.usuario.codeIndication);
  }
}